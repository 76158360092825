import React from "react";
import { CustomViewInput } from "shared/AllViewInputs";

const ViewAddressComponent = ({ data }) => {
  return (
    <>
      <CustomViewInput name="address" data={data} />
      <CustomViewInput name="houseNo" data={data} />
      <CustomViewInput name="street" data={data} />
      <CustomViewInput name="zipCode" data={data} />
      <CustomViewInput name="city" data={data} />
      <CustomViewInput name="cityDivision" data={data} />
      <CustomViewInput name="country" data={data} />
    </>
  );
};

export default ViewAddressComponent;
