import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const employeeSlice = createSlice({
    name: "employee",
    initialState,
    reducers: {
        setEmployeeSlice: (state, action) => {
            state.employee = action.payload
        },
    },

});

export const { setEmployeeSlice } = employeeSlice.actions;
export default employeeSlice.reducer;