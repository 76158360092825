import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { getTimeOnly, handleFormatDate } from "../../utils/commonFunctions";
import { showToastAction } from "../slices/commonSlice";

const getMaintenanceReqListAction = (company, rows, page, search, status, propertyId, damageCause, damageType, reportedDate, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.DAMAGE + `/${company}/?is_requested=true&limit=${rows}&page=${page}&search=${search}&status=${status || ""}&property_id=${propertyId || ""}&damage_cause=${damageCause || ""}&damage_type=${damageType || ""}&created_at=${handleFormatDate(reportedDate) || ""}`,
    );

    if (res?.success) {
        const info = res?.data?.results?.map((item) => {
            return {
                property_id: item?.property?.property_id,
                reportedDate: handleFormatDate(item.created_at),
                ...item
            }
        })
        onRes({ options: info, count: res?.data?.count })
    }
};

const handleDamageAction = (data, selectedContact, damageId, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        who_handle: data?.whoHandle,
        cost: data?.cost,
        response: data?.response,
        expected_date: data?.expectedDate,
        start_time: getTimeOnly(data?.startTime),
        end_time: getTimeOnly(data?.endTime),
        damage: damageId,
    }

    if (data?.whoHandle === "Landlord") {
        payload.landlord_contact = selectedContact

    } else {
        payload.handyman_service = selectedContact
    }

    const res = await api(
        "post",
        endPoints?.DAMAGE_HANDLE + "/",
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
};

const getMaintenanceCommentsListAction = (resportId, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.DAMAGE_REQUEST_COMMENT + `/?damage=${resportId}`,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes(info)
        // dispatch(setDamageReportSlice(info))
    }
}

const addMaintenanceCommentAction = (requestId, comment, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        comment: comment,
        damage: requestId
    }
    const res = await api(
        "post",
        endPoints?.DAMAGE_REQUEST_COMMENT + `/`,
        payload
    );

   if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
}

const updateMaintenanceCommentAction = (requestId, commentId, comment, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        comment: comment,
        damage: requestId
    }
    const res = await api(
        "put",
        endPoints?.DAMAGE_REQUEST_COMMENT + `/${commentId}/`,
        payload
    );

   if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
}

const deleteMaintenanceCommentAction = (commentId, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.DAMAGE_REQUEST_COMMENT + `/${commentId}/`,
    );

   if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    onRes(res)
    setLoading(false);
}

export {
    getMaintenanceReqListAction,
    handleDamageAction,
    getMaintenanceCommentsListAction,
    addMaintenanceCommentAction,
    updateMaintenanceCommentAction,
    deleteMaintenanceCommentAction
};