import React, { useEffect, useRef, useState } from "react";
import { Chips } from "primereact/chips";
import { CustomForm } from "../../shared/AllInputs";
import { emailValidation } from "../../utils/regex";
import {
  checkExistingUserAction,
  getCompanyRolesAction,
} from "../../store/actions/createProjectActions";
import { useDispatch } from "react-redux";
import SendInviteModal from "../../shared/Modals/SendInviteModal";
import { getUserInfo } from "../../services/auth";
import { Toast } from "primereact/toast";
const InviteEmployees = ({
  title,
  error,
  setError,
  allInvites,
  setAllInvites,
  disabled,
}) => {
  const dispatch = useDispatch();
  const msgs = useRef(null);
  const [sendInviteModal, setSendInviteModal] = useState(false);
  const [inviteEmails, setInviteEmails] = useState([]);
  const [emailToSendInvite, setEmailToSendInvite] = useState("");
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (allInvites) {
      let invites = allInvites?.map((invite) => {
        return invite?.recipient_email;
      });
      setInviteEmails(invites);
    }
  }, [allInvites]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let info = getUserInfo();
    if (info) {
      handleCompanyRoles(info?.company);
    }
  }, []);

  const handleCompanyRoles = async (company) => {
    dispatch(
      getCompanyRolesAction(company, (res) => {
        if (res?.success) {
          setRoles(res?.data?.results);
        }
      })
    );
  };

  const handleInputChange = async (e) => {
    const lastIndex = e.value?.length - 1;
    if (lastIndex > -1) {
      const isValid = emailValidation(e?.value[lastIndex]);
      if (e.value.length) {
        if (isValid) {
          setInviteEmails(e.value);
        }
      } else {
        setInviteEmails(e.value);
      }
    }
  };

  const handleAddEmail = (email) => {
    if (email) {
      const isInviteSent = allInvites.find(
        (invite) => invite?.recipient_email === email
      );

      if (!emailValidation(email)) {
        setError("Please enter a valid email!");
      } else if (email && !isInviteSent?.role) {
        setError("");
        setEmailToSendInvite(email);
        setSendInviteModal(true);
      } else {
        setSendInviteModal(false);
      }
    }
  };

  const handleExistingUser = async (email) => {
    setLoading(true);
    let payload = {
      email: email,
    };

    let isUserExist = "";
    dispatch(
      checkExistingUserAction(payload, (res) => {
        if (res?.success) {
          handleAddEmail(email);
        } else {
          msgs.current.show({
            severity: "error",
            detail: res?.message,
          });
          handleRemoveEmail(email);
          isUserExist = true;
        }
        setLoading(false);
      })
    );
    return isUserExist;
  };

  const handleRemoveEmail = (email) => {
    const invites = [...allInvites];
    const indexToRemove = invites.findIndex(
      (invite) => invite?.recipient_email === email
    );
    if (indexToRemove !== -1) {
      invites.splice(indexToRemove, 1);
    }

    setAllInvites(invites);

    const emails = [...inviteEmails];
    const indexToRemoveEmails = emails.findIndex(
      (inviteEmail) => inviteEmail === email
    );
    if (indexToRemoveEmails !== -1) {
      emails.splice(indexToRemoveEmails, 1);
    }
    setInviteEmails(emails);
    setSendInviteModal(false);
  };

  return (
    <>
      <CustomForm title={title}>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <div className="w-full">
        <label className="text-sm font-semibold mt-2">
        Email Address
        </label>
        <Chips
          className={`w-full mt-2 chips ${error ? "border-danger" : ""}`}
          placeholder={!disabled && "Enter email and press enter"}
          value={inviteEmails}
          onAdd={(e) => handleExistingUser(e.value)}
          onRemove={(e) => handleRemoveEmail(e.value)}
          onChange={(e) => handleInputChange(e)}
          allowDuplicate={false}
          disabled={disabled}
        />
        <small className="p-error">{error}</small>
        </div>
      </CustomForm>
      <SendInviteModal
        visible={sendInviteModal}
        setVisible={setSendInviteModal}
        email={emailToSendInvite}
        roles={roles}
        allInvites={allInvites}
        setAllInvites={setAllInvites}
        handleRemoveEmail={handleRemoveEmail}
      />
    </>
  );
};

export default InviteEmployees;
