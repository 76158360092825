import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import {
  CustomAutoComplete,
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "shared/AllInputs";
import EmployeeContainer from "./EmployeeContainer";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import AddOffices from "shared/Components/AddOffices";

export default function EmployeeForm({ type }) {
  const {
    data,
    handleChange,
    rolesList,
    allOffices,
    setAllOffices,
    onSubmit,
    loading,
    employeeIdOptions,
    // handleEmployeeIdChange,
    debouncedOnChange,
    navigate
  } = EmployeeContainer();

  return (
    <Page
      title={`${type} Employee`}
      description={`Let’s ${type} your Employee Details`}
      navigateLink={"/administrator/employees/list"}
    >
      <CustomCard>
        <CustomForm title="Employee Details">
          {/* <CustomDropDown
            data={data}
            onChange={handleEmployeeIdChange}
            name="employeeId"
            options={employeeIdOptions}
            label={"Employee ID"}
            placeholder={"Select Employee ID"}
            required
            editable
          /> */}
           <CustomAutoComplete
            data={data}
            onChange={handleChange}
            name="employeeId"
            suggestions={employeeIdOptions}
            label={"Employee ID"}
            placeholder={"Select Employee ID"}
            search={(e) => debouncedOnChange.current(e.query)}
            required
            editable
            max={9}
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="firstName"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="lastName"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="email"
            required
          />
          <CustomPhoneInput
            data={data}
            onChange={handleChange}
            name="phoneNumber"
            required
          />
        </CustomForm>
        <br />
        <CustomForm title="Role">
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="selectRole"
            placeholder="Select Role"
            options={rolesList}
            required
          />
        </CustomForm>
        <br />
        <AddOffices allOffices={allOffices} setAllOffices={setAllOffices} />
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label={type === "Edit"? "Save Changes" : "Save"} loading={loading} />
        <CustomButtonOutlined label="Cancel" onClick={() => navigate("/administrator/employees/list")}/>
      </Buttonlayout>
    </Page>
  );
}
