import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { canAccess } from "services/auth";
import { BuildingTypeOptions, StairAccessOptions } from "shared/AllDropDownOptions";
import { Dropdown } from "primereact/dropdown";
import manageColICon from "assets/images/icons/manage-col-icon.png";
import { debounce } from "lodash";
import {
  deleteBuildingAction,
  getBuildingListAction,
} from "store/actions/buildingActions";
import { setBuildingSlice } from "store/slices/buildingSlices";
// import { Tooltip } from "primereact/tooltip";
// import { truncateText } from "utils/regex";

const BuildingList = () => {
  const openAction = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalFilters = ["name", "type", "address", "staircase"];
  const [filters, setFilters] = useState({
    name: { value: "" },
    type: { value: "" },
    address: { value: "" },
    staircase: { value: "" },
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { profile } = useSelector((state) => state.user);
  const [buildingList, setBuildingList] = useState([]);
  const { building } = useSelector((state) => state?.building);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  // const [sortField, setSortField] = useState({ field: "", order: "" });
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState();
  const [columns, setColumns] = useState([]);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              setFirst(0);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.name?.value,
                filters?.type?.value,
                filters?.address?.value,
                filters?.staircase?.value
              );
            }}
            isSearch={isSearch}
            placeholder="Search Building"
          />
        </div>
        {canAccess("add_building") && <CustomButton
          size="small"
          icon="pi pi-plus"
          label="Add Building"
          onClick={() => navigate("/real-estate/property/building/add")}
        />}
      </div>
    );
  };

  const BuildingNameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="buildingName"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Building Name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const BuildingTypeFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={BuildingTypeOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Building Type"
          className="p-column-filter"
        />
      </div>
    );
  };

  // const AddressFilter = (options) => {
  //   return (
  //     <div>
  //       <CustomInput
  //         col={12}
  //         value={options.value}
  //         name="address"
  //         onChange={(e) => {
  //           options.filterCallback(e.value);
  //         }}
  //         optionLabel="name"
  //         placeholder="Address"
  //         className="p-column-filter"
  //         ignoreLabel
  //       />
  //     </div>
  //   );
  // };

  // const addressBodyTemplate = (options) => {
  //   return (
  //     <div>
  //       <Tooltip
  //         className="tooltip"
  //         target={`.tooltip-${options?.id}`}
  //         content={options?.address}
  //       />
  //       <span
  //         className={`tooltip-${options?.id}`}
  //         data-pr-classname="tooltip"
  //         style={{ cursor: "pointer" }}
  //       >
  //         <span
  //           // style={{
  //           //   color: options?.id === building?.id ? "white" : null,
  //           // }}
  //         >
  //           {truncateText(options?.address, 20)}
  //         </span>
  //       </span>
  //     </div>
  //   );
  // };

  const StairCaseFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={StairAccessOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Straircase Access"
          className="p-column-filter"
        />
      </div>
    );
  };

  const getStairCaseName = (staircase) => {
    switch (staircase) {
      case "direct":
        return "Direct Access";

      case "common":
        return "Access to Common Floor";

      case "combined":
        return "Combined Access";

      default:
        return "";
    }
  }

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Building Name",
        accessor: "name",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        filterElement: (option) => BuildingNameFilter(option),
      },
      // {
      //   name: "Address",
      //   accessor: "address",
      //   value: true,
      //   filter: true,
      //   body: (options) => addressBodyTemplate(options),
      //   filterElement: (options) => AddressFilter(options),
      // },
      {
        name: "Building Type",
        accessor: "type",
        value: true,
        filter: true,
        filterElement: (options) => BuildingTypeFilter(options),
      },
      {
        name: "Building Max Floor",
        accessor: "max_floor",
        sortable: false,
        value: true,
      },
      {
        name: "Staircase Access",
        accessor: "staircase",
        sortable: false,
        value: true,
        filter: true,
        body: (options) => getStairCaseName(options?.staircase),
        filterElement: (options) => StairCaseFilter(options),
      },
      {
        name: "Size",
        accessor: "size",
        sortable: false,
        value: true,
      },
      { name: "Street", accessor: "street", value: true },
      { name: "Zipcode", accessor: "zipcode", value: true },
      { name: "City", accessor: "city", value: false },
      { name: "City Division", accessor: "city_division", value: false },
      { name: "Country", accessor: "country", value: false },
    ],
    []
  );

  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/property/building/view/${d.id}`);
              dispatch(setBuildingSlice(d));
            }}
          >
            <i className="pi pi-eye text-dark"></i>
            View
          </li>
          {canAccess("delete_building") && <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
            <i
              className="pi pi-trash text-dark"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            ></i>{" "}
            Delete
          </li>}
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected building from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleBuildingDelete = () => {
    dispatch(
      deleteBuildingAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getBuildingList(profile?.company, rows, page, search);
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center cursor-pointer"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
          {/* Action */}
        </p>
      </>
    );
  };

  const getBuildingList = (
    company,
    rows,
    page,
    search,
    // order,
    name,
    type,
    address
  ) => {
    dispatch(
      getBuildingListAction(
        company,
        rows,
        page,
        search,
        // order,
        name,
        type,
        address,
        (res) => {
          setBuildingList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(
            `/real-estate/property/building/list?page=${page}&rows=${rows}`
          );
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    // order,
    name,
    type,
    address
  ) => {
    setIsSearch(true);
    getBuildingList(
      company,
      rows,
      page,
      search,
      // order,
      name,
      type,
      address
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getBuildingList(
        profile?.company,
        rows,
        page,
        search,
        filters?.name?.value,
        filters?.type?.value,
        filters?.address?.value,
        filters?.staircase?.value
      );
    }
  }, [
    profile?.company,
    // rows,
    // page,
    // first,
    filters?.name?.value,
    filters?.type?.value,
    filters?.address?.value,
    filters?.staircase?.value
  ]);

  const onPageChange = (e) => {
    // navigate(
    //   `/real-estate/property/building/list?page=${e.page + 1}&rows=${e.rows}`
    // );
    getBuildingList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.name?.value,
      filters?.type?.value,
      filters?.address?.value,
      filters?.staircase?.value
    );
    setFirst(e.first);
  };

  useEffect(() => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, []);

  return (
    <div>
      <CustomCard title="Building List" header={header()}>
        <CustomTableContainer
          list={buildingList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={building?.id}
          slice={setBuildingSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Building Name"
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleBuildingDelete}
        loading={loading}
      />
    </div>
  );
};

export default BuildingList;
