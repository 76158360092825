import React from "react";
import {
  CustomCalander,
  CustomDropDown,
  CustomForm,
  CustomInputMask,
} from "../AllInputs";
import { MaturityType } from "../AllDropDownOptions";
const Maturity = ({ title, data, handleChange }) => {
  return (
    <>
      <CustomForm title={title ? title : "Maturity"}>
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="maturityType"
          label={"Type of Maturity"}
          options={MaturityType}
          required
        />
      </CustomForm>
      {data?.maturityType === "None" || data?.maturityType == "" ? null : (
        <CustomForm>
          <CustomCalander
            minDate={new Date()}
            data={data}
            onChange={handleChange}
            name="maturityDate"
          />

          {data?.maturityType === "Automatic Renewal" && (
            <>
              {" "}
              <CustomInputMask
                data={data}
                onChange={handleChange}
                name="maturityExtension"
                spanLabel={"Months"}
                max={9}
              />
              <CustomInputMask
                data={data}
                onChange={handleChange}
                name="noticePeriod"
                spanLabel={"Months"}
                label={"Notice Period"}
                max={9}
              />
            </>
          )}
        </CustomForm>
      )}
    </>
  );
};

export default Maturity;
