import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const officeUnitSlice = createSlice({
    name: "officeUnit",
    initialState,
    reducers: {
        setOfficeUnitSlice: (state, action) => {
            state.officeUnit = action.payload
        },
    },

});

export const { setOfficeUnitSlice } = officeUnitSlice.actions;
export default officeUnitSlice.reducer;