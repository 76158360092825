import React, { useEffect, useState } from "react";
import CustomCard from "../../../../shared/CustomCard";
import Page from "../../../../shared/Page";
import { CustomForm } from "../../../../shared/AllInputs";
import { CustomViewInput } from "../../../../shared/AllViewInputs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserInfo } from "../../../../services/auth";
import { setUserProfile } from "../../../../store/slices/userSlice";
import {
  getDamageReportDetailAction,
  processDamageAction,
} from "../../../../store/actions/damageActions";
import CustomTableContainer from "../../../Tables/CustomTableContainer";
import CustomOverlay from "../../../../shared/CustomOverlay";
import ProcessIcon from "../../../../assets/images/svg/processicon.svg";

const DamageHistory = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state?.user);
  const [data, setData] = useState({
    propertyID: "",
    address: "",
    landlordName: "",
    movingDate: "",
    typeOfMaturity: "",
    rent: "",
    tenantsName: "",
    status: "",
    damages: [],
  });
  const [selectedItems, setSelectedItems] = useState()
  const statusBodyTemplate = (options) => {
    return (
      <span className={getStatusClass(options?.status)}>{options?.status}</span>
    );
  };

  const damageBodyTemplate = (options) => {
    return <p>{options?.name}</p>;
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "New":
        return "text-red-600";

      case "Back Office":
        return "text-yellow-500";

      case "Pending":
        return "text-green-600";

      case "Social Worker":
        return "text-blue-600";

      default:
        return "";
    }
  };

  const dateBodyTemplate = (options) => {
    const date = new Date(options?.created_at);
    let reportedDate = date.toLocaleDateString();
    return <div>{reportedDate}</div>;
  };

  const columns = [
    {
      name: "Reported Date",
      accessor: "created_at",
      body: (options) => dateBodyTemplate(options),
    },
    {
      name: "Status",
      accessor: "status",
      body: (options) => statusBodyTemplate(options),
    },
    {
      name: "Damage Type",
      accessor: "damage_type",
      body: (options) => damageBodyTemplate(options?.damage_type),
    },
    {
      name: "Damage Cause",
      accessor: "damage_cause",
    },
  ];

  useEffect(() => {
    if (!profile?.company) {
      let info = getUserInfo();
      // dispatch(setUserProfile(info));
    }
  }, [profile]);

  useEffect(() => {
    if (params?.id && profile?.company) {
      getDamageReportDetails(profile?.company, params?.id);
    }
  }, [params?.id, profile]);

  const getDamageReportDetails = () => {
    dispatch(
      getDamageReportDetailAction(
        profile?.company,
        params?.id,
        dispatch,
        (res) => {
          setData({
            propertyID: res?.property?.property_id,
            address: res?.property?.address,
            landlordName: res?.landlord_name,
            movingDate: res?.property?.contract?.moving_in_date,
            typeOfMaturity: res?.property?.contract?.maturity_type,
            rent: res?.property?.contract?.rent_rise_type,
            // tenantsName: res?.property,
            status: res?.property?.status,
            damages: res?.damages,
          });
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p className="flex gap-2 align-items-center">Action</p>
      </>
    );
  };

  const actions = (d) => {
    return (
      <CustomOverlay>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              handleProcessDamage(d?.id);
            }}
          >
            <img src={ProcessIcon} /> Process
          </li>
        </ul>
      </CustomOverlay>
    );
  };

  const handleProcessDamage = (damageId) => {
    dispatch(
      processDamageAction(profile?.company, damageId, dispatch, (res) => {})
    );
  };

  return (
    <Page
      title={`Damage History`}
      navigateLink={"/real-estate/maintenance/damage/list"}
    >
      <CustomCard>
        <CustomForm title={`Property Details`}>
          <CustomViewInput data={data} name="propertyID" />
          <CustomViewInput data={data} name="address" />
          <CustomViewInput data={data} name="landlordName" />
          <CustomViewInput data={data} name="movingDate" />
          <CustomViewInput data={data} name="typeOfMaturity" />
          <CustomViewInput data={data} name="rent" />
          <CustomViewInput data={data} name="tenantsName" />
          <CustomViewInput data={data} name="status" />
        </CustomForm>
        <CustomTableContainer
          columns={columns}
          list={data?.damages}
          ActionTemplate={ActionTemplate}
          actions={actions}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </CustomCard>
    </Page>
  );
};

export default DamageHistory;
