import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const maintenanceReqSlice = createSlice({
    name: "maintenanceReq",
    initialState,
    reducers: {
        setMaintenanceReqSlice: (state, action) => {
            state.maintenanceReq = action.payload
        },
    },

});

export const { setMaintenanceReqSlice } = maintenanceReqSlice.actions;
export default maintenanceReqSlice.reducer;