import React from "react";
import { CustomViewInput } from "../../../../../shared/AllViewInputs";
import { CustomForm } from "../../../../../shared/AllInputs";
// import EditIcon from "../../../../../assets/images/icons/editicon.png";
import { handleFormatDate } from "../../../../../utils/commonFunctions";
// import { useNavigate } from "react-router-dom";/
// import EditIcon from "../../../../assets/images/icons/editicon.png";
const HandleDamageView = ({ data }) => {
  //   const navigate = useNavigate();
  // const header = () => {
  //   return (
  //     <div className="flex justify-content-around cursor-pointer">
  //       <img
  //         src={EditIcon}
  //         alt=""
  //         //   onClick={() => navigate(`/administrator/office/edit/${params?.id}`)}
  //       ></img>
  //     </div>
  //   );
  // };

  return (
    <>
      <CustomForm title="Maintenance Details">
        <CustomViewInput
          data={data}
          name="who_handle"
          label={"Who will Handle it?"}
        />
        <CustomViewInput data={data} name="cost" />
      </CustomForm>
      <CustomForm
        title={
          data?.who_handle === "Landlord" ? "Landlord Info" : "Handyman Details"
        }
      >
        <CustomViewInput
          value={data?.landlord_contact?.name || data?.handyman_service?.name}
          name="name"
        />
        {data?.landlord_contact?.role ? (
          <CustomViewInput data={data?.landlord_contact} name="role" />
        ) : (
          <CustomViewInput data={data?.handyman_service} name="service" />
        )}
        <CustomViewInput
          data={data?.landlord_contact || data?.handyman_service}
          name="email"
        />
        {data?.landlord_contact?.office_phone ? (
          <CustomViewInput data={data?.landlord_contact} name="office_phone" />
        ) : (
          <CustomViewInput data={data?.handyman_service} name="work_phone" />
        )}

        <CustomViewInput
          value={handleFormatDate(data?.expected_date)}
          name="expected_date"
        />
        <CustomViewInput data={data} name="start_time" />
        <CustomViewInput data={data} name="end_time" />
      </CustomForm>
    </>
  );
};

export default HandleDamageView;
