const endPoints = {
  //authentication
  LOGIN: "/auth/login/",
  REGISTER: "/auth/signup/",
  SEND_OTP: "/auth/send-otp/",
  VERIFY_OTP: "/auth/verify-otp/",
  CHANGE_FORGOT_PASSWORD: "/auth/forgot-password/",
  SETUP_ACCOUNT: "/auth/setup-account/",
  VERIFY_TOKEN: "/invite-token-verify/",

  //profile
  PROFILE: "/auth/user-detail/",
  CHECK_EXISTING_USER: "/auth/check-user/",

  //roles
  COMPANY_ROLES: "/office/roles/",
  ROLES_LIST: "/office/employee_role/",
  EMP_ROLE_CHANGE: "/employees-role-change/",

  //office
  ADD_OFFICE: "/office/offices/",
  OFFICE_LIST: "/office/offices/",
  SEARCH_OFFICE_ID: "/office/office-id-suggestion",
  OFFICE_BANK_ACCOUNTS: "/office/bank-accounts/",
  OFFICE_EMPLOYEE: "/office/office_employee/",

  //officeunit
  ADD_OFFICE_UNIT: "/office/unit/",
  OFFICE_UNIT_LIST: "/office/unit/",

  //employees
  EMPLOYEE_LIST: "/employee/",
  SEARCH_EMPLOYEE_ID: "/employee-id-suggestion",

  //Property
  PROPERTY: "/property/",
  PROPERTY_SUGGESTIONS: "/property-id-suggestion",

  //Building
  BUILDING: "/building/",
  GET_BUILDING: "/get-building/",

  //Building Unit
  BUILDING_UNIT: "/building-unit/",
  PROPERTY_BUILDING_UNIT: "/property-building-unit",
  UNIT_ROOM: "/unit-room/",
  ENERGY_CLOCK: "/energy-clock/",
  ENERGY_METER: "/energy-meter/",


  //landlord
  LANDLORD: "/landlord/",
  LANDLORD_DROPDOWN: "/landlord-dropdown",
  PROPERTY_LANDLORD: "/property-landlord/",
  GET_LANDLORD_BYID: "/get-landlord/",
  LANDLORD_BANK_ACCOUNTS: "/landlord-bank-accounts/",
  LANDLORD_FURNITURE: "/landlord-furniture/",
  LANDLORD_CONTACT: "/landlord-contact/",
  LANDLORD_SUGGESTIONS: "/landlord-id-suggestion",
  LANDLORD_SEARCH: "/landlord-search",
  
  //contract
  CONTRACT: "/contract/",
  CONTRACT_SUGGESTIONS: "/contract-id-suggestion",
  PROPERTY_CONTRACT: "/property-contract",
  GET_CONTRACT_BYID: "/get-contract/",
  CONTRACT_MATURITY: "/contract-maturity/",
  CONTRACT_EXTRA_COST: "/contract-extra-cost/",
  CONTRACT_PROPERTY_LIST: "/contract-property",

  //handyman services
  HANDYMAN_SERVICE: "/services",

  //Handyman
  HANDYMAN: "/handyman",
  SERVICE: "/handymanservices/",
  SEARCH_HANDYMAN: "/handyman-search/",
  //damage report
  DAMAGE_REPORT: "/damage-report",

  //damage
  DAMAGE: "/damage",
  DAMAGE_HANDLE: "/damage-handles",
  DAMAGE_FEEDBACK: "/damage-feedback",
  DAMAGE_REPORT_COMMENTS: "/damage-report-comment",
  DAMAGE_REQUEST_COMMENT : "/damage-comments",
  //Annual statement
  ANNUAL_STATEMENT: "/annualstatement",
  MAINTENANCE_COSTS: "/maintenance-costs",
};
export default endPoints;
