import React, { useEffect, useState } from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomForm } from "shared/AllInputs";
import {
  CustomViewArrayInput,
  CustomViewInput,
} from "shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOfficeUnitInfoAction } from "store/actions/officeUnitActions";
import EditIcon from "assets/images/icons/editicon.png";
import { canAccess } from "services/auth";
export default function ViewOfficeUnit() {
  const [data, setData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    if (profile?.company) {
      getUnitInfo(profile?.company);
    }
  }, [profile]);

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/administrator/office-unit/edit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  const getUnitInfo = (company) => {
    dispatch(
      getOfficeUnitInfoAction(company, params?.id, dispatch, (res) => {
          let offices = res?.offices?.map((office) => {
            return office?.name;
          });
          let info = {
            unitName: res?.name,
            email: res?.email,
            phoneNumber: res?.phone_number,
            costCenter: res?.cost_center,
          };
          if (
            res?.head_1?.user?.first_name ||
            res?.head_1?.user?.last_name
          ) {
            let firstName = res?.head_1?.user?.first_name || "";
            let lastName = res?.head_1?.user?.last_name || "";
            info.unitHead1 = `${firstName} ${lastName}`.trim();
          }
          if (
            res?.head_2?.user?.first_name ||
            res?.head_2?.user?.last_name
          ) {
            let firstName = res?.head_2?.user?.first_name || "";
            let lastName = res?.head_2?.user?.last_name || "";
            info.unitHead2 = `${firstName} ${lastName}`.trim();
          }
          if (
            res?.head_3?.user?.first_name ||
            res?.head_3?.user?.last_name
          ) {
            let firstName = res?.head_3?.user?.first_name || "";
            let lastName = res?.head_3?.user?.last_name || "";
            info.unitCohead = `${firstName} ${lastName}`.trim();
          }

          if (offices?.length) {
            info.offices = offices;
          }
          setData(info);
      })
    );
  };

  return (
    <Page title="View Details" description={`Let’s view your Unit Details`} navigateLink={"/administrator/office-unit/list"}>
      <CustomCard>
        <CustomForm title="General" header={canAccess("change_officeunit") && header()}>
          <CustomViewInput name="unitName" data={data} />
          <CustomViewArrayInput name="offices" data={data} />
          <CustomViewInput name="email" data={data} />
          <CustomViewInput name="phoneNumber" data={data} />
          <CustomViewInput name="costCenter" data={data} />
        </CustomForm>
        <CustomForm title="Employee">
          <CustomViewInput name="unitHead1" data={data} label="Unit Head 1" />
          <CustomViewInput name="unitHead2" data={data} label="Unit Head 2" />
          <CustomViewInput name="unitCohead" data={data} label="Unit Co-Head" />
        </CustomForm>
      </CustomCard>
    </Page>
  );
}
