import React, { useState } from "react";
import { CustomAmountInput, CustomDropDown, CustomForm, CustomNumberInput2 } from "../AllInputs";
import { DepositType } from "../AllDropDownOptions";
const Deposits = ({ title, data, handleChange }) => {
  const [value, setValue] = useState();

  return (
    <>
      <CustomForm title={title ? title : "Deposits"}>
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="depositType"
          options={DepositType}
          required
        />
        {data.depositType !== "None" && (
         <> 
         {/* <CustomNumberInput2
            data={data}
            onChange={handleChange}
            name="amount"
            required
          /> */}
          <CustomAmountInput
            data={data}
            onChange={handleChange}
            name="amount"
            required
          />
        </>
        )}
      </CustomForm>
    </>
  );
};

export default Deposits;
