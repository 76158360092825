import React, { useState } from "react";
import {
  CustomAmountInput,
  CustomDropDown,
  CustomForm,
} from "shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "utils/validations";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { handleDelete } from "shared/Components/commonFunctions";
import {
  convertAmountInGerman,
} from "utils/commonFunctions";
import {
  ExtraCostPayemntType,
  ExtraCostType,
  YesNoOptions,
} from "shared/AllDropDownOptions";
import { deleteExtraCostAction, getContractAction, getPropertyContractAction, updateExtraCostAction } from "store/actions/contractActions";
import { useParams } from "react-router-dom";
import CrossIcon from "assets/images/svg/cross-icon.svg"
import SaveIcon from "assets/images/svg/Save-icon.svg"

export default function CustomExtraCostTable({
  action,
  id,
  title,
  allExtraCost,
  setAllExtraCost,
}) {
  const params = useParams()
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const { profile } = useSelector((state) => state?.user)

  const TypeEditor = (options) => {
    const formErrors = formValidation("type", options.value, allExtraCost);
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="type"
        col={12}
        options={ExtraCostType}
        ignoreLabel
        errorMessage={formErrors["type"]}
      />
    );
  };

  const PaymentEditor = (options) => {
    const formErrors = formValidation(
      "payment_type",
      options.value,
      allExtraCost
    );
    return (
      <CustomDropDown
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="payment_type"
        col={12}
        options={ExtraCostPayemntType}
        ignoreLabel
        errorMessage={formErrors["payment_type"]}
      />
    );
  };

  const AmountEditor = (options) => {
    const formErrors = formValidation("amount", options.value, allExtraCost);

    return (
      <CustomAmountInput
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.value);
        }}
        name="amount"
        ignoreLabel
        errorMessage={formErrors["amount"]}
      />
    );
  };

  const FirstMonthEditor = (options) => {
    const formErrors = formValidation(
      "amount_first_month",
      options.value,
      allExtraCost
    );
    return (
      <CustomAmountInput
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.value);
        }}
        name="amount_first_month"
        col={12}
        ignoreLabel
        errorMessage={formErrors["amount_first_month"]}
      />
    );
  };

  const LastMonthEditor = (options) => {
    const formErrors = formValidation(
      "amount_last_month",
      options.value,
      allExtraCost
    );
    return (
      <CustomAmountInput
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.value);
        }}
        name="amount_last_month"
        col={12}
        ignoreLabel
        errorMessage={formErrors["amount_last_month"]}
      />
    );
  };

  const TenantEditor = (options) => {
    const formErrors = formValidation(
      "applicable_tenant_rent",
      options.value,
      allExtraCost
    );
    return (
      <CustomDropDown
        type="text"
        col={12}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        options={YesNoOptions}
        name="applicable_tenant_rent"
        ignoreLabel
        errorMessage={formErrors["applicable_tenant_rent"]}
      />
    );
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              className="cursor-pointer"
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <img src={SaveIcon} />
            </a>
            <a
              className="cursor-pointer"
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
             
                <img src={CrossIcon} />
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li className="flex gap-2 text-xs font-medium mb-3">
                  <i
                    className="pi pi-trash cursor-pointer text-dark"
                    onClick={() => {
                      setIdToDelete({
                        id: rowData?.id,
                        index: options?.rowIndex,
                      });
                      setDeleteModal(true);
                    }}
                  ></i>{" "}
                  Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const rowEditValidator = async (rowData, options) => {
    const {
      type,
      payment_type,
      amount,
      amount_first_month,
      amount_last_month,
      applicable_tenant_rent,
    } = rowData;

    const typeError = formValidation("type", type, allExtraCost);
    const payment_typeError = formValidation(
      "payment_type",
      payment_type,
      allExtraCost
    );
    const amountError = formValidation("amount", amount, allExtraCost);
    const amountFirstMonthError = formValidation(
      "amount_first_month",
      amount_first_month,
      allExtraCost
    );
    const amountLastMonthError = formValidation(
      "amount_last_month",
      amount_last_month,
      allExtraCost
    );
    const applicableTenantRentError = formValidation(
      "applicable_tenant_rent",
      applicable_tenant_rent,
      allExtraCost
    );

    if (
      !typeError["type"] &&
      !payment_typeError["payment_type"] &&
      !amountFirstMonthError["amount_first_month"] &&
      !amountLastMonthError["amount_last_month"] &&
      !applicableTenantRentError["applicable_tenant_rent"] &&
      !amountError["amount"]
    ) {
      let payload = {
        type: type,
        payment_type: payment_type,
        amount_first_month: amount_first_month,
        amount_last_month: amount_last_month,
        applicable_tenant_rent: applicable_tenant_rent,
        amount: amount,
        contract: id,
      };
      dispatch(
        updateExtraCostAction(rowData?.id, payload, dispatch, (res) => {
            let meters = [...allExtraCost];
            meters[options.rowIndex] = rowData;
            setAllExtraCost(meters);
            setEditingRows({});
            handleGetDetails()
            return true;
        })
      );
    } else {
      return false;
    }
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const handleDeleteExtraCost = () => {
    setLoading(true);
    dispatch(
        deleteExtraCostAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDelete(allExtraCost,  setAllExtraCost,  "",  "",  idToDelete?.index);
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
          handleGetDetails()
      })
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected extra cost from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleGetDetails = () => {
    if(action === "property"){
      dispatch(getPropertyContractAction(params?.id, dispatch))
    }else if(action === "contract"){
      dispatch(getContractAction(profile?.company, params?.id, dispatch))
    }
  }

  return (
    <CustomForm title={title}>
      <DataTable
        className="col-12 table-error-align"
        value={allExtraCost}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        // onRowEditComplete={onRowEditComplete}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "100rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="type"
          header="Type"
          editor={(options) => TypeEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="payment_type"
          header="Payment Type"
          editor={(options) => PaymentEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="amount"
          header="Amount"
          editor={(options) => AmountEditor(options)}
          body={(rowData) => (
            rowData.amount && convertAmountInGerman(rowData.amount)
          )}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="amount_first_month"
          header="Amount First Month"
          body={(rowData) => (
            rowData.amount && convertAmountInGerman(rowData.amount_first_month)
          )}
          editor={(options) => FirstMonthEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="amount_last_month"
          header="Amount Last Month"
          editor={(options) => LastMonthEditor(options)}
          body={(rowData) => (
            rowData.amount && convertAmountInGerman(rowData.amount_last_month)
          )}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          className="firstletter_capital"
          field="applicable_tenant_rent"
          header="Applicable for tenant Rent"
          editor={(options) => TenantEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          header="Action"
          headerClassName="custom-header"
          rowEditor
          // headerStyle={{ minWidth: "8rem" }}
          // bodyStyle={{ textAlign: "center" }}
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteExtraCost}
        loading={loading}
      />
    </CustomForm>
  );
}
