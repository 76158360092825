import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import CustomCard from "shared/CustomCard";
import CustomModal from "shared/Modals/CustomModal";
import {
  CustomCalander,
  CustomDropDown,
  CustomInput,
  CustomNumberInput2,
  CustomSearch,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import CustomOverlay from "shared/CustomOverlay";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import {
  deleteDamageReportAction,
  getDamageReportListAction,
  processDamageAction,
} from "store/actions/damageActions";
import { setDamageReportSlice } from "store/slices/damageSlice";
import { damageReportType } from "shared/AllDropDownOptions";
import ProcessIcon from "assets/images/svg/processicon.svg";
import { handleFormatDate } from "utils/commonFunctions";
import { canAccess } from "services/auth";
const DamageReportList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalFilters = [
    "created_at",
    "status",
    "property_id",
    "number_damages",
  ];
  const [damageList, setDamageList] = useState([]);
  const { profile } = useSelector((state) => state.user);
  // const [filters, setFilters] = useState({
  //   created_at: { value: "" },
  //   status: { value: "" },
  //   property_id: { value: "" },
  //   number_damages: { value: "" },
  // });

  const [filters, setFilters] = useState({
    created_at: "",
    status: "",
    property_id: "",
    number_damages: "",
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { damageReport } = useSelector((state) => state?.damage);
  const [expandedRows, setExpandedRows] = useState(null);
  const [selectedReports, setSelectedReports] = useState();
  const [selectedDamages, setSelectedDamages] = useState();

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex align-items-center justify-content-end flex-wrap align-items-center">
        <div className="w-8 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setFirst(0);
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                search,
                filters?.created_at,
                filters?.status,
                filters?.property_id,
                filters?.number_damages
              );
            }}
            isSearch={isSearch}
            placeholder="Search Damage"
          />
        </div>
        {canAccess("add_damagereport") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            extraClassNames="mr-3"
            label="Report Damage"
            onClick={() =>
              navigate("/real-estate/maintenance/damage-report/add")
            }
          />
        )}
        <CustomOverlay>
          <ul className="no-style p-0">
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-download cursor-pointer text-dark"></i>
              Import
            </li>
            <li className="flex gap-2 text-xs font-medium mb-3">
              <i className="pi pi-upload cursor-pointer text-dark"></i> Export
            </li>
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const ReportedDateFilter = () => {
    return (
      <div>
        <CustomCalander
          col={12}
          data={filters}
          name="created_at"
          onChange={handleFilterChange}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const StatusFilter = (options) => {
    return (
      <div>
        <CustomDropDown
          col={12}
          data={filters}
          name="status"
          options={damageReportType}
          onChange={handleFilterChange}
          optionLabel="name"
          placeholder="Type of Status"
          className="p-column-filter"
        />
      </div>
    );
  };

  const PropertyIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          data={filters}
          name="property_id"
          onChange={handleFilterChange}
          placeholder="Property ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const DamageNumFilter = (options) => {
    return (
      <div>
        <CustomNumberInput2
          col={12}
          data={filters}
          name="number_damages"
          onChange={handleFilterChange}
          className="p-column-filter"
          placeholder="Enter No of Damages"
          ignoreLabel
        />
      </div>
    );
  };

  const statusBodyTemplate = (options) => {
    return (
      <span className={getStatusClass(options?.status)}>{options?.status}</span>
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "New":
        return "text-red-600";

      case "Back Office":
        return "text-yellow-500";

      case "Pending":
        return "text-green-600";

      case "Social Worker":
        return "text-blue-600";

      default:
        return "";
    }
  };

  const handleFilterChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value || "" }));
  };

  const columns = useMemo(
    () => [
      {
        name: "Reported Date",
        accessor: "created_at",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        dataType: "date",
        body: (options) => handleFormatDate(options?.created_at),
        filterElement: (option) => ReportedDateFilter(option),
      },
      {
        name: "Status",
        accessor: "status",
        value: true,
        filter: true,
        body: (options) => statusBodyTemplate(options),
        filterElement: (option) => StatusFilter(option),
      },
      {
        name: "Property ID",
        accessor: "property_id",
        value: true,
        filter: true,
        filterElement: (options) => PropertyIDFilter(options),
      },
      {
        name: "Landlord Name",
        accessor: "landlord_name",
        sortable: false,
        value: true,
      },
      {
        name: "No of damages",
        accessor: "number_damages",
        value: true,
        filter: true,
        filterElement: (options) => DamageNumFilter(options),
      },
    ],
    [filters]
  );

  const damageColumns = useMemo(() => [
    {
      name: "Sr no.",
      accessor: "srNo",
      value: true,
    },
    {
      name: "Date",
      accessor: "created_at",
      value: true,
      body: (options) => handleFormatDate(options?.created_at),
    },
    {
      name: "Damage Cause",
      accessor: "damage_cause",
      value: true,
    },
    {
      name: "Damage Type",
      accessor: "damage_type",
      value: true,
      body: (options) => options?.damage_type?.name,
    },
  ]);

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/maintenance/damage-report/view/${d.id}`);
              dispatch(setDamageReportSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {canAccess("delete_damagereport") && (
            <li
              className="flex gap-2 text-xs font-medium mb-3"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            >
              <i className="pi pi-trash cursor-pointer text-dark"></i> Delete
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const damageActions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(
                `/real-estate/maintenance/damage-report/damage/edit/${d?.id}`
              );
            }}
          >
            <i className="pi pi-file-edit text-dark"></i>
            Edit
          </li>
          {!d?.is_requested && (
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                handleProcessDamage(d?.id);
              }}
            >
              <img src={ProcessIcon} /> Process
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const handleProcessDamage = (id, index) => {
    dispatch(processDamageAction(profile?.company, id, dispatch, (res) => {}));
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected damage report from the
        list? This action cannot be undone.
      </span>
    );
  };

  const handleDamageReportDelete = () => {
    dispatch(
      deleteDamageReportAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getDamageReportList(profile?.company, rows, page, search);
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          // onClick={(e) => openAction.current.toggle(e)}
        >
          {/* <img src={manageColICon} style={{ width: "27px" }} /> */}
          {/* <i className="pi pi-chevron-down"></i> */}
          Action
        </p>
      </>
    );
  };

  const damageActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          // onClick={(e) => openAction.current.toggle(e)}
        >
          {/* <img src={manageColICon} style={{ width: "27px" }} /> */}
          {/* <i className="pi pi-chevron-down"></i> */}
          Action
        </p>
      </>
    );
  };

  const getDamageReportList = (
    company,
    rows,
    page,
    search,
    createdAt,
    status,
    propertyId,
    numberDamages
  ) => {
    dispatch(
      getDamageReportListAction(
        company,
        rows,
        page,
        search,
        createdAt,
        status,
        propertyId,
        numberDamages,
        (res) => {
          setDamageList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(
            `/real-estate/maintenance/damage/list?page=${page}&rows=${rows}`
          );
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    createdAt,
    status,
    propertyId,
    numberDamages
  ) => {
    setIsSearch(true);
    getDamageReportList(
      company,
      rows,
      page,
      search,
      createdAt,
      status,
      propertyId,
      numberDamages
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getDamageReportList(
        profile?.company,
        rows,
        page,
        search,
        filters?.created_at,
        filters?.status,
        filters?.property_id,
        filters?.number_damages
      );
    }
  }, [profile?.company]);

  const onPageChange = (e) => {
    // navigate(
    //   `/real-estate/maintenance/damage/list?page=${e.page + 1}&rows=${e.rows}`
    // );
    getDamageReportList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.created_at,
      filters?.status,
      filters?.property_id,
      filters?.number_damages
    );
    setFirst(e.first);
  };

  const allowExpansion = (rowData) => {
    return rowData?.damages?.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    const list = data?.damages?.map((item, index) => {
      return {
        srNo: index + 1,
        ...item,
      };
    });
    return (
      <div className="p-3">
        <span className="title">Damages</span>
        <CustomTableContainer
          list={list}
          columns={damageColumns}
          actions={damageActions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={damageActionTemplate}
          selectedItems={selectedDamages}
          setSelectedItems={setSelectedDamages}
          // onPageChange={onPageChange}
        />
      </div>
    );
  };

  const applyFilters = (options) => {
    getDamageReportList(
      profile?.company,
      rows,
      page,
      "",
      filters?.created_at,
      filters?.status,
      filters?.property_id,
      filters?.number_damages
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getDamageReportList(
      profile?.company,
      rows,
      page,
      "",
      field === "created_at" ? "" : filters?.created_at,
      field === "status" ? "" : filters?.status,
      field === "property_id" ? "" : filters?.property_id,
      field === "number_damages" ? "" : filters?.number_damages
    );
  };

  return (
    <div>
      <CustomCard
        className="damage-list-table"
        title="Damage Report List"
        header={header()}
      >
        <CustomTableContainer
          list={damageList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={damageReport?.id}
          slice={setDamageReportSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          allowExpansion={allowExpansion}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
          rowExpansionTemplate={rowExpansionTemplate}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedReports}
          setSelectedItems={setSelectedReports}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDamageReportDelete}
        loading={loading}
      />
    </div>
  );
};

export default DamageReportList;
