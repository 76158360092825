import React from "react";
import { CustomImageView } from "../../shared/AllViewInputs";

const DamageView = ({ damage }) => {
  return (
    <div className="p-2 w-full border-round-xl grid mt-3">
      <div className="col-6">
        <h4 className="my-2 capitalize">{damage?.service?.name}</h4>
        <p className="my-2">Damage Cause</p>
        <p className="font-bold mt-2">{damage?.damageCause}</p>
      </div>
      <div className="col-6">
        <p className="mb-2">Damage Type</p>
        <span className="font-bold">{damage?.damageType?.name}</span>
      </div>
      <p className="col-12">
        <span className="title">Damage Description </span>{" "}
        <p className="mt-2">{damage?.damageDescription}</p>
      </p>
      <p className="col-12">
        <span className="title">Damage Photos </span>{" "}
        <div className="flex gap-4 flex-wrap mt-2">
          {damage?.images?.map((image) => {
            return <CustomImageView image={`${image?.image}`} />;
          })}
        </div>
      </p>
    </div>
  );
};

export default DamageView;
