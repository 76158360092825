import React from "react";
import { CustomInput, CustomNumberInput2 } from "shared/AllInputs";
import GooglePlacesInput from "shared/GooglePlacesInput";

const AddressComponent = ({id, data, handleChange, setAddress, isDisabled}) => {
  return (
    <>
      <GooglePlacesInput
        id={id}
        data={data}
        onChange={handleChange}
        name="address"
        required
        setAddress={setAddress}
        disabled={isDisabled}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="houseNo"
        required
        disabled={isDisabled}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="street"
        required
        disabled={isDisabled}
      />
      <CustomNumberInput2
        data={data}
        onChange={handleChange}
        name="zipCode"
        required
        disabled={isDisabled}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="city"
        required
        disabled={isDisabled}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="cityDivision"
        required
        disabled={isDisabled}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="country"
        required
        disabled={isDisabled}
      />
    </>
  );
};

export default AddressComponent;
