import React, { useState } from "react";
import { CustomForm, CustomInput } from "../../shared/AllInputs";
import { Avatar } from "primereact/avatar";
import CustomButton from "shared/CustomButton";
import formValidation from "utils/validations";
import { getUserAvtar, hoursAgo, showFormErrors } from "utils/commonFunctions";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";

const CommentsTable = ({ comments, onAdd, onUpdate, onDelete }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    comment: "",
  });
  const [selectedComment, setSelectedComment] = useState({
    id: "",
    index: "",
    action: "",
  });
  const [isDelete, setIsDelete] = useState(false);

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const addComment = () => {
    if (showFormErrors(data, setData)) {
      setLoading(true);
      selectedComment?.id
        ? onUpdate(
            selectedComment?.id,
            selectedComment?.index,
            data?.comment,
            setLoading
          )
        : onAdd(data?.comment, setLoading);
      setData({ comment: "" });
      setSelectedComment({ id: "", index: "", action: "" });
    }
  };

  const updateComment = (id, index) => {
    const comment = comments.find((item) => item?.id === id);
    setData({ comment: comment?.comment });
    setSelectedComment({ id: id, index: index, action: "edit" });
  };

  const deleteComment = () => {
    onDelete(selectedComment?.id, setLoading, (res) => {
      setIsDelete(false);
      setSelectedComment({ id: "", index: "", action: "" });
    });
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the comment from the list? This action
        cannot be undone.
      </span>
    );
  };

  return (
    <div className="border-1 border-400 p-4 border-round-xl">
      <CustomForm title={"Comments"} extraClassName="comment-heading">
        <ul className="w-full p-0 relative">
          {comments?.map((comment, index) => {
            return (
              <li
                className="flex relative border-dashed w-auto mx-7 border-bottom-1 border-400 py-3 w-full justify-content-between align-items-center border-none"
                key={index}
              >
                <div className="flex align-items-center gap-3 w-min-content">
                  <Avatar
                    label={getUserAvtar(
                      comment?.user?.first_name,
                      comment?.user?.last_name
                    )}
                    size="large"
                    style={{ backgroundColor: "#0094DA", color: "#ffffff", marginLeft:'-64px' }}
                    shape="circle"
                  />
                  <div>
                    <p className="text-lg font-semibold capitalize">
                      {comment?.user?.first_name} {comment?.user?.last_name}
                    </p>
                    <p className="text-md text-grayy-500 capitalize">{comment?.comment}</p>
                  </div>
                </div>
                <div className="">
                <span className="text-gray-500 text-md">
                  {hoursAgo(comment?.created_at)} hour ago
                </span>
                <CustomOverlay extraClass="three-dot absolute top-50">
                  <ul className="no-style p-0">
                    <li
                      className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                      onClick={() => {
                        updateComment(comment?.id, index);
                      }}
                    >
                      <i className="pi pi-file-edit text-dark"></i>
                      Edit
                    </li>
                    <li
                      className="flex gap-2 text-xs font-medium mb-3"
                      onClick={() => {
                        setIsDelete(true);
                        setSelectedComment({
                          id: comment?.id,
                          index: index,
                          action: "delete",
                        });
                      }}
                    >
                      <i className="pi pi-trash cursor-pointer text-dark"></i>{" "}
                      Delete
                    </li>
                  </ul>
                </CustomOverlay>
                </div>
              </li>
            );
          })}
          <div className="flex align-items-center flex-wrap mt-3">
            {/* <InputText id="username" aria-describedby="username-help" className="w-full" placeholder="Enter comments"/> */}
            <CustomInput name="comment" data={data} onChange={handleChange} extraClassName="comment-input xl:col-10"/>
            <CustomButton
              type="button"
              extraClassNames="w-autoo col-2 add-comment mt-4 flex-shrink-0 mb-0"
              label={
                selectedComment?.action === "edit"
                  ? "Update Comment"
                  : "Add Comment"
              }
              onClick={addComment}
              loading={loading}
            />
          </div>
        </ul>
      </CustomForm>
      <CustomModal
        isOpen={isDelete}
        setIsOpen={setIsDelete}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={deleteComment}
        loading={loading}
      />
    </div>
  );
};

export default CommentsTable;
