import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { debounce } from "lodash";
import CustomCard from "shared/CustomCard";
import CustomModal from "shared/Modals/CustomModal";
import {
  CustomDropDown,
  CustomInput,
  CustomRatings,
  CustomSearch,
  CustomTextArea,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import CustomOverlay from "shared/CustomOverlay";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import {
  changeServiceStatusActon,
  deleteHandymanAction,
  getHandymanListAction,
} from "store/actions/HandymanActions";
import { Tooltip } from "primereact/tooltip";
import { truncateText } from "utils/regex";
import {
  getAllHandymanServiceListAction,
  getSingleHandymanServiceListAction,
} from "store/actions/handymanServiceAction";
import { setHandymanSlice } from "store/slices/handymanSlice";
import formValidation from "utils/validations";
import { showFormErrors } from "utils/commonFunctions";
import { canAccess } from "services/auth";
import manageColICon from "assets/images/icons/manage-col-icon.png";

const HandymanList = () => {
  const openAction = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const globalFilters = ["name", "address", "service"];
  const [handymanList, setHandymanList] = useState([]);
  const { profile } = useSelector((state) => state.user);
  // const [filters, setFilters] = useState({
  //   name: { value: "" },
  //   address: { value: "" },
  //   service: { value: "" },
  // });
  const [filters, setFilters] = useState({
    name: "",
    address: "",
    service: "",
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { handyman } = useSelector((state) => state?.handyman);
  const [serviceList, setServicesList] = useState([]);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [handymanServiceList, setHandymanServiceList] = useState([]);
  const [blockData, setBlockData] = useState({
    service: "",
    reason: "",
  });
  const [columns, setColumns] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  useEffect(() => {
    if (profile?.company) {
      getServiceList();
    }
  }, [profile]);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex align-items-center justify-content-end flex-wrap align-items-center">
        <div className="w-12 md:w-7 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              setFirst(0);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.name?.value,
                filters?.address?.value,
                filters?.service?.value
              );
            }}
            isSearch={isSearch}
            placeholder="Search Handyman"
          />
        </div>
        {canAccess("add_handyman") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Handyman"
            extraClassNames="mr-3"
            onClick={() => navigate("/real-estate/maintenance/handyman/add")}
          />
        )}
        <CustomOverlay>
          <ul className="no-style p-0">
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i className="pi pi-download cursor-pointer text-dark"></i>
              Import
            </li>
            <li className="flex gap-2 text-xs font-medium mb-3">
              <i className="pi pi-upload cursor-pointer text-dark"></i> Export
            </li>
          </ul>
        </CustomOverlay>
      </div>
    );
  };

  const NameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          // value={options.value}
          value={filters.name}
          name="name"
          onChange={(e) => {
            // options.filterCallback(e.value);
            setFilters((prev) => ({ ...prev, name: e.value }));
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const ServiceTypeFilter = (options, list) => {
    return (
      <div>
        <Dropdown
          value={filters?.service}
          options={list}
          onChange={(e) => {
            // options.filterCallback(e.value);
            setFilters((prev) => ({ ...prev, service: e.value }));
          }}
          optionLabel="name"
          placeholder="Type of Service"
          className="p-column-filter"
        />
        {/* <CustomInput
          col={12}
          value={options.value}
          name="address"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        /> */}
      </div>
    );
  };

  const RatingsBodyTemplate = (options) => {
    return (
      <CustomRatings
        value={options}
        name="rating"
        disabled
        cancel={false}
        ignoreLabel={true}
      />
    );
  };

  const AddressFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={filters?.address}
          name="address"
          onChange={(e) => {
            // options.filterCallback(e.value);
            setFilters((prev) => ({ ...prev, address: e.value }));
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const serviceBodyTemplate = (options) => {
    const tooltipId = `service-tooltip-${options?.id}`;
    return (
      <div>
        <Tooltip
          className="tooltip"
          target={`.${tooltipId}`}
          content={options?.service}
        />
        <span
          className={`${tooltipId} cursor-pointer`}
          data-pr-classname="tooltip"
        >
          <span
            className={{color: selectedItems.some((item) => item?.id == options?.id) ? "text-blue-700" : null}}
            // style={{
            //   color: options?.id === handyman?.id ? "white" : null,
            // }}
          >
            {truncateText(options?.service, 20)} {options?.id}
          </span>
        </span>
      </div>
    );
  };

  const addressBodyTemplate = (options) => {
    const tooltipId = `address-tooltip-${options?.id}`;
    return (
      <div>
        <Tooltip
          className="tooltip"
          target={`.${tooltipId}`}
          content={options?.address}
        />
        <span
          className={tooltipId}
          data-pr-classname="tooltip"
          style={{ cursor: "pointer" }}
        >
          <span
          // style={{
          //   color: options?.id === handyman?.id ? "white" : null,
          // }}
          >
            {truncateText(options?.address, 20)}
          </span>
        </span>
      </div>
    );
  };

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Name",
        accessor: "name",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        disabled: true,
        filterElement: (option) => NameFilter(option),
      },
      // {
      //   name: "Address",
      //   accessor: "address",
      //   value: true,
      //   filter: true,
      //   body: (options) => addressBodyTemplate(options),
      //   filterElement: (option) => AddressFilter(option),
      // },
      {
        name: "Services",
        accessor: "service",
        value: true,
        filter: true,
        body: (options) => serviceBodyTemplate(options),
        filterElement: (options) => ServiceTypeFilter(options, serviceList),
      },
      {
        name: "Ratings",
        accessor: "rating",
        sortable: false,
        value: true,
        body: (options) => RatingsBodyTemplate(options?.rating || 0),
      },
      { name: "Street", accessor: "street", value: true },
      { name: "Zipcode", accessor: "zipcode", value: true },
      { name: "City", accessor: "city", value: false },
      { name: "City Division", accessor: "division", value: false },
      { name: "Country", accessor: "country", value: true },
    ],
    [serviceList, handyman, filters, selectedItems]
  );

  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/maintenance/handyman/view/${d.id}`);
              dispatch(setHandymanSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              getSingleHandymanServiceList(d?.id);
              setIsBlockModal(true);
            }}
          >
            <i className="pi pi-ban text-dark"></i> Block
          </li>
          {canAccess("delete_handyman") && (
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            >
              <i className="pi pi-trash text-dark"></i> Delete
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected handyman from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleHandymanDelete = () => {
    dispatch(
      deleteHandymanAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getHandymanList(profile?.company, rows, page, search);
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
          {/* Action */}
        </p>
      </>
    );
  };

  const getHandymanList = (
    company,
    rows,
    page,
    search,
    name,
    address,
    service
  ) => {
    dispatch(
      getHandymanListAction(
        company,
        rows,
        page,
        search,
        name,
        address,
        service,
        (res) => {
          const formattedData = res?.options.map((option) => ({
            ...option,
            rating: option.rating?.average_rating,
            service: option.service.map((s) => s.name).join(", "),
          }));
          setHandymanList(formattedData);
          setCount(res?.count);
          setIsSearch(false);
          navigate(
            `/real-estate/maintenance/handyman/list?page=${page}&rows=${rows}`
          );
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    name,
    address,
    service
  ) => {
    setIsSearch(true);
    getHandymanList(company, rows, page, search, name, address, service);
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getHandymanList(
        profile?.company,
        rows,
        page,
        search,
        filters?.name,
        filters?.address,
        filters?.service
      );
    }
  }, [
    profile?.company,
    // rows,
    // page,
    // first,
    // filters?.name?.value,
    // filters?.address?.value,
    // filters?.service?.value,
  ]);

  const onPageChange = (e) => {
    // navigate(
    //   `/real-estate/maintenance/handyman/list?page=${e.page + 1}&rows=${e.rows}`
    // );
    getHandymanList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.name,
      filters?.address,
      filters?.service
    );
    setFirst(e.first);
  };

  const handleServiceChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, blockData);
    setBlockData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.name,
          };
        });
        setServicesList(options);
      })
    );
  };

  const getSingleHandymanServiceList = (id) => {
    dispatch(
      getSingleHandymanServiceListAction(id, (res) => {
        const options = res?.map((item) => {
          return {
            name: item?.service?.name,
            value: item?.id,
          };
        });
        setHandymanServiceList(options);
      })
    );
  };

  const handleChangeServiceStatus = () => {
    if (showFormErrors(blockData, setBlockData, ["reason"])) {
      dispatch(
        changeServiceStatusActon(
          blockData.service,
          "Block",
          blockData?.reason,
          setLoading,
          dispatch,
          (res) => {
            setIsBlockModal(false);
            setBlockData({
              service: "",
              reason: "",
            });
          }
        )
      );
    }
  };

  const blockModalContent = () => {
    return (
      <>
        <CustomDropDown
          data={blockData}
          onChange={handleServiceChange}
          name="service"
          col={12}
          options={handymanServiceList}
          // label={"Select Service"}
          required
        />
        <CustomTextArea
          extraClassName="w-full"
          data={blockData}
          onChange={handleServiceChange}
          name="reason"
          label="Write a reason for the block?"
        />
      </>
    );
  };

  const cancelBlockService = () => {
    setBlockData({
      service: "",
      reason: "",
    });
  };

  const applyFilters = (options) => {
    getHandymanList(
      profile?.company,
      rows,
      page,
      "",
      filters?.name,
      filters?.address,
      filters?.service
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getHandymanList(
      profile?.company,
      rows,
      page,
      "",
      field === "name" ? "" : filters?.name,
      field === "address" ? "" : filters?.address,
      field === "service" ? "" : filters?.service
    );
  };

  useEffect(() => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, []);

  return (
    <div>
      <CustomCard title="Handyman List" header={header()}>
        <CustomTableContainer
          list={handymanList?.length ? handymanList : []}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={handyman?.id}
          slice={setHandymanSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Name"
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleHandymanDelete}
        loading={loading}
      />
      <CustomModal
        isOpen={isBlockModal}
        setIsOpen={setIsBlockModal}
        heading={"Block Service"}
        body={blockModalContent}
        submitLabel={"Save"}
        onSubmit={handleChangeServiceStatus}
        onCancel={cancelBlockService}
        loading={loading}
      />
    </div>
  );
};

export default HandymanList;
