import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomMulitSelect,
  CustomPhoneInput,
} from "shared/AllInputs";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import OfficeUnitContainer from "./OfficeUnitContainer";

export default function OfficeUnitForm({ type }) {
  const {
    data,
    handleChange,
    onSubmit,
    loading,
    officeList,
    employeesList,
    navigate
  } = OfficeUnitContainer();

  return (
    <Page
      title={`${type} Office Unit`}
      description={`Let’s ${type} your Unit Details`}
      navigateLink={"/administrator/office-unit/list"}
    >
      <CustomCard>
        <CustomForm title="General">
          <CustomInput
            data={data}
            onChange={handleChange}
            name="unitName"
            required
          />
          {/* <CustomDropDown
            data={data}
            onChange={handleChange}
            name="selectOffice"
            label={"Select Office"}
            options={officeList}
            placeholder="Select Office"
          /> */}
          <CustomMulitSelect
            data={data}
            onChange={handleChange}
            name="selectOffice"
            label={"Select Office"}
            options={officeList}
            placeholder="Select Office"
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="email"
            required
          />
          <CustomPhoneInput
            data={data}
            onChange={handleChange}
            name="phoneNumber"
            required
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="costCenter"
            required
          />
        </CustomForm>
        <br />
        <CustomForm title="Employee">
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="unitHeadOne"
            label={"Unit Head 1"}
            options={employeesList}
            required
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="unitHeadTwo"
            label={"Unit Head 2"}
            options={employeesList}
          />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="unitCoHead"
            label="Unit Co-head"
            options={employeesList}
          />
        </CustomForm>
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label={type === "Edit"? "Save Changes" : "Save"} loading={loading} />
        <CustomButtonOutlined label="Cancel" onClick={() => navigate("/administrator/office-unit/list")}/>
      </Buttonlayout>
    </Page>
  );
}
