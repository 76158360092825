import React, { useRef } from "react";
import { CustomForm } from "../../shared/AllInputs";
import CommonViewTable from "./CommonViewTable";
import { Accordion, AccordionTab } from "primereact/accordion";
import { OverlayPanel } from "primereact/overlaypanel";
import { CustomImageView } from "../../shared/AllViewInputs";
import ProcessIcon from "../../assets/images/svg/processicon.svg";
import GreenTick from "../../assets/images/svg/greenTick.svg";
import { handleFormatDate } from "utils/commonFunctions";

const CustomDamageTable = ({ allDamages, header, onEdit, onProcess }) => {
  const headerOp = useRef(null);
  const accordianHeader = (damage, index) => {
    return (
      <div className="flex align-items-center justify-content-between w-full ">
        <p className="title">Damage {index + 1}</p>
        <p className="title">{handleFormatDate(damage?.processedDate)}</p>
        {damage?.is_requested && <img src={GreenTick} />}
        {damage?.id && (
          <>
            <i
              className="pi pi-ellipsis-v cursor-pointer text-dark"
              onClick={(e) => headerOp.current.toggle(e)}
            ></i>
            <OverlayPanel ref={headerOp} className="px-3">
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => onEdit(damage?.id, index)}
                >
                  <i className="pi pi-file-edit text-dark"></i> Edit
                </li>

                {!damage?.is_requested && (
                  <li
                    className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                    onClick={() => onProcess(damage?.id, index)}
                  >
                    <img src={ProcessIcon} /> Process
                  </li>
                )}
              </ul>
            </OverlayPanel>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <CustomForm title=" " header={header}>
        {allDamages?.length ? (
          allDamages?.map((damage, index) => {
            return (
              <Accordion activeIndex={damage.id} className="w-full mt-3">
                <AccordionTab
                  headerClassName="accordion-role-list"
                  headerTemplate={() => accordianHeader(damage, index)}
                >
                  <div className="p-4 w-full bg-primary-light border-round-xl grid mt-3">
                    <div className="col-6">
                      <h4 className="mb-2 capitalize">
                        {damage?.service?.name}
                      </h4>
                      <p className="mb-2">Damage Cause</p>
                      <span className="font-bold">{damage?.damageCause}</span>
                    </div>
                    <div className="col-6">
                      <p className="mb-2">Damage Type</p>
                      <span className="font-bold">
                        {damage?.damageType?.name || damage?.damageTypeName}
                      </span>
                    </div>
                    <p className="col-12">
                      <span className="title">Damage Description </span>{" "}
                      <p className="mt-2">{damage?.damageDescription}</p>
                    </p>
                    <p className="col-12">
                      <span className="title">Damage Photos </span>{" "}
                      <div className="flex mt-3 gap-4 flex-wrap">
                        {damage?.document?.map((image) => {
                          if (image.document) {
                            return <CustomImageView src={image?.document} />;
                          } else {
                            return <CustomImageView image={`${image}`} />;
                          }
                        })}
                      </div>
                    </p>
                  </div>
                </AccordionTab>
              </Accordion>
            );
          })
        ) : (
          <CommonViewTable title={""} list={[]} />
        )}
      </CustomForm>
      {/* <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        // onSubmit={() => onDelete(idToDelete?.id, idToDelete?.index)}
        loading={loading}
      /> */}
    </>
  );
};

export default CustomDamageTable;
