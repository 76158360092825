import { setAnnualStatementSlice } from "store/slices/annualStatementSlice";
import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { handleFileConversion, handleFormatDate } from "../../utils/commonFunctions";
import { showToastAction } from "../slices/commonSlice";

const AddAnnualStatementAction = (company, propertyId, allDocuments, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const documents = await Promise.all(allDocuments.map(async (document) => {
        const documentDetails = {
            document_type: document?.document_type,
            print_date: handleFormatDate(document?.print_date),
            year_of_statement: typeof document?.year_of_statement === "object" ? handleFormatDate(document?.year_of_statement, "year") : document?.year_of_statement,
            date_from: handleFormatDate(document?.date_from),
            date_to: handleFormatDate(document?.date_to),
            document_file: await handleFileConversion(document.document)
        };

        if (document?.maintenance_costs) {
            documentDetails.maintenance_costs = document.maintenance_costs;
        }

        if (document?.heating_cost) {
            documentDetails.heating_cost = document.heating_cost;
        }

        return documentDetails;
    }));

    let payload = {
        property: propertyId,
        documents: documents,
    }


    const res = await api(
        "post",
        endPoints?.ANNUAL_STATEMENT + `/${company}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const UpdateAnnualStatementAction = (company, propertyId, statementId, allDocuments, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const documents = await Promise.all(allDocuments.map(async (document) => {
        const documentDetails = {
            document_type: document?.document_type,
            print_date: handleFormatDate(document?.print_date),
            year_of_statement: typeof document?.year_of_statement === "object" ? handleFormatDate(document?.year_of_statement, "year") : document?.year_of_statement,
            date_from: handleFormatDate(document?.date_from),
            date_to: handleFormatDate(document?.date_to),
        };


        if (document?.id) {
            documentDetails.id = document?.id
        }

        if (document?.document_file) {
            documentDetails.document_file = document?.document_file;
        } else {
            documentDetails.document_file = await handleFileConversion(document.document)
        }

        if (document?.maintenance_costs) {
            documentDetails.maintenance_costs = document.maintenance_costs;
        }

        if (document?.heating_cost) {
            documentDetails.heating_cost = document.heating_cost;
        }

        return documentDetails;
    }));

    let payload = {
        property: propertyId,
        documents: documents,
    }


    const res = await api(
        "put",
        endPoints?.ANNUAL_STATEMENT + `/${company}/${statementId}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const getAnnualStatementListAction = (company, rows, page, search, year, propertyId, onRes) => async () => {
    let statementYear = handleFormatDate(year, "year")
    const res = await api(
        "get",
        endPoints?.ANNUAL_STATEMENT + `/${company}/?limit=${rows}&page=${page}&search=${search}&year_of_statement=${statementYear || ""}&property_id=${propertyId || ""}`,
    );

    if (res?.success) {
        const info = res?.data?.results?.map((item) => {
            return {
                property_id: item?.property?.property_id,
                ...item
            }
        })
        onRes({ options: info, count: res?.data?.count })
    }
};

const getAnnualStatementListByPropertyAction = (company, property, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.ANNUAL_STATEMENT + `/${company}/?property=${property || ""}`,
    );

    if (res?.success) {
        const info = res?.data?.results?.map((item) => {
            return {
                property_id: item?.property?.property_id,
                ...item
            }
        })
        onRes({ options: info, count: res?.data?.count })
    }
};

const getAnnualStatenentAction = (company, id, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.ANNUAL_STATEMENT + `/${company}/${id}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(setAnnualStatementSlice(info))
        onRes(info)
    }
};

const getMaintenanceCostDetailsAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.MAINTENANCE_COSTS + `/${id}/`,
    );
    if(res.success){
        const info = res?.data?.results;
        onRes(info)
    }
}

const deleteMaintenanceCostAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.MAINTENANCE_COSTS + `/${id}/`,
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    }
    setLoading(false)

}

export {
    AddAnnualStatementAction,
    getAnnualStatenentAction,
    getAnnualStatementListAction,
    UpdateAnnualStatementAction,
    getAnnualStatementListByPropertyAction,
    getMaintenanceCostDetailsAction,
    deleteMaintenanceCostAction
};