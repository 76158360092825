import { OverlayPanel } from "primereact/overlaypanel";
import React, { useRef } from "react";

export default function CustomOverlay({ children, onClick, extraClass}) {
  const ref = useRef(null);
  return (
    <>
      <i
        className={`pi pi-ellipsis-v block cursor-pointer text-dark ${extraClass} text-xl`}
        onClick={(e) => ref.current.toggle(e)}
      ></i>
      <OverlayPanel ref={ref}>{children}</OverlayPanel>
    </>
  );
}
