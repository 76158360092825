import React, { useEffect, useRef, useState } from "react";
import formValidation from "utils/validations";
import { showFormErrors } from "utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getRolesListAction } from "store/actions/rolesActions";
import { addEmployeeAction, getEmployeeInfoAction, searchEmployeeIdAction, updateEmployeeAction } from "store/actions/EmployeeActions";
import { debounce } from "lodash";
import { checkOfficeErrors } from "shared/Components/commonFunctions";

const EmployeeContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams()
    const { profile } = useSelector((state) => state.user);
    const [data, setData] = useState({
        employeeId: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        selectRole: "",
    });
    const [loading, setLoading] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [allOffices, setAllOffices] = useState([
        { id: null, office: null, position: null, status: null },
    ]);
    const [employeeIdOptions, setEmployeeIdOptions] = useState([])

    useEffect(() => {
        if (profile?.company) {
            handleGetRolesList(profile?.company);
        }
        if (params?.id && profile?.company) {
            getEmployeeInfo(profile?.company, params?.id)
        }
    }, [profile, params?.id]);

    const handleGetRolesList = (company) => {
        dispatch(
            getRolesListAction(company, dispatch, (res) => {
                let options = res?.data?.results.map((role) => {
                    return { name: role?.name, value: role?.id };
                });
                setRolesList(options);
            })
        );
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const offices = checkOfficeErrors(allOffices, setAllOffices)
        if (showFormErrors(data, setData) && !offices?.hasErrors) {
            if (params?.id) {
                dispatch(updateEmployeeAction(profile?.company, params?.id, data, offices?.offices, setLoading, navigate, dispatch));
            } else {
                dispatch(addEmployeeAction(profile?.company, data, offices?.offices, setLoading, navigate, dispatch));
            }
        }
    };

    const getEmployeeInfo = (company, id) => {
        dispatch(
            getEmployeeInfoAction(company, id, dispatch, (res) => {
                setData({
                    id: res?.id,
                    employeeId: res?.employee_id,
                    firstName: res?.user?.first_name,
                    lastName: res?.user?.last_name,
                    email: res?.user?.email,
                    phoneNumber: res?.user?.phone_number,
                    selectRole: res?.role?.id,
                })
                let offices = res?.offices?.map((ofc) => {
                    return { id: ofc?.id, office: ofc?.office?.id, position: ofc?.position, status: "save" }
                })
                setAllOffices(offices)
            })
        );
    };

    // const handleEmployeeIdChange = ({ name, value }) => {
    //     const formErrors = formValidation(name, value, data);
    //     setData((prev) => ({ ...prev, [name]: value, formErrors }));
    //     if (value) {
    //         debouncedOnChange.current(value);
    //     }
    // };

    const handleSearch = (value) => {
        let payload = {
            employee_id: value,
        };

        dispatch(
            searchEmployeeIdAction(payload, setEmployeeIdOptions, (error) => {
                // if (!res?.success) {
                    const formErrors = ({ ["employeeId"]: error })
                    setData((prev) => ({ ...prev, ["employeeId"]: value, formErrors }));
                    
                // }
            })
        );
    };

    const debouncedOnChange = useRef(debounce(handleSearch, 1000));

    return {
        data,
        handleChange,
        rolesList,
        allOffices,
        setAllOffices,
        onSubmit,
        loading,
        employeeIdOptions,
        // handleEmployeeIdChange,
        debouncedOnChange,
        navigate
    }
}

export default EmployeeContainer