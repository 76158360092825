import React, { useEffect, useState } from "react";
import {
  CustomAmountInput,
  CustomCalander,
  CustomFileInput,
  CustomForm,
  CustomYearInput,
} from "shared/AllInputs";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import formValidation from "utils/validations";
import {
  convertAmountInGerman,
  getFileExtension,
  getFileName,
  showFormErrors,
} from "utils/commonFunctions";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";

import AddMaintenanceCosts from "shared/Components/AddMaintenanceCosts";
import { checkMaintenanceCostsErrors } from "shared/Components/commonFunctions";
import { truncateText } from "utils/regex";

const AddDocumentForm = ({
  documentType,
  setDocumentType,
  allDocuments,
  setAllDocuments,
  selectedDocument,
}) => {
  const [image, setImage] = useState("");
  const dataInitialState = {
    index: "",
    id: "",
    printDate: "",
    year: "",
    dateFrom: "",
    dateTo: "",
    documentType: documentType.type,
    document: "",
    fixedCost: "",
    variableCost: "",
    heatingCostId: "",
    // damage_report: reportId || "",
  };
  const [data, setData] = useState(dataInitialState);
  const [allMaintenanceCosts, setAllMaintenanceCosts] = useState([]);
  const [documentYear, setDocumentYear] = useState("");

  useEffect(() => {
    if (
      documentType.type === "Maintenance" ||
      documentType.type === "Combined"
    ) {
      setAllMaintenanceCosts([
        { id: "", cost_type: "", amount: "", status: "" },
      ]);
    }
  }, []);

  useEffect(() => {
    if (selectedDocument?.index >= 0) {
      let document = allDocuments.find((item, index) => {
        return index === selectedDocument?.index;
      });

      if (document) {
        setData({
          index: selectedDocument?.index,
          id: selectedDocument?.id || "",
          printDate: new Date(document?.print_date),
          year:
            typeof document?.year_of_statement == "object"
              ? document?.year_of_statement
              : new Date(document?.year_of_statement, 0, 1),
          dateFrom: new Date(document?.date_from),
          dateTo: new Date(document?.date_to),
          documentType: document?.document_type,
          document: document?.document_file || document?.document,
          fixedCost: document?.heating_cost?.fixed_cost || "",
          variableCost: document?.heating_cost?.variable_cost || "",
          heatingCostId: document?.heating_cost?.id || "",
        });
        setImage(document?.document_file || document?.document);
        setAllMaintenanceCosts(document?.maintenance_costs);
      }
    }
  }, [selectedDocument?.index]);

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data, [
      "index",
      "id",
      "heatingCostId",
    ]);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setImage(file);
    const formErrors = formValidation("document", file, data);
    setData((prev) => ({ ...prev, ["document"]: file, formErrors }));
  };

  const onSubmit = () => {
    let ignoreInputs = ["index", "id", "heatingCostId"];
    if (data?.documentType === "Maintenance") {
      ignoreInputs = [...ignoreInputs, "fixedCost", "variableCost"];
    }

    const costs = checkMaintenanceCostsErrors(
      allMaintenanceCosts,
      setAllMaintenanceCosts
    );

    if (showFormErrors(data, setData, ignoreInputs) && !costs?.hasErrors) {
      const documents = [...allDocuments];
      // const indexToUpdate = allDocuments.findIndex(
      //   (item) => selectedDocument?.id && item.id === selectedDocument?.id
      // );

      const newDocument = {
        id: selectedDocument?.id,
        print_date: data.printDate,
        year_of_statement: data.year,
        date_from: data.dateFrom,
        date_to: data?.dateTo,
        document_type: data.documentType,
        document: data?.document,
      };

      if (data.documentType === "Heating" || data.documentType === "Combined") {
        newDocument.heating_cost = {
          fixed_cost: data?.fixedCost,
          variable_cost: data?.variableCost,
        };
      }

      if (data?.heatingCostId) {
        newDocument.heating_cost.id = data?.heatingCostId;
      }

      if (
        data.documentType === "Maintenance" ||
        data.documentType === "Combined"
      ) {
        newDocument.maintenance_costs = costs?.costs;
      }

      if (selectedDocument?.index === 0 || selectedDocument?.index > 0) {
        documents[selectedDocument?.index] = { ...newDocument };
      } else {
        documents.push(newDocument);
      }

      setAllDocuments(documents);
      setDocumentType({ documentType: "", isAdd: false, action: "" });
      setData(dataInitialState);
      setImage("");
    }
  };

  const onDamageCancel = () => {
    setDocumentType({ documentType: "", isAdd: false, action: "" });
    setImage([]);
    setData(dataInitialState);
  };

  const heatingCostHeader = () => {
    const totalAmount = parseFloat(
      Number(data?.fixedCost) + Number(data?.variableCost)
    );
    return (
      <div className="w-12 flex justify-content-end flex-wrap total-amount">
        <span>Total: {convertAmountInGerman(totalAmount)}</span>
      </div>
    );
  };

  useEffect(() => {
    if (allDocuments.length) {
      let year = allDocuments[0]?.year_of_statement;
      year = typeof year == "object"? year :  new Date(year, 0, 1) 
      setDocumentYear(year);
      setData((prev) => ({...prev, ["year"]: year}))
    }
  }, [allDocuments]);

  const handleBack = () => {
    setDocumentType({ documentType: "", isAdd: false, action: "" });
    setData(dataInitialState);
    setImage("");
  };

  return (
    <Page
      title={`${documentType?.action} Document`}
      description={`Let’s ${documentType?.action} your Document`}
      onClick={handleBack}
    >
      <CustomCard>
        <CustomForm title={"Document"}>
          <CustomCalander
            data={data}
            onChange={handleChange}
            name="printDate"
            required
          />
          <CustomYearInput
            data={data}
            onChange={handleChange}
            name="year"
            label={"Year of Statement"}
            placeholder={"Year of Statement"}
            view="year"
            disabled={documentYear && (selectedDocument?.index !== 0)}
            required
          />
          <CustomCalander
            data={data}
            onChange={handleChange}
            name="dateFrom"
            required
          />
          <CustomCalander
            data={data}
            minDate={new Date(data?.dateFrom)}
            onChange={handleChange}
            name="dateTo"
            required
          />
        </CustomForm>
        <CustomForm>
          <CustomFileInput
            data={data}
            selectedFiles={data?.document}
            // inputKey={fileKey}
            type="file"
            name="document"
            label="Upload Document"
            onChange={handleFileChange}
            required
            accept=".jpg,.jpeg,.png"
          />
          {image && (
            <div className="input-layout col-12  xl:col-6 ">
              <h5>Selected file:</h5>
              {/* <li className="upload-list"> */}
              {image?.name
                ? truncateText(image.name, 20)
                : truncateText(
                    getFileName(image) + "." + getFileExtension(image),
                    15
                  )}{" "}
              {/* </li> */}
            </div>
          )}
        </CustomForm>
        {(data.documentType === "Heating" ||
          data.documentType === "Combined") && (
          <CustomForm title={"Heating Cost"} header={heatingCostHeader()}>
            <CustomAmountInput
              data={data}
              name="fixedCost"
              onChange={handleChange}
            />
            <CustomAmountInput
              data={data}
              name="variableCost"
              onChange={handleChange}
            />
          </CustomForm>
        )}
        {(data.documentType === "Maintenance" ||
          data.documentType === "Combined") && (
          <AddMaintenanceCosts
            title={"Maintenance Costs"}
            allValues={allMaintenanceCosts}
            setAllValues={setAllMaintenanceCosts}
          />
        )}
      </CustomCard>
      <Buttonlayout>
        <CustomButton
          onClick={onSubmit}
          label={documentType?.action === "Add" ? "Save" : "Save Changes"}
        />
        <CustomButtonOutlined label="Cancel" onClick={onDamageCancel} />
      </Buttonlayout>
    </Page>
  );
};

export default AddDocumentForm;
