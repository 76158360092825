import React, { useState } from "react";
import Page from "../../../shared/Page";
import CustomCard from "../../../shared/CustomCard";
import {
  CustomCalander,
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "../../../shared/AllInputs";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../shared/CustomButton";
import formValidation from "../../../utils/validations";
import { showFormErrors } from "../../../utils/commonFunctions";

export default function AddTenant() {
  const [data, setData] = useState({
    tenantId: "",
    firstName: "",
    lastName: "",
    tenantType: "",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    addressBeforeMovingIn: "",
  });

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData)) {
    }
  };
  return (
    <Page title="Add Tenant" description="Let’s add your Tenant">
      <CustomCard>
        <CustomForm title="General">
          <CustomInput data={data} onChange={handleChange} name="tenantId" />
          <CustomInput data={data} onChange={handleChange} name="firstName" />
          <CustomInput data={data} onChange={handleChange} name="lastName" />
          <CustomDropDown
            data={data}
            onChange={handleChange}
            name="tenantType"
            options={[]}
          />
          <CustomCalander
            data={data}
            onChange={handleChange}
            name="dateOfBirth"
          />
          <CustomInput data={data} onChange={handleChange} name="email" />
          <CustomPhoneInput
            data={data}
            onChange={handleChange}
            name="phoneNumber"
          />
          <CustomInput
            data={data}
            onChange={handleChange}
            name="addressBeforeMovingIn"
          />
        </CustomForm>
      </CustomCard>
      <Buttonlayout>
        <CustomButton onClick={onSubmit} label="Save & Next" />
        <CustomButtonOutlined label="Cancel" />
        <CustomButtonOutlined label="Skip" />
      </Buttonlayout>
    </Page>
  );
}
