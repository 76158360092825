import {
  FirstletterUpperCase,
  equal,
  greaterThan,
  length,
  notEqual,
} from "./javascript";
import {
  stringValidation,
  emailValidation,
  regularString,
  number,
  passwordValidation,
  formatFieldName,
  // stringAndSpace,
  // stringNumberSpace,
  // stringSpace,
  officeNameRegex,
  addressRegex,
  zipCodeValidation,
  maxNumbers,
  ibanCheck,
} from "./regex";
import { isValidPhoneNumber } from "react-phone-number-input";

const formValidation = (name, value, state, ignore = [], optional = [], errorName) => {

  const formErrors = { ...state.formErrors };
  if (ignore.includes(name)) {
    if (formErrors[name]) formErrors[name] = "";
    return formErrors;
  }

  switch (name) {
    case "email":
    case "businessEmail":
    case "officeEmail":
      if (equal(length(value)) && !optional.includes(name)) {
        formErrors[name] = `${formatFieldName(name)} is required!`;
      } else if (value && !emailValidation(value)) {
        formErrors[name] = `Please enter valid email!`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "firstName":
    case "lastName":
      if (equal(length(value))) {
        formErrors[name] = `${formatFieldName(name)} is required!`;
      } else if (!regularString(value)) {
        formErrors[name] = `Unnecessary space or special chracter in word!`;
      } else if (greaterThan(length(value), 35)) {
        formErrors[name] = `${formatFieldName(
          name
        )} exceeds character limit. Maximum allowed: 35 characters.`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "officeName":
    case "serviceName":
    case "officeLetterheadName":
      if (equal(length(value))) {
        formErrors[name] = `${formatFieldName(name)} is required!`;
      } else if (!officeNameRegex(value)) {
        formErrors[name] = `Unnecessary space or special chracter in word!`;
      } else if (greaterThan(length(value), 35)) {
        formErrors[name] = `${formatFieldName(
          name
        )} exceeds character limit. Maximum allowed: 35 characters.`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "damageDescription":
    case "comment":
      if (equal(length(value))) {
        formErrors[name] = `${formatFieldName(name)} is required!`;
      } else if (greaterThan(length(value), 500)) {
        formErrors[name] = `${formatFieldName(name)} exceeds character limit. Maximum allowed: 500 characters.`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "address":
    case "houseNo":
      if (equal(length(value))) {
        formErrors[name] = `${formatFieldName(name)} is required!`;
      } else if (!addressRegex(value)) {
        formErrors[name] = `Please enter a valid address!`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "fullName":
    case "userName":
    case "name":
    // case "officeName":
    // case "address":
    case "street":
    case "city":
    case "cityDivision":
    case "country":
    case "costCenter":
    case "roleName":
    case "unitName":
    case "employeeId":
    case "officeId":
    case "contractId":
    case "landlordId":
    case "propertyId":
    case "officeNickName":
    case "officeNickName":
    case "existingLandlord":
    case "existingContract":
    case "landlordName":
    case "estateCompany":
    case "meter_id":
    case "purpose":
    case "owner_name":
    case "response":
    // case "iban":
    case "bic":
      if (equal(length(value)) && !optional.includes(name)) {
        formErrors[name] = `${errorName || formatFieldName(name)} is required!`;
      } else if (value && !regularString(value)) {
        formErrors[name] = `Unnecessary space or special chracter in word!`;
      } else if (greaterThan(length(value), 70)) {
        formErrors[name] = `${errorName || formatFieldName(
          name
        )} exceeds character limit. Maximum allowed: 70 characters.`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "tenantType":
    case "status":
    case "category":
    case "subCategory":
    case "selectRole":
    // case "size":
    case "unitSize":
    case "name_id":
    case "bankAccount":
    case "landlordContact":
    case "damageCause":
    case "damageType":
    case "cost_type":
      if (equal(length(value))) {
        formErrors[name] = `${formatFieldName(name)} is required!`;
      } else if (!stringValidation(value)) {
        formErrors[name] = `Unnecessary space or special chracter in word!`;
      } else {
        formErrors[name] = "";
      }
      break;

    // case "unit":
    //   if (equal(length(value))) {
    //     formErrors[name] = `${formatFieldName(name)} is required!`;
    //   } else {
    //     formErrors[name] = "";
    //   }
    //   break;

    case "loginPassword":
    case "staircaseAccess":
    case "buildingType":
    case "tenantId":
    case "dateOfBirth":
    case "addressBeforeMovingIn":
    case "unitType":
    case "location":
    case "waterHeating":
    case "heatingTechnology":
    case "landlordType":
    case "maturityType":
    case "unitHeadOne":
    case "whoHandle":
      if (equal(length(value))) {
        formErrors[name] = `${formatFieldName(errorName || name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "password":
      if (equal(length(value))) {
        formErrors[name] = `${FirstletterUpperCase(name)} is required!`;
      } else if (!passwordValidation(value)) {
        formErrors[
          name
        ] = `Please enter a password with 8-16 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "iban":
      if (equal(length(value))) {
        formErrors[name] = `${FirstletterUpperCase(name)} is required!`;
      } else if (!ibanCheck(value)) {
        formErrors[name] = "Please enter a valid Iban!";
      } else {
        formErrors[name] = "";
      }
      break;

    case "landlord":
    case "property":
      if (equal(length(value))) {
        formErrors[name] = `${FirstletterUpperCase(name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "confirmPassword":
      if (equal(length(value))) {
        formErrors[name] = `${formatFieldName(name)} is required!`;
      } else if (notEqual(state.password, value)) {
        formErrors[name] = `Password and Confirm Password is not match!`;
      } else if (equal(state.password, value)) {
        formErrors[name] = "";
      }
      break;

    case "buildingMaxFloor":
    case "qty":
    case "floor":
    case "maturityExtension":
    case "noticePeriod":
    case "extension":
    case "notice_period":
    case "amount":
    case "amountFirstMonth":
    case "riseInterval":
    case "riseAmount":
    case "doors":
    case "cost":
    case "fixedCost":
    case "variableCost":
    case "amount_first_month":
    case "amount_last_month":
    case "contractualSize":
    case "contractualNo":

      if (!value) {
        if (name === "noOflabels") {
          formErrors[name] = `No of labels is required!`;
        } else {
          formErrors[name] = `${formatFieldName(name)} is required!`;
        }
      } else if (!number(value)) {
        if (name === "noOflabels") {
          formErrors[name] = `No of labels should be number!`;
        } else {
          formErrors[name] = `${formatFieldName(
            name
          )} should be number!`;
        }
      } else if (!maxNumbers(value)) {
        formErrors[name] = `Please enter valid ${formatFieldName(name)}!.`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "size":
      if (equal(length(value))) {
        formErrors[name] = `${formatFieldName(name)} is required!`;
      } else if (!maxNumbers(value) || value == 0) {
        formErrors[name] = `Please enter valid ${formatFieldName(name)}!.`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "otpCode":
      {
        if (!value) {
          formErrors[name] = `OTP is required!`;
        } else if (value.length < 6) {
          formErrors[name] = `Valid OTP is required!`;
        } else {
          formErrors[name] = "";
        }
      }
      break;

    case "phoneNumber":
    case "mobile":
    case "office_phone":
    case "work_phone":
    case "home_phone":
      {
        if (equal(length(value)) && !optional.includes(name)) {
          formErrors[name] = `${formatFieldName(name)} is required!`;
        } else if (value == undefined && !optional.includes(name)) {
          formErrors[name] = `${formatFieldName(name)} is required!`;
        }else if (value && !isValidPhoneNumber(value)) {
          formErrors[name] = `Please enter valid ${formatFieldName(name)}`;
        } else {
          formErrors[name] = "";
        }
      }
      break;

    case "zipCode":
      {
        if (equal(length(value))) {
          formErrors[name] = `${formatFieldName(name)} is required!`;
        } else if (value) {
          if (!number(value)) {
            formErrors[name] = `${formatFieldName(name)} should be number!`;
          } else if (!zipCodeValidation(value)) {
            formErrors[name] = `Please enter valid ${formatFieldName(name)}!`;
          } else {
            formErrors[name] = "";
          }
        }
      }
      break;

    case "document":
    case "movingDate":
    case "depositType":
    case "maturityDate":
    case "rentRiseType":
    case "intervalType":
    case "riseInterval":
    case "service":
    case "startDate":
    case "printDate":
    case "dateTo":
    case "dateFrom":
    case "endDate":
    case "year":
    case "expectedDate":
    case "startTime":
    case "endTime":
      {
        if (equal(length(value)) || !value) {
          formErrors[name] = `${formatFieldName(name)} is required!`;
        }
        else {
          formErrors[name] = "";
        }
      }
      break;

    case "furnitureProvided":
    case "directDebit":
    case "building":
    case "purpose":
    case "searchEmployee":
    case "type":
    case "payment_type":
    case "applicable_tenant_rent":
    case "access":
    case "item":
    case "role":
    case "position":
    case "employee":
    case "office":
      if (!value) {
        formErrors[name] = `${formatFieldName(name)} are required!`;
      } else {
        formErrors[name] = "";
      }
      break;

    // case "contractualSize":
    //   {
    //     if (!value) {
    //       formErrors[name] = `${formatFieldName(name)} is required!`;
    //     } else if (!number(value)) {
    //       formErrors[name] = `Please enter a valid ${formatFieldName(name)}!`;
    //     } else {
    //       formErrors[name] = "";
    //     }
    //   }
    //   break;

    // case "contractualNo":
    // case "building":
    //   if (!value) {
    //     formErrors[name] = `${formatFieldName(name)} are required!`;
    //   } else {
    //     formErrors[name] = "";
    //   }
    //   break;

    default:
      break;
  }


  return formErrors;
};

export default formValidation;
