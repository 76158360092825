import React, { useEffect, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomForm } from "shared/AllInputs";
import { CustomViewInput } from "shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmployeeOfficeAction, getEmployeeInfoAction, updateEmployeeOfficeAction } from "store/actions/EmployeeActions";
import EditIcon from "assets/images/icons/editicon.png";
import EmployeeOfficeTable from "views/Tables/EmployeeOfficeTable";
import Page from "shared/Page";
import { canAccess} from "services/auth";

export default function ViewEmployee() {
  const [data, setData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [allOffices, setAllOffices] = useState([])

  useEffect(() => {
    if (profile?.company) {
      getEmployeeInfo(profile?.company);
    }
  }, [profile]);

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/administrator/employees/edit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  const getEmployeeInfo = (company) => {
    dispatch(
      getEmployeeInfoAction(company, params?.id, dispatch, (res) => {
        let info = {
          employeeId: res?.employee_id,
          firstName: res?.user?.first_name,
          lastName: res?.user?.last_name,
          email: res?.user?.email,
          phoneNumber: res?.user?.phone_number,
          role: res?.role?.name,
          // offices: res?.offices,
        };
        let options = res?.offices?.map((office) => {
          return {
            id: office?.id,
            position: office?.position,
            office: {name: office?.office?.name, value: office?.office?.id}
          }
        })
        setAllOffices(options)
        setData(info);
      })
    );
  };

  const handleOfficeDelete = (id, loading, onRes) => {
    dispatch(
      deleteEmployeeOfficeAction(id, loading, dispatch, (res) => {
        onRes(res);
      })
    );
  };

  const handleOfficeUpdate = (id, data, onRes) => {
    let payload = {
      ...data,
      employee: params?.id,
    }
    dispatch(
      updateEmployeeOfficeAction(id, payload, dispatch, (res) => {
        onRes(res);
      })
    );
  };

  return (
    <Page
      title="View Employee"
      description={`Let’s view your Employee Details`}
      navigateLink={"/administrator/employees/list"}
    >
      <CustomCard>
        <CustomForm title="General" header={canAccess("change_employee") && header()}>
          <CustomViewInput
            name="employeeId"
            data={data}
            label={"Employee ID"}
          />
          <CustomViewInput name="firstName" data={data} />
          <CustomViewInput name="lastName" data={data} />
          <CustomViewInput name="email" data={data} />
          <CustomViewInput name="phoneNumber" data={data} />
        </CustomForm>
        <CustomForm title="Role">
          <CustomViewInput name="role" data={data} />
        </CustomForm>
        <EmployeeOfficeTable title="Office" allValues={allOffices} setAllValues={setAllOffices} onUpdate={handleOfficeUpdate} onDelete={handleOfficeDelete}/>
      </CustomCard>
    </Page>
  );
}
