import React from "react";
import { useNavigate } from "react-router-dom";

export default function Page({ title, navigateLink, description, children, onClick }) {
  const navigate = useNavigate();
  return (
    <div className="page ">
      <div className="flex ">
        <div className="back-icon my-auto" onClick={() => {onClick? onClick() : (navigateLink? navigate(navigateLink) : navigate(-1))}}>
          <i className="pi pi-arrow-left text-xl " />
        </div>
        <div className="page-title ">{title}</div>
      </div>
      {description && <div className="page-description ">{description}</div>}
      {children && <div className="mt-4">{children}</div>}
    </div>
  );
}
