import React from "react";
import { CustomDropDown, CustomForm } from "../../shared/AllInputs";
import AddFurniture from "../../shared/Components/AddFurniture";
import { YesNoOptions } from "../../shared/AllDropDownOptions";

const FurnitureForm = ({title, data, handleChange, allFurniture, setAllFurniture}) => {
  return (
    <>
      <CustomForm title={title}>
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="furnitureProvided"
          options={YesNoOptions}
          label="Is Furniture provide by the Landlord?"
        />
      </CustomForm>
      {data?.furnitureProvided === "yes" && (
        <AddFurniture
          title=""
          allValues={allFurniture}
          setAllValues={setAllFurniture}
          // errors={furnitureErrors}
          // setErrors={setFurnitureErrors}
        />
      )}
    </>
  );
};

export default FurnitureForm;
