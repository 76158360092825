import { checkIsValueGreater } from "../../utils/commonFunctions";
import formValidation from "../../utils/validations";

export const handleFeildName = (key) => {
    return key
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_/g, ' ')
        .replace(/^\w/, (c) => c.toUpperCase())
        .replace(/\b\w/g, char => char.toUpperCase())
}

export const HandleAdd = (emptyObj, allValuesArray, setAllValues, errorObj, allErrorsArray, setAllErrors) => {
    const values = [...allValuesArray];
    const errors = [...allErrorsArray];

    values.push(emptyObj);
    setAllValues(values);

    errors.push(errorObj);
    setAllErrors(errors);
}

export const HandleReplaceRentTenantObj = (data, index, allValues, setAllValues, errorObj, errors, setErrors) => {
    let values = [...allValues];
    let allErrors = [...errors];

    if (index != -1) {
        // values[index] = {
        //     ...data,
        //     applicable_tenant_rent: data.applicable_tenant_rent ? "yes" : "no"
        // };
        values[index] = data
        values[index].status = "save";
        allErrors[index] = errorObj
    }
    setAllValues(values);
    setErrors(errors);
}

export const handleSave = (allValuesArray, setAllValues, allErrorsArray, setAllErrors, ignoreInputs = [], index, optional = []) => {
    const values = [...allValuesArray];
    const allErrors = [...allErrorsArray];
    const fieldKeys = Object.keys(values[index]);
    let hasErrors = false;
    fieldKeys.forEach((key) => {
        const value = values[index][key];
        if (!value && value !== false && !ignoreInputs.includes(key) && !optional.includes(key)) {
            let fieldName = handleFeildName(key)
            allErrors[index][key] = `${fieldName} is required.`;
            hasErrors = true;
        } else if (allErrors[index][key]) {
            allErrors[index][key] = allErrors[index][key]
            hasErrors = true
        }
    });
    if (!hasErrors) {
        values[index].status = "save";
        setAllValues(values);
        allErrors[index] = {};
        setAllErrors(allErrors);
    } else {
        setAllErrors(allErrors);
    }

    return hasErrors
};

export const handleCancel = (data, index, allValues, setAllValues, errorObj, errors, setErrors) => {
    let values = [...allValues]
    let allErrors = [...errors]
    if (index !== -1) {
        values[index] = data
        values[index].status = "save";
        allErrors[index] = errorObj
    }
    setAllValues(values)
    setErrors(allErrors)
}

export const handleChangeStatus = (emptyObj, allValuesArray, setAllValues, action, index) => {
    const values = [...allValuesArray];
    if (index > -1) {
        if (action === "clear") {
            values[index] = emptyObj;
        } else {
            values[index].status = action;
        }
    }
    setAllValues(values);
};

export const handleDelete = (allValuesArray, setAllValues, allErrors, setAllErrors, index) => {
    let values = [...allValuesArray]
    let errors = [...allErrors]
    if (values.length > 0) {
        let filtered = values.filter((item, itemIndex) => itemIndex !== index)
        let filteredErrors = errors.filter((error, errorIndex) => errorIndex !== index)
        setAllValues(filtered)
        setAllErrors && setAllErrors(filteredErrors)
    }
}

export const handleObjChange = (allValuesArray, setAllValues, allErrorsArray, setAllErrors, ignoreInputs = [], name, value, index, optional = []) => {
    const values = [...allValuesArray];
    const allErrors = [...allErrorsArray];
    if (index > -1) {
        if (values.length) {
            values[index][name] = value;
        }
        if (value && !ignoreInputs.includes(name)) {
            const formErrors = formValidation(name, value, allValuesArray, ignoreInputs, optional);
            let error = formErrors[name]
            if (error) {
                allErrors[index][name] = error;
            } else {
                allErrors[index][name] = null;
            }
        } else if (!value && optional.includes(name)) {
            allErrors[index][name] = null;
        } else if (!ignoreInputs.includes(name) && !optional.includes(name)) {
            let fieldName = handleFeildName(name)
            allErrors[index][name] = `${fieldName} is required.`;
        }
    }
    setAllValues(values);
    setAllErrors(allErrors);
};

export const handleRoomSaveNew = (allRooms, setAllRooms, maxSize) => {
    // Create a copy of the rooms array to work with
    const updatedRooms = [...allRooms];
    // Track if there are any errors
    let hasErrors = false;

    updatedRooms.forEach((room, index) => {
        if (room.doors || room.size) {
            const errors = handleSaveRoom(index, updatedRooms, setAllRooms, ["status", "id"], ["name"], maxSize);
            // If there are errors, set the status to something other than "save"
            if (errors) {
                hasErrors = true;
            }
        } else if (room.status !== "save") {
            // Filter out rooms with status "save" if they don't have doors or size
            updatedRooms[index].status = "error"; // or another status indicating errors
        }
    });

    // Set all rooms status if there are no errors
    if (!hasErrors) {
        updatedRooms.forEach(room => {
            if (room.doors && room.size) {
                room.status = "save";
            }
        });
    }

    // Update the state with the updated rooms
    // setAllRooms(updatedRooms);
};

export const handleRoomSave = (allRooms, setAllRooms, roomErrors, setRoomErrors) => {
    allRooms.map((room, index) => {
        if ((room.doors || room.size) && room.status !== "save") {
            handleSave(
                allRooms,
                setAllRooms,
                roomErrors,
                setRoomErrors,
                ["status", "id",],
                index,
                ["name"],
            )
        } else if (room.status !== "save") {
            const updatedArray = allRooms.filter(items => items.status === "save");
            setAllRooms(updatedArray)
        }
    })

    let hasErrors = false

    if (roomErrors.length) {
        hasErrors = roomErrors.some((error) => error.doors || error.size || error.name)
    }

    return hasErrors
}

export const handleEnergyMeterSave = (allEnergyMeters, setAllEnergyMeters, meterErrors, setMeterErrors) => {
    allEnergyMeters.map((meter, index) => {
        if ((meter.purpose || meter.type || meter.name_id || meter.location || meter.access || meter.amount) && meter.status !== "save") {
            handleSave(
                allEnergyMeters,
                setAllEnergyMeters,
                meterErrors,
                setMeterErrors,
                ["status", "id",],
                index,
            )
        } else if (meter.status !== "save") {
            const updatedArray = allEnergyMeters.filter(items => items.status === "save");
            setAllEnergyMeters(updatedArray)
        }
    })

    let hasErrors = false
    if (meterErrors.length) {
        hasErrors = meterErrors.some((error) => error.purpose || error.type || error.name_id || error.location || error.access || error.amount)
    }
    return hasErrors
}

export const handleBankSave = (allBankAccounts, setAllBankAccounts, bankErrors, setBankErrors) => {
    allBankAccounts.map((bank, index) => {
        if ((bank.purpose || bank.owner_name || bank.iban || bank.bic) && bank.status !== "save") {
            handleSave(
                allBankAccounts,
                setAllBankAccounts,
                bankErrors,
                setBankErrors,
                ["status", "id",],
                index,
            )
        } else if (bank.status !== "save") {
            const updatedBankAccounts = allBankAccounts.filter(bank => bank.status === "save");
            setAllBankAccounts(updatedBankAccounts)
        }
    })
    if (bankErrors.length) {
        const hasErrors = bankErrors.some((obj) => obj.owner_name || obj.iban || obj.bic || obj.purpose);
        return hasErrors
    } else {
        return false

    }
}

export const handleContactSave = (allContacts,
    setAllContacts,
    contactErrors,
    setContactErrors) => {
    allContacts.map((contact, index) => {
        if ((contact.type || contact.name || contact.role || contact.personal_phone || contact.office_phone || contact.email) && contact.status !== "save") {
            handleSave(
                allContacts,
                setAllContacts,
                contactErrors,
                setContactErrors,
                ["status", "id",],
                index,
            )
        } else if (contact.status !== "save") {
            const updatedArray = allContacts.filter(items => items.status === "save");
            setAllContacts(updatedArray)
        }
    })

    let hasErrors = false
    if (contactErrors.length) {
        hasErrors = contactErrors.some((error) => error.type || error.name || error.role || error.personal_phone || error.office_phone || error.email)
    }
    return hasErrors
}

export const handleMaturitiesSave = (allMaturities, setAllMaturities, maturityErrors, setMaturityErrors) => {
    allMaturities.map((maturity, index) => {
        if ((maturity.extension || maturity.noticePeriod) && maturity.status !== "save") {
            handleSave(
                allMaturities,
                setAllMaturities,
                maturityErrors,
                setMaturityErrors,
                ["status", "id",],
                index,
            )
        } else if (maturity.status !== "save") {
            const updatedArray = allMaturities.filter(items => items.status === "save");
            setAllMaturities(updatedArray)
        }
    })
    let hasErrors = false
    if (maturityErrors.length) {
        hasErrors = maturityErrors.some((error) => error.option || error.deadline)
    }
    return hasErrors
}

export const handleCostSave = (allExtraCost, setAllExtraCost, costErrors, setCostErrors) => {
    allExtraCost.map((cost, index) => {
        if ((cost.type || cost.payment_type || cost.amount || cost.amount_first_month || cost.amount_last_month || cost.applicable_tenant_rent) && cost.status !== "save") {
            handleSave(
                allExtraCost,
                setAllExtraCost,
                costErrors,
                setCostErrors,
                ["status", "id",],
                index,
            )
        } else if (cost.status !== "save") {
            const updatedArray = allExtraCost.filter(items => items.status === "save");
            setAllExtraCost(updatedArray)
        }
    })

    let hasErrors = false
    if (costErrors.length) {
        hasErrors = costErrors.every((error) => error.type || error.payment_type || error.amount || error.amount_first_month || error.amount_last_month || error.applicable_tenant_rent)
    }
    return hasErrors
}

export const handleEmployeesSave = (allEmployees, setAllEmployees, empErrors, setEmpErrors) => {
    allEmployees.map((emp, index) => {
        if ((emp.role || emp.position || emp.employee) && emp.status !== "save") {
            handleSave(
                allEmployees,
                setAllEmployees,
                empErrors,
                setEmpErrors,
                ["status", "id",],
                index,
            )
        } else if (emp.status !== "save") {
            const updatedEmployees = allEmployees.filter(emp => emp.status === "save");
            setAllEmployees(updatedEmployees)
        }
    })
    let hasErrors = false
    if (empErrors.length) {
        hasErrors = empErrors.some((error) => error.role || error.position || error.employee)
    }
    return hasErrors
}

export const handleFurnitureSave = (
    allFurniture,
    setAllFurniture,
    furnitureErrors,
    setFurnitureErrors
) => {
    allFurniture.map((item, index) => {
        if ((item.type || item.item) && item.status !== "save") {
            handleSave(
                allFurniture,
                setAllFurniture,
                furnitureErrors,
                setFurnitureErrors,
                ["status", "id",],
                index,
            )
        } else if (item.status !== "save") {
            const updatedArray = allFurniture.filter(items => items.status === "save");
            setAllFurniture(updatedArray)
            // handleDelete(
            //     allFurniture,
            //     setAllFurniture,
            //     furnitureErrors,
            //     setFurnitureErrors,
            //     index
            // )
        }
    })

    let hasErrors = false
    if (furnitureErrors.length) {
        hasErrors = furnitureErrors.some((error) => error.type || error.item)
    }
    return hasErrors
}

//new common functions
export const HandleAddNew = (emptyObj, allValuesArray, setAllValues) => {
    const values = [...allValuesArray];
    values.push(emptyObj);
    setAllValues(values);
}

export const handleDeleteNew = (allValuesArray, setAllValues, index) => {
    let values = [...allValuesArray]
    let filtered = values.filter((item, itemIndex) => itemIndex !== index)
    setAllValues(filtered)
}

export const handlEdit = (allValuesArray, setAllValues, index) => {
    const values = [...allValuesArray];
    if (index > -1) {
        values[index].status = "edit";
    }
    setAllValues(values);
};

export const handleCancelNew = (data, index, allValues, setAllValues) => {
    let values = [...allValues]
    if (index !== -1) {
        values[index] = data
        values[index].status = "save";
        values[index].formErrors = {};
    }
    setAllValues(values)
}

export const handleSaveNew = (index, allValuesArray, setAllValues, ignoreInputs = [], optional = []) => {
    const values = [...allValuesArray];
    let hasErrors = false
    const fieldKeys = Object.keys(values[index]);
    fieldKeys.forEach((key) => {
        const value = values[index][key];
        let formErrors = formValidation(key, value, values[index], ignoreInputs, optional);
        values[index] = { ...values[index], [key]: value, formErrors };
        if (formErrors[key]) {
            hasErrors = true;
        }
    });

    if (!hasErrors) {
        values[index].status = "save"
    }
    setAllValues(values)
    return hasErrors
};

export const handleChangeNew = (name, value, index, allValues, setAllValues, ignoreInputs = [], optional = [],) => {
    const updatedArray = [...allValues];
    if (index > -1 && index < updatedArray.length) {
        let formErrors = formValidation(name, value, updatedArray[index], ignoreInputs, optional);
        updatedArray[index] = { ...updatedArray[index], [name]: value, formErrors, };
        setAllValues(updatedArray);
    }
};

//room functions
export const handleRoomChange = (name, value, index, allRooms, setAllRooms, ignoreInputs, optional, maxSize) => {
    const updatedRooms = [...allRooms];

    if (index > -1 && index < updatedRooms.length) {
        let formErrors = formValidation(name, value, updatedRooms[index], ignoreInputs, optional);

        if (name === "size") {
            let isGreater = handleRoomSizeError(value, index, maxSize, allRooms)
            if (isGreater) {
                formErrors = ({ ["size"]: "The size must be lower than the maximum size of the building." })
            } else {
                formErrors = formValidation(name, value, updatedRooms[index], ignoreInputs, optional);
            }
        }
        updatedRooms[index] = { ...updatedRooms[index], [name]: value, formErrors };
        setAllRooms(updatedRooms);
    }
};

export const handleRoomSizeError = (value, index, maxSize, allRooms) => {
    const filterdRooms = allRooms.filter((item, itemIndex) => itemIndex !== index)
    const totalSize = filterdRooms.reduce((acc, obj) => {
        return acc + parseFloat(obj?.size)
    }, 0)

    const total = totalSize + parseFloat(value)
    let isGreater = checkIsValueGreater(total, maxSize)
    return isGreater
}

export const handleSaveRoom = (index, allValuesArray, setAllValues, ignoreInputs = [], optional = [], maxSize) => {
    const values = [...allValuesArray];
    let hasErrors = false
    const fieldKeys = Object.keys(values[index]);
    fieldKeys.forEach((key) => {
        const value = values[index][key];
        let formErrors = formValidation(key, value, values[index], ignoreInputs, optional);
        if (key === "size") {
            let isGreater = handleRoomSizeError(value, index, maxSize, allValuesArray)
            if (isGreater) {
                formErrors = ({ ["size"]: "The size must be lower than the maximum size of the building." })
            } else {
                formErrors = formValidation(key, value, values[index], ignoreInputs, optional);
            }
        }
        values[index] = { ...values[index], [key]: value, formErrors };
        if (formErrors[key]) {
            hasErrors = true;
        }
    });

    if (!hasErrors) {
        values[index].status = "save"
    }
    setAllValues(values)
    return hasErrors
};

export const checkRoomErrors = (allRooms, setAllRooms, maxsize) => {
    let hasErrors = false;
    let updatedRooms = [];

    // Transform each room and validate
    updatedRooms = allRooms.map((item, index) => {
        if ((item.doors || item.size) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};

            // Validate each field
            fieldKeys.forEach((key) => {
                const value = item[key];
                let errors = formValidation(key, value, item, ["id", "status"], ["name"]);

                if (key === "size") {
                    const isGreater = handleRoomSizeError(value, index, maxsize, allRooms);
                    if (isGreater) {
                        errors = { ...errors, size: "The size must be lower than the maximum size of the building." };
                    }
                }

                formErrors = { ...formErrors, ...errors };

                if (errors[key]) {
                    hasErrors = true;
                }
            });

            // Update the room with validation errors and status
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }

        // If the room doesn't need validation, return it as is
        return item;
    });

    // Update state with the transformed rooms
    setAllRooms(updatedRooms);

    return { hasErrors, rooms: updatedRooms };
};

export const checkEnergyMeterErrors = (allValues, setAllValues) => {
    let hasErrors = false;

    // Process and validate each item
    const updatedValues = allValues.map((item) => {
        if ((item.type || item.purpose || item.meter_id || item.location || item.access || item.amount) && item.status !== "save") {
            let formErrors = {};

            // Validate each field in the item
            Object.keys(item).forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"]);

                // Merge errors into formErrors
                formErrors = { ...formErrors, ...errors };

                if (errors[key]) {
                    hasErrors = true;
                }
            });

            // Return the item with errors and updated status
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }

        // Return the item if its status is 'save'
        return item.status === "save" ? item : null;
    }).filter(Boolean);

    // Update the state with the updated values
    setAllValues(updatedValues);

    // Return the results
    return { hasErrors, energyMeters: updatedValues };
};

export const checkBankErrors = (allValues, setAllValues, optional = []) => {
    let hasErrors = false;

    const updatedValues = allValues.map((item) => {
        if ((item?.purpose || item?.owner_name || item?.iban || item?.bic) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"], optional);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);
    setAllValues(updatedValues);

    // Return the results
    return { hasErrors, bankAccounts: updatedValues };
};

export const checkFurnitureErrors = (allValues, setAllValues) => {
    let hasErrors = false;
    const updatedValues = allValues.map((item) => {
        if ((item.type || item.item) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"]);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);

    setAllValues(updatedValues);
    // Return the results
    return { hasErrors, furniture: updatedValues };
}

export const checkContactErrors = (allValues, setAllValues, optional = []) => {
    let hasErrors = false;

    const updatedValues = allValues.map((item) => {
        if ((item.type || item.name || item.role || item.mobile || item.office_phone || item.email) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"], optional);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);
    setAllValues(updatedValues);
    // Return the results
    return { hasErrors, contacts: updatedValues };
}

export const checkOptionalMaturtiesErrors = (allValues, setAllValues, optional = []) => {
    let hasErrors = false;

    const updatedValues = allValues.map((item) => {
        if ((item.extension || item.notice_period) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"], optional);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);
    setAllValues(updatedValues);
    return { hasErrors, optionalMaturities: updatedValues };
};

export const checkExtraCostErrors = (allValues, setAllValues, optional = []) => {
    let hasErrors = false;

    const updatedValues = allValues.map((item) => {
        if ((item?.type || item?.payment_type || item?.meter_id || item?.location || item?.access || item?.amount) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"], optional);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);
    setAllValues(updatedValues);
    return { hasErrors, extraCosts: updatedValues };
}

export const checkEmployeesErrors = (allValues, setAllValues, optional = []) => {
    let hasErrors = false;

    const updatedValues = allValues.map((item) => {
        if ((item.position || item.employee) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"], optional);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);
    setAllValues(updatedValues);
    return { hasErrors, employees: updatedValues };
}

export const checkHandymanServiceErrors = (allValues, setAllValues, optional = []) => {
    let hasErrors = false;

    const updatedValues = allValues.map((item, index) => {
        if ((item?.service || item?.email || item?.work_phone || item?.home_phone) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"], optional);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }else if(index === 0 && item.status !== "save"){
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"], optional);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);
    setAllValues(updatedValues);

    // Return the results
    return { hasErrors, services: updatedValues };
};

export const checkOfficeErrors = (allValues, setAllValues, optional = []) => {
    let hasErrors = false;

    const updatedValues = allValues.map((item) => {
        if ((item.office || item.position) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"], optional);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);
    setAllValues(updatedValues);
    return { hasErrors, offices: updatedValues };
};

export const checkMaintenanceCostsErrors = (allValues, setAllValues) => {
    let hasErrors = false;

    const updatedValues = allValues.map((item) => {
        if ((item?.cost_type || item?.amount) && item.status !== "save") {
            const fieldKeys = Object.keys(item);
            let formErrors = {};
            fieldKeys.forEach((key) => {
                const value = item[key];
                const errors = formValidation(key, value, item, ["id", "status"]);
                formErrors = { ...formErrors, ...errors };
                if (errors[key]) {
                    hasErrors = true;
                }
            });
            return { ...item, formErrors, status: hasErrors ? item.status : "save" };
        }
        return item.status !== "save" ? null : item;
    }).filter(Boolean);
    setAllValues(updatedValues);

    // Return the results
    return { hasErrors, costs: updatedValues };
};
