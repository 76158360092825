import axios from "axios";
import constants from "../constants";
import { isAuthenticated, logout } from "./auth";

const api = async (method, urlEndPoint, data = {}, params = {}) => {
  try {
    let headers = {
      "Content-Type": "application/json",
    };
    if (isAuthenticated()) {
      headers = {
        ...headers,
        Authorization: `Bearer ${isAuthenticated()}`,
      };
    }

    let response = await axios({
      method,
      url: constants.endPointUrl + urlEndPoint,
      data,
      headers,
      params,
    });

    let res = response.data;
    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      logout();
    }
    let res = error?.response ? error.response.data : error.toString();

    return res;
  }
};

export default api;

export const checkIban = async (data) => {
  try {
    let headers = {
      "Content-Type": "application/json",
    };
    let res = await axios.get('https://ibanvalidation.abstractapi.com/v1/', {
      params: {
        api_key: data?.api_key,
        iban: data?.iban
      },
      headers
    })
    return res
  } catch (error) {
    return error
  }
}
