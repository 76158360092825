import React, { useRef } from "react";
// import { Messages } from "primereact/messages";
import {
  CustomDropDown,
  CustomFileInput,
  CustomForm,
  CustomNumberInput2,
  CustomSizeM2,
} from "shared/AllInputs";
import {
  BuildingTypeOptions,
  StairAccessOptions,
} from "shared/AllDropDownOptions";
import { Toast } from "primereact/toast";
import SizeM2 from "assets/images/svg/SizeM2.svg"
const BuildingStucture = ({
  data,
  handleChange,
  buildingOptions,
  fileKey,
  sturcureDocuments,
  fileChange,
  fileDelete,
}) => {
  const msgs = useRef(null);

  return (
    <>
      <CustomForm title="Building Structure">
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="building"
          options={buildingOptions}
          label="Select Building"
          placeholder="Select Building"
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="buildingType"
          options={BuildingTypeOptions}
          required
        />
        <CustomNumberInput2
          data={data}
          onChange={handleChange}
          name="buildingMaxFloor"
          max={3}
          required
        />
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="staircaseAccess"
          options={StairAccessOptions}
          required
        />
        <CustomSizeM2
          data={data}
          onChange={handleChange}
          name="size"
          label={"Size"}
          placeholder={"Enter Size"}
          spanExtraClassName="p-1"
          max={9}
          required
          spanIcon={SizeM2}
        />
      </CustomForm>
      <CustomForm title="Upload Document">
        <CustomFileInput
          data={data}
          selectedFiles={sturcureDocuments}
          inputKey={fileKey}
          type="file"
          name="document"
          onChange={fileChange}
        />
        {sturcureDocuments?.length > 0 && (
          <div>
            <h5>Selected files:</h5>
            {sturcureDocuments?.map((file, index) => (
              <li className="upload-list" key={index}>
                {file?.name || file?.document}{" "}
                <i
                  onClick={() => fileDelete(index)}
                  className="pi pi-times"
                  style={{ color: "red", fontSize: "1rem", cursor: "pointer" }}
                ></i>
              </li>
            ))}
          </div>
        )}
      </CustomForm>
    </>
  );
};

export default React.memo(BuildingStucture);
