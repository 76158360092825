import React from "react";
import { CustomForm, CustomInput } from "../../shared/AllInputs";

const FinancialDetails = ({ data, title, handleChange }) => {
  return (
    <>
      <CustomForm title={title}>
        <CustomInput
          data={data}
          onChange={handleChange}
          name="costCenter"
          label="Cost Centre"
          placeholder="Enter Cost Centre"
          required
        />
      </CustomForm>
    </>
  );
};

export default FinancialDetails;
