import React, { useState, useEffect } from "react";
import classNames from "classnames";

import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import { getUserInfo } from "../services/auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  // const [isUserOnBoarding, setIsUserOnBoarding] = useState("");
  const { profile } = useSelector((state) => state.user);
  const navigate = useNavigate();
  // const [profileInfo, setProfileInfo] = useState("");

  useEffect(() => {
    let info = "";
    profile?.firstName ? (info = profile) : (info = getUserInfo());
    // setProfileInfo(info);

    // setIsUserOnBoarding(info?.is_onboarding);
    // if (info?.is_onboarding) {
    //   setIsSidebarOpen(false);
    //   navigate("/create-project");
    // } else {
      // navigate("/dashboard");
      setIsSidebarOpen(true);
    // }
  }, [profile]);

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": !isSidebarOpen,
    "layout-static": isSidebarOpen,
    "layout-mobile-sidebar-active": mobileMenuActive,
  });

  useEffect(() => {
    if (!isDesktop()) {
      setIsSidebarOpen(false);
    }
  }, []);

  const toggleSidebar = () => {
    if (isDesktop()) {
      setIsSidebarOpen((prev) => !prev);
    } else {
      // if (isSidebarOpen && !isUserOnBoarding) {
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
        setMobileMenuActive(false);
        addClass(document.body, "body-overflow-hidden");
      } else {
        setIsSidebarOpen(true);
        setMobileMenuActive(true);
        removeClass(document.body, "body-overflow-hidden");
      }
    }
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };
  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  return (
    <div className={wrapperClass}>
      {/* {!isUserOnBoarding && <Sidebar />} */}
      <Sidebar />
      <Topbar toggleSidebar={toggleSidebar} />

      <div className="layout-main-container">
        <div className={`layout-main `}>{children}</div>
      </div>
    </div>
  );
}
