import React, { useState, useRef } from "react";
import {
  CustomAmountInput,
  CustomDropDown,
  CustomForm,
  CustomInput,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import {
  HandleAddNew,
  handleCancelNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import {
  AccessOptions,
  ElectricityuPurposeOptions,
  GasPurposeOptions,
  LocationOptions,
  MeterTypeOpt,
} from "../AllDropDownOptions";
import CustomOverlay from "../CustomOverlay";
import { Sidebar } from "primereact/sidebar";
import AddEnergyClocks from "./AddEnergyClock";
import CustomModal from "../Modals/CustomModal";
import { useDispatch } from "react-redux";
import {
  addEnergyMeterAction,
  deleteEnergyMeterAction,
  getEnergyMeterAction,
  updateEnergyMeterAction,
} from "store/actions/buildingUnitActions";
import { spaceNotAllowed } from "utils/regex";
import { Toast } from "primereact/toast";
import CrossIcon from "assets/images/svg/cross-icon.svg"
import SaveIcon from "assets/images/svg/Save-icon.svg"

const AddEnergyMeters = ({ unitId, allEnergyMeters, setAllEnergyMeters }) => {
  const msgs = useRef(null);
  const openAction = useRef(null);
  const dispatch = useDispatch();
  const InputObj = {
    id: null,
    purpose: "",
    type: "",
    meter_id: "",
    location: "",
    access: "",
    amount: "",
    status: "",
  };

  const ignoreInputs = ["status", "id"];
  const [openRightbar, setOpenRightbar] = useState({
    isOpen: false,
    id: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() =>
          HandleAddNew(InputObj, allEnergyMeters, setAllEnergyMeters)
        }
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected energy meter from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleAddEnergyMeter = (meter, index) => {
    let hasErrors = handleSaveNew(
      index,
      allEnergyMeters,
      setAllEnergyMeters,
      ignoreInputs
    );

    if (!hasErrors && unitId) {
      let data = {
        purpose: meter?.purpose,
        type: meter?.type,
        meter_id: meter?.meter_id,
        location: meter?.location,
        access: meter?.access,
        amount: meter?.amount,
        building_unit: unitId,
      };
      if (meter?.id) {
        dispatch(
          updateEnergyMeterAction(meter?.id, data, dispatch, (res) => {
            if (!res?.success) {
              handlEdit(allEnergyMeters, setAllEnergyMeters, index);
            }
          })
        );
      } else {
        dispatch(
          addEnergyMeterAction(data, dispatch, (res) => {
            if (res) {
              allEnergyMeters[index].id = res?.id;
              allEnergyMeters[index].status = "save";
              setAllEnergyMeters(allEnergyMeters);
            } else {
              handlEdit(allEnergyMeters, setAllEnergyMeters, index);
            }
          })
        );
      }
    }
  };

  const handleEnergyMeterDelete = () => {
    if (idToDelete?.id) {
      dispatch(
        deleteEnergyMeterAction(idToDelete?.id, setLoading, dispatch, (res) => {
          handleDeleteNew(
            allEnergyMeters,
            setAllEnergyMeters,
            idToDelete?.index
          );
          setIdToDelete({ id: "", index: "" });
          setDeleteModal(false);
        })
      );
    } else {
      handleDeleteNew(allEnergyMeters, setAllEnergyMeters, idToDelete?.index);
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    }
  };

  const customHeader = (
    <div className="">
      <div className="sidebar-title">Energy Clock </div>
    </div>
  );

  const getMeterDetails = (id, index) => {
    dispatch(
      getEnergyMeterAction(id, (res) => {
        const info = res?.data?.results;
        if (res?.success) {
          handleCancelNew(info, index, allEnergyMeters, setAllEnergyMeters);
        }
      })
    );
  };

  return (
    <>
      <CustomForm title={"Energy Meters"} header={header}>
        <Toast ref={msgs} />
        <div className="overflow-auto">
          <table className="financial-table w-full mt-3 table-error-align">
            <thead>
              <tr>
                <th>Type</th>
                <th>Purpose</th>
                <th>Meter ID</th>
                <th>Location</th>
                <th>Access</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="financial-table-form " className="cell-width">
              {allEnergyMeters?.map((meter, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <CustomDropDown
                          data={meter}
                          onChange={
                            (e) =>
                              handleChangeNew(
                                e?.target?.name,
                                e?.target?.value,
                                index,
                                allEnergyMeters,
                                setAllEnergyMeters,
                                ignoreInputs
                              )
                          }
                          name="type"
                          col={12}
                          options={MeterTypeOpt}
                          disabled={meter?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomDropDown
                          data={meter}
                          col={12}
                          onChange={
                            (e) =>
                              handleChangeNew(
                                e?.target?.name,
                                e?.target?.value,
                                index,
                                allEnergyMeters,
                                setAllEnergyMeters,
                                ignoreInputs
                              )
                          }
                          name="purpose"
                          options={
                            meter.type === "Electricity"
                              ? ElectricityuPurposeOptions
                              : GasPurposeOptions
                          }
                          disabled={meter?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomInput
                          data={meter}
                          col={12}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (spaceNotAllowed(value)) {
                              handleChangeNew(
                                e?.target?.name,
                                value.toUpperCase(),
                                index,
                                allEnergyMeters,
                                setAllEnergyMeters,
                                ignoreInputs
                              );
                            } else if (!value) {
                              handleChangeNew(
                                e?.target?.name,
                                value,
                                index,
                                allEnergyMeters,
                                setAllEnergyMeters,
                                ignoreInputs
                              );
                            }
                          }}
                          name="meter_id"
                          disabled={meter?.status === "save" ? true : false}
                          placeholder="Enter Meter ID"
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomDropDown
                          data={meter}
                          onChange={
                            (e) =>
                              handleChangeNew(
                                e?.target?.name,
                                e?.target?.value,
                                index,
                                allEnergyMeters,
                                setAllEnergyMeters,
                                ignoreInputs
                              )
                          }
                          name="location"
                          col={12}
                          options={LocationOptions}
                          disabled={meter?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td>
                        <CustomDropDown
                          data={meter}
                          onChange={
                            (e) =>
                              handleChangeNew(
                                e?.target?.name,
                                e?.target?.value,
                                index,
                                allEnergyMeters,
                                setAllEnergyMeters,
                                ignoreInputs
                              )
                          }
                          name="access"
                          col={12}
                          options={AccessOptions}
                          disabled={meter?.status === "save" ? true : false}
                          ignoreLabel
                        />
                      </td>
                      <td style={{"min-width": "200px"}}>
                        <CustomAmountInput
                          data={meter}
                          col={12}
                          onChange={
                            (e) =>
                              handleChangeNew(
                                "amount",
                                e?.value,
                                index,
                                allEnergyMeters,
                                setAllEnergyMeters,
                                ignoreInputs
                              )
                          }
                          name="amount"
                          disabled={meter?.status === "save" ? true : false}
                          spanLabel={
                            meter?.type === "Electricity" ? (
                              "kWh"
                            ) : (
                              <p>
                                m<sup>3</sup>
                              </p>
                            )
                          }
                          spanExtraClassName={"p-1"}
                          ignoreLabel
                        />
                      </td>
                      {meter?.status === "save" ? (
                        <td>
                          <CustomOverlay ref={openAction}>
                            <ul className="no-style p-0">
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() =>
                                  handlEdit(
                                    allEnergyMeters,
                                    setAllEnergyMeters,
                                    index
                                  )
                                }
                              >
                                <i className="pi pi-file-edit text-dark"></i>
                                Edit
                              </li>
                              {/* } */}
                              {unitId && (
                                <li
                                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                  onClick={() =>
                                    setOpenRightbar({
                                      isOpen: true,
                                      id: meter?.id,
                                    })
                                  }
                                >
                                  <i className="pi pi-bolt text-dark"></i>
                                  Energy Clock
                                </li>
                              )}
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() => {
                                  setIdToDelete({
                                    id: meter?.id,
                                    index: index,
                                  });
                                  setDeleteModal(true);
                                }}
                              >
                                <i className="pi pi-trash cursor-pointer text-dark"></i>
                                Delete
                              </li>
                            </ul>
                          </CustomOverlay>
                        </td>
                      ) : (
                        <td>
                          <div className="d-flex gap-3">
                            <a
                              className="cursor-pointer"
                              onClick={() => handleAddEnergyMeter(meter, index)}
                            >
                             <img src={SaveIcon} />
                            </a>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                if (meter?.id) {
                                  getMeterDetails(meter?.id, index);
                                } else {
                                  handleDeleteNew(
                                    allEnergyMeters,
                                    setAllEnergyMeters,
                                    index
                                  );
                                }
                              }}
                            >
                             <img src={CrossIcon} />
                            </a>
                          </div>
                        </td>
                      )}
                    </tr>

                    {/* {(meter?.formErrors?.type ||
                      meter?.formErrors?.purpose ||
                      meter?.formErrors?.meter_id ||
                      meter?.formErrors?.location ||
                      meter?.formErrors?.access ||
                      meter?.formErrors?.amount) && (
                      <tr>
                        <td>
                          <small className="p-error">
                            {meter?.formErrors?.type}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {meter?.formErrors?.purpose}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {meter?.formErrors?.meter_id}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {meter?.formErrors?.location}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {meter?.formErrors?.access}
                          </small>
                        </td>
                        <td>
                          <small className="p-error">
                            {meter?.formErrors?.amount}
                          </small>
                        </td>
                      </tr>
                    )} */}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
      </CustomForm>
      <Sidebar
        header={customHeader}
        className="view-enery-meter-sidebar"
        visible={openRightbar.isOpen}
        position="right"
        onHide={() => setOpenRightbar({ isOpen: false, id: "" })}
      >
        <AddEnergyClocks meterId={openRightbar?.id} />
      </Sidebar>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleEnergyMeterDelete}
        loading={loading}
      />
    </>
  );
};

export default AddEnergyMeters;
