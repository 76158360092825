import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUserInfo } from "services/auth";
import CustomButton from "shared/CustomButton";
import { AddAnnualStatementAction, getAnnualStatenentAction, UpdateAnnualStatementAction } from "store/actions/AnnualStatementActions";
import { setAnnualStatementSlice } from "store/slices/annualStatementSlice";
import { showToastAction } from "store/slices/commonSlice";
import { setUserProfile } from "store/slices/userSlice";

const AnnualStatementContainer = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const { profile } = useSelector((state) => state?.user)
    const [propertyData, setPropertyData] = useState({
        id: "", propertyID: "", contractID: "", status: "", address: "", statementStatus: "",
    })
    const [allDocuments, setAllDocuments] = useState([])
    const [loading, setLoading] = useState(false)
    const [isPropertyModal, setIsPropertyModal] = useState(false)
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isAddDocument, setIsAddDocument] = useState(false)
    const [documentType, setDocumentType] = useState({ type: "", isAdd: false, action: "" })
    const [selectedDocument, setSelectedDocument] = useState({id: "", index: ""})

    useEffect(() => {
        if (!profile?.company) {
            let info = getUserInfo()
            // dispatch(setUserProfile(info))
        }
    }, [profile])

    useEffect(() => {
        // if (params?.id && profile?.company && damageReport?.id) {
        //     handleSetDamageReportData(damageReport)
        // } else if (params?.id && profile?.company && !damageReport?.id) {
        //     getDamageReportDetails(profile?.company, params?.id);
        // }

        if (params?.id && profile?.company) {
            getAnnualStatementDetails()
        }

    }, [params?.id, profile]);

    const getAnnualStatementDetails = () => {
        dispatch(
            getAnnualStatenentAction(profile?.company, params?.id, dispatch, (res) => {
                setPropertyData({
                    id: res?.property?.id,
                    propertyID: res?.property?.property_id,
                    contractID: res?.property?.contract?.contract_id,
                    status: res?.property?.status,
                    statementStatus: res?.status,
                    address: res?.property?.address,
                })

                setAllDocuments(res?.documents)
                setSelectedProperty(res?.property)
            })
        )
    }

    const handleAddedDocuments = () => {
        let addedDocument = "";
        if (allDocuments.length === 1) {
            addedDocument = allDocuments[0].document_type
        } else if (allDocuments.length === 2) {
            addedDocument = "Both";
        } else {
            addedDocument = null;
        }
        return addedDocument;
    };

    const documentheader = () => {
        const addedDocument = handleAddedDocuments();
        return (
            <div className="flex justify-content-end cursor-pointer">
                <CustomButton
                    type="button"
                    extraClassNames={"add-document"}
                    size="small"
                    label="Add Document"
                    onClick={() => {
                        if (selectedProperty || params?.id) {
                            setIsAddDocument(true)
                            setSelectedDocument({id: "", index: ""})
                        } else {
                            dispatch(showToastAction({
                                type: "error",
                                detail: "Please select property first.",
                            }))
                        }
                    }}
                    disabled={addedDocument === "Both" || addedDocument === "Combined"}
                />
            </div>
        );
    };

    const onSubmit = () => {
        if (!selectedProperty) {
            dispatch(showToastAction({
                type: "error",
                detail: "Please select property.",
            }))
        } else if (!allDocuments.length) {
            dispatch(showToastAction({
                type: "error",
                detail: "Please add document.",
            }))
        } else {
            if (params?.id) {
                dispatch(UpdateAnnualStatementAction(profile?.company, selectedProperty?.id, params?.id, allDocuments, setLoading, dispatch, (res) => {
                    dispatch(setAnnualStatementSlice(res))
                    params?.propertyId? navigate(`/real-estate/property/view/annual-statement/${params?.propertyId}`) : navigate("/real-estate/annualstatement/list")
                }))
            } else {
                dispatch(AddAnnualStatementAction(profile?.company, selectedProperty?.id, allDocuments, setLoading, dispatch, (res) => {
                    dispatch(setAnnualStatementSlice(res))
                    params?.propertyId?  navigate(`/real-estate/property/view/annual-statement/${params?.propertyId}`) : navigate("/real-estate/annualstatement/list")
                }))
            }
        }
    }

    const onConfirm = () => {
        setPropertyData({
            id: selectedProperty?.id,
            propertyID: selectedProperty?.property_id,
            contractID: selectedProperty?.contract?.contract_id,
            address: selectedProperty?.address,
            status: selectedProperty?.status
        })
        setIsPropertyModal(false)
    }

    const handleEditStatement = (id, index) => {
        setDocumentType({ isAdd: true, action: "Edit" })
        setSelectedDocument({id: id, index: index})
    }

    useEffect(() => {
        if(params?.propertyId && selectedProperty){
            setPropertyData({
                id: selectedProperty?.id,
                propertyID: selectedProperty?.property_id,
                contractID: selectedProperty?.contract?.contract_id,
                address: selectedProperty?.address,
                status: selectedProperty?.status
            })
        }
    }, [params?.propertyId, selectedProperty])

    return {
        propertyData,
        onSubmit,
        loading,
        navigate,
        isPropertyModal,
        setIsPropertyModal,
        onConfirm,
        selectedProperty,
        setSelectedProperty,
        handleEditStatement,
        documentheader,
        isAddDocument,
        setIsAddDocument,
        allDocuments,
        setAllDocuments,
        documentType,
        setDocumentType,
        handleAddedDocuments,
        selectedDocument,
        propertyId: params?.propertyId,
    }
}
export default AnnualStatementContainer