import { Accordion, AccordionTab } from "primereact/accordion";
import { OverlayPanel } from "primereact/overlaypanel";
import React, { useRef } from "react";
import { CustomImageView } from "shared/AllViewInputs";
import { handleFormatDate } from "utils/commonFunctions";
import CustomAddMaintenanceTable from "views/Tables/CustomAddMaintenanceCostsTable";

const DocumentView = ({ document, docIndex, onEdit }) => {
  const headerOp = useRef(null);
  const accordianHeader = (document) => {
    return (
      <div className="flex align-items-center justify-content-between w-full ">
        <p className="title w-4">{document?.document_type === "Combined"? document?.document_type : `${document?.document_type} Cost`}</p>
        <p className="title w-4">
          {handleFormatDate(document?.processedDate || new Date())}
        </p>
        {/* {document?.id && ( */}
          <>
            <i
              className="pi pi-ellipsis-v cursor-pointer text-dark"
              onClick={(e) => headerOp.current.toggle(e)}
            ></i>
            <OverlayPanel ref={headerOp} className="px-3">
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => onEdit(document?.id, docIndex)}
                >
                  <i className="pi pi-file-edit text-dark"></i> Edit
                </li>
              </ul>
            </OverlayPanel>
          </>
        {/* )} */}
      </div>
    );
  };

  return (
    <Accordion activeIndex={document.id} className="w-full mt-3">
      <AccordionTab
        headerClassName="accordion-role-list"
        headerTemplate={() => accordianHeader(document)}
      >
        <div className="p-4 w-full border-round-xl grid mt-3">
          <div className="col-6">
            <p className="mb-2">Print Date</p>
            <span className="font-bold">
              {handleFormatDate(document?.print_date)}
            </span>
            <p className="mb-2 mt-4">Date From</p>
            <span className="font-bold">
              {handleFormatDate(document?.print_date)}
            </span>
          </div>
          <div className="col-6">
            <p className="mb-2">Year of Statement</p>
            <span className="font-bold">
              {typeof document?.year_of_statement === "object"
                ? handleFormatDate(document?.year_of_statement, "year")
                : document?.year_of_statement}
            </span>
            <p className="mb-2 mt-4">Date To</p>
            <span className="font-bold">
              {handleFormatDate(document?.print_date)}
            </span>
          </div>
          <p className="col-12 mt-4">
            <span className="font-bold">Upload document</span>{" "}
            <div className="flex mt-3 gap-4 flex-wrap">
              {document?.document_file ? (
                <CustomImageView image={document?.document_file} />
              ) : typeof document?.document === "string" ? (
                <CustomImageView image={document?.document} />
              ) : (
                <CustomImageView src={document?.document} />
              )}
            </div>
          </p>

          {(document?.document_type === "Heating" ||
            document?.document_type === "Combined") && (
            <>
              {document?.document_type === "Combined" && (
                <span className="title col-12">Heating Cost</span>
              )}
              <div className="col-6">
                <p className="mb-2">Fixed Cost</p>
                <span className="font-bold">
                  {document?.heating_cost?.fixed_cost}
                </span>
              </div>
              <div className="col-6">
                <p className="mb-2">Variable Cost</p>
                <span className="font-bold">
                  {document?.heating_cost?.variable_cost}
                </span>
              </div>
            </>
          )}
          {(document?.document_type === "Maintenance" ||
            document?.document_type === "Combined") && (
            <CustomAddMaintenanceTable
              action={""}
              id={""}
              title={
                document?.document_type === "Combined" ? "Maintenance Cost" : ""
              }
              allCosts={document?.maintenance_costs}
              setAllFurniture={""}
            />
          )}
        </div>
      </AccordionTab>
    </Accordion>
  );
};

export default DocumentView;
