import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const annualStatementSlice = createSlice({
    name: "annualStatement",
    initialState,
    reducers: {
        setAnnualStatementSlice: (state, action) => {
            state.annualStatement = action.payload
        },
    },

});

export const { setAnnualStatementSlice } = annualStatementSlice.actions;
export default annualStatementSlice.reducer;