import React, { useEffect, useMemo, useRef, useState } from "react";
import { handleSetAddress, showFormErrors } from "utils/commonFunctions";
import formValidation from "utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { addLandlordAction, getLandlordAction,  searchLandlordByNameAction, updateLandlordAction } from "store/actions/landlordActions";
import { debounce } from "lodash";
import { checkContactErrors } from "shared/Components/commonFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { setLandlordSlice } from "store/slices/landlordSlice";

const LandlordContainer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams();
    const [data, setData] = useState({
        id: "",
        landlordId: "",
        landlordName: "",
        landlordType: "",
        address: "",
        street: "",
        zipCode: "",
        city: "",
        cityDivision: "",
        country: "",
        email: "",
        phoneNumber: "",
    })

    //contacts
    const [allContacts, setAllContacts] = useState([{
        type: "",
        name: "",
        role: "",
        mobile: "",
        office_phone: "",
        email: "",
    }])
    const [landlordOptions, setLandlordOptions] = useState([])
    const [landlordAddress, setLandlordAddress] = useState()
    const [loading, setLoading] = useState(false)
    const { profile } = useSelector((state) => state?.user)
    const { landlord } = useSelector((state) => state?.landlord)
    const [isSearch, setIsSearch] = useState(false)


    useEffect(() => {
        if (params?.id && profile?.company) {
            landlord ? handleLandlordInfo(landlord) : getLandlordDetail(profile?.company, params?.id);
        }
    }, [params?.id, profile, landlord]);

    useMemo(() => {
        if (landlordAddress) {
            handleSetAddress(landlordAddress, data, setData, ["longitude", "latitude"])
        }
    }, [landlordAddress])

    const getLandlordDetail = (company, id) => {
        dispatch(getLandlordAction(company, id, dispatch, (res) => {
            handleLandlordInfo(res)
        }))
    }

    const handleLandlordInfo = (info) => {
        let landlordData = {
            id: info?.id,
            landlordId: info?.landlord_id,
            landlordName: info?.landlord_name,
            landlordType: info?.type,
            address: info?.address,
            street: info?.street,
            zipCode: info?.zip_code,
            city: info?.city,
            cityDivision: info?.city_division,
            country: info?.country,
            email: info?.email,
            phoneNumber: info?.phone_number,
        }

        setData(landlordData)
        if (info?.contacts?.length) {
            let contacts = info?.contacts?.map((contact) => {
                return { ...contact, status: "save" }
            })
            setAllContacts(contacts)
        } else {
            setAllContacts([])
        }
    }

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleLandlordSearch = (company, value) => {
        dispatch(
            searchLandlordByNameAction(company, value, setLandlordOptions, setIsSearch, (error) => {
                const note = ({ ["landlordName"]: error })
                setData((prev) => ({ ...prev, ["landlordName"]: value, note }));
            })
        );
    };

    const landlordIdChange = useRef(debounce((company, value) => {
        const note = { ["name"]: "" };
        setData((prev) => ({ ...prev, note }));
        handleLandlordSearch(company, value)
    }, 1000));

    const onSubmit = () => {
        const contacts = checkContactErrors(allContacts, setAllContacts, ["office_phone", "email"])
        if (showFormErrors(data, setData, ["landlordId"]) && !contacts?.hasErrors) {
            let info = {
                ...data,
                contacts: contacts?.contacts,
            }
            if (data?.id) {
                dispatch(
                    updateLandlordAction(
                        profile?.company,
                        data?.id,
                        info,
                        setLoading,
                        dispatch,
                        (res) => {
                            navigate(`/real-estate/landlord/view/${params?.id}`)
                            handleLandlordInfo(res)
                            dispatch(setLandlordSlice(res))
                        }
                    )
                )
            } else {
                dispatch(
                    addLandlordAction(
                        profile?.company,
                        info,
                        setLoading,
                        dispatch,
                        (res) => {
                            navigate("/real-estate/landlord/list")
                            handleLandlordInfo(res)
                            dispatch(setLandlordSlice(res))
                        }
                    )
                )
            }
        }
    }

    return {
        data,
        handleChange,
        allContacts,
        setAllContacts,
        onSubmit,
        landlordOptions,
        landlordIdChange,
        setLandlordAddress,
        navigate,
        loading,
        isSearch
    }
}

export default LandlordContainer