import React, { useState } from "react";
import {
  CustomFileInput,
  CustomForm,
  CustomRatings,
  CustomTextArea,
} from "../../../../../shared/AllInputs";
import formValidation from "../../../../../utils/validations";
import {
  getFileExtension,
  getFileName,
  handleFileConversion,
} from "../../../../../utils/commonFunctions";

const DamageFeedback = ({ isRating, data, setData }) => {
  const [images, setImages] = useState([]);
  const [fileKey, setFileKey] = useState(0);

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setImages([...images, file]);

    const convertedFile = await handleFileConversion(file);
    let douments = [...data.images];
    const formErrors = formValidation("images", convertedFile, data);
    douments.push({ image: convertedFile });
    setData((prev) => ({ ...prev, ["images"]: douments, formErrors }));
  };

  const handleFileDelete = (index) => {
    let arr = [...images];
    arr?.splice(index, 1);
    setImages(arr);

    let douments = [...data.images];
    douments?.splice(index, 1);
    setData((prev) => ({ ...prev, ["images"]: douments }));

    if (images.length == 1) {
      setImages([]);
      setFileKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <>
      <CustomForm title="Comments">
        <CustomTextArea
          extraClassName="w-full"
          name="comment"
          data={data}
          onChange={handleChange}
          ignoreLabel
        />
      </CustomForm>
      {isRating && (
        <CustomForm title="Handyman Review">
          <CustomRatings
            name="rating"
            data={data}
            onChange={handleChange}
            ignoreLabel
          />
        </CustomForm>
      )}
      <CustomForm title="Add Photos">
        <CustomFileInput
          data={data}
          selectedFiles={data?.images}
          inputKey={fileKey}
          type="file"
          name="document"
          onChange={handleFileChange}
          required
        />
        {images.length > 0 && (
          <div>
            <h5>Selected files:</h5>
            {images.map((file, index) => (
              <li className="upload-list" key={index}>
                {file?.name
                  ? file?.name
                  : getFileName(file?.image) +
                    "." +
                    getFileExtension(file?.image)}{" "}
                <i
                  onClick={() => handleFileDelete(index)}
                  className="pi pi-times"
                  style={{
                    color: "red",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                ></i>
              </li>
            ))}
          </div>
        )}
      </CustomForm>
    </>
  );
};

export default DamageFeedback;
