import React from "react";

export default function CustomCard({
  children,
  header,
  className,
  classNameBar,
  title,
  col = 12,
}) {
  return (
    <div className={`col-12 md:col-${col} p-2 card-col-12`}>
      <div className={`card ${className}`}>
        <div className={`flex gap-2 justify-content-between flex-wrap ${classNameBar}`}>
          {title ? <div className="title my-auto">{title}</div> : null}
          {header}
        </div>
        {children}
      </div>
    </div>
  );
}

export function CustomCardSecondary({ children, className }) {
  return <div className={`secondary-card p-2 ${className}`}>{children}</div>;
}
