import React from "react";
import { YesNoOptions } from "../../shared/AllDropDownOptions";
import { CustomDropDown, CustomForm } from "../../shared/AllInputs";
import AddBankAccounts from "../../shared/Components/AddBankAccounts";

const BankingDetailForm = ({title, data, handleChange, allBankAccounts, setAllBankAccounts}) => {
  return (
    <>
      <CustomForm title={title}>
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="directDebit"
          options={YesNoOptions}
        />
      </CustomForm>
      <AddBankAccounts
        title={"Bank Accounts"}
        allBankAccounts={allBankAccounts}
        setAllBankAccounts={setAllBankAccounts}
        // errors={bankErrors}
        // setErrors={setBankErrors}
      />
    </>
  );
};

export default BankingDetailForm;
