import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "shared/CustomCard";
import { CustomInput, CustomSearch } from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "views/Tables/CustomTableContainer";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import {
  contractListAction,
  deleteContractAction,
} from "store/actions/contractActions";
import { debounce } from "lodash";
import { convertAmountInGerman } from "utils/commonFunctions";
import { DepositType, MaturityType, RentType } from "shared/AllDropDownOptions";
import { setContractSlice } from "store/slices/contractSlice";
import manageColICon from "assets/images/icons/manage-col-icon.png";
import { canAccess } from "services/auth";

const ContractList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openAction = useRef(null);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  // const [sortField, setSortField] = useState({ field: "", order: "" });
  const [loading, setLoading] = useState(false);
  const contract = useSelector((state) => state?.contract?.contract);
  const globalFilters = [
    "contract_id",
    "maturity_type",
    "rent_rise_type",
    "deposit_type",
  ];
  const [contractList, setContractList] = useState([]);
  const { profile } = useSelector((state) => state.user);
  const [filters, setFilters] = useState({
    contract_id: { value: "" },
    maturity_type: { value: "" },
    rent_rise_type: { value: "" },
    deposit_type: { value: "" },
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const [selectedItems, setSelectedItems] = useState();
  const [columns, setColumns] = useState([]);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex justify-content-end flex-wrap align-items-center">
        <div className="w-8 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.contract_id?.value,
                filters?.maturity_type?.value,
                filters?.rent_rise_type?.value,
                filters?.deposit_type?.value
              );
              setFirst(0);
            }}
            isSearch={isSearch}
            placeholder="Search Contract"
          />
        </div>
        {canAccess("add_contract") && (
          <CustomButton
            size="small"
            icon="pi pi-plus"
            label="Add Contract"
            onClick={() => navigate("/real-estate/property/contract/add")}
          />
        )}
      </div>
    );
  };

  const ContractIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="contractId"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Contract ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const MaturityTypeFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={MaturityType}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Type of Maturity"
          className="p-column-filter"
        />
      </div>
    );
  };

  const RentRiseTypeFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={RentType}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Type of Rent Rise"
          className="p-column-filter"
        />
      </div>
    );
  };

  const DepositTypeFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={DepositType}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Type of Deposit"
          className="p-column-filter"
        />
      </div>
    );
  };

  const columnOptionsInitial = useMemo(
    () => [
      {
        name: "Contract ID",
        accessor: "contract_id",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        disabled: true,
        filterElement: (option) => ContractIDFilter(option),
      },
      {
        name: "Type of maturity",
        accessor: "maturity_type",
        value: true,
        filter: true,
        filterElement: (options) => MaturityTypeFilter(options),
      },
      {
        name: "Landlord",
        accessor: "landlord_name",
        sortable: false,
        value: true,
      },
      {
        name: "No of Property",
        accessor: "property_count",
        sortable: false,
        value: true,
      },
      {
        name: "Amount",
        accessor: "rent_amount",
        sortable: false,
        value: true,
        body: (options) => convertAmountInGerman(options?.rent_amount),
      },
      {
        name: "Size",
        accessor: "size",
        sortable: false,
        value: false,
      },
      {
        name: "Rent Rise Type",
        accessor: "rent_rise_type",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => RentRiseTypeFilter(options),
      },
      {
        name: "Amount First Month",
        accessor: "rent_amount_first_month",
        sortable: false,
        value: false,
      },
      {
        name: "Deposit Type",
        accessor: "deposit_type",
        sortable: false,
        value: true,
        filter: true,
        filterElement: (options) => DepositTypeFilter(options),
      },
    ],
    []
  );

  const [columnOptions, setColumnOptions] = useState(columnOptionsInitial);

  const actions = (d) => {
    return (
      <CustomOverlay extraClass={"text-center"}>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/property/contract/view/${d.id}`);
              dispatch(setContractSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          {canAccess("delete_contract") && (
            <li className="flex gap-2 text-xs font-medium mb-3">
              <i
                className="pi pi-trash cursor-pointer text-dark"
                onClick={() => {
                  setIdToDelete(d?.id);
                  setDeleteModal(true);
                }}
              ></i>{" "}
              Delete
            </li>
          )}
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected contract from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleContractDelete = () => {
    dispatch(
      deleteContractAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getContractList(
            profile?.company,
            rows,
            page,
            search,
            filters?.contract_id?.value,
            filters?.maturity_type?.value,
            filters?.rent_rise_type?.value,
            filters?.deposit_type?.value
          );
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          onClick={(e) => openAction.current.toggle(e)}
        >
          <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i>
        </p>
      </>
    );
  };

  const getContractList = (
    company,
    rows,
    page,
    search,
    // order,
    contractId,
    maturityType,
    rentRiseType,
    depositType
  ) => {
    dispatch(
      contractListAction(
        company,
        rows,
        page,
        search,
        // order,
        contractId,
        maturityType,
        rentRiseType,
        depositType,
        (res) => {
          setContractList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(
            `/real-estate/property/contract/list?page=${page}&rows=${rows}`
          );
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    order,
    contractId,
    maturityType,
    rentRiseType,
    depositType
  ) => {
    setIsSearch(true);
    getContractList(
      company,
      rows,
      page,
      search,
      order,
      contractId,
      maturityType,
      rentRiseType,
      depositType
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getContractList(
        profile?.company,
        rows,
        page,
        search,
        filters?.contract_id?.value,
        filters?.maturity_type?.value,
        filters?.rent_rise_type?.value,
        filters?.deposit_type?.value
      );
    }
  }, [
    profile?.company,
    // rows,
    // page,
    // sortField,
    filters?.contract_id?.value,
    filters?.maturity_type?.value,
    filters?.rent_rise_type?.value,
    filters?.deposit_type?.value,
  ]);

  // const handleSort = (e) => {
  //   if (sortField?.order === "-") {
  //     setSortField({ field: e?.sortField, order: "" });
  //   } else {
  //     setSortField({ field: e?.sortField, order: "-" });
  //   }
  // };

  const onPageChange = (e) => {
    // navigate(
    //   `/real-estate/property/contract/list?page=${e.page + 1}&rows=${e.rows}`
    // );
    getContractList(
      profile?.company,
      e.rows,
      e.page + 1,
      search,
      filters?.contract_id?.value,
      filters?.maturity_type?.value,
      filters?.rent_rise_type?.value,
      filters?.deposit_type?.value
    );
    setFirst(e.first);
  };

  useEffect(() => {
    let filterdCols = columnOptions.filter((col) => col.value === true);
    setColumns(filterdCols);
  }, []);

  return (
    <div>
      <CustomCard title="Contract List" header={header()}>
        {/* <Toast ref={msgs} /> */}
        <CustomTableContainer
          list={contractList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={contract?.id}
          slice={setContractSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          openAction={openAction}
          columnOptions={columnOptions}
          setColumnOptions={setColumnOptions}
          setColumns={setColumns}
          firstCol="Contract ID"
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleContractDelete}
        loading={loading}
      />
    </div>
  );
};

export default ContractList;
