import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getContractAction, getContractPropertiesActions } from "../../../../store/actions/contractActions";
import { getUserInfo } from "../../../../services/auth";
import { setUserProfile } from "../../../../store/slices/userSlice";

const ViewContractContainer = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const { profile } = useSelector((state) => state?.user)
    const [data, setData] = useState()
    const [allMaturities, setAllMaturities] = useState([]);
    const [allExtraCost, setAllExtraCost] = useState([]);
    const [propertyList, setPropertyList] = useState([])
    const { contract } = useSelector((state) => state?.contract)
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        if (!profile?.company) {
            let info = getUserInfo()
            // dispatch(setUserProfile(info))
        }
    }, [])

    useEffect(() => {
        if(params?.page){
            if(params?.page === "rent-and-cost"){
                setActiveIndex(0)
            }else if(params?.page === "maturity"){
                setActiveIndex(1)
            }else if(params?.page === "deposit") {
                setActiveIndex(2)
            }
        }
    }, [params?.page])
    
    useEffect(() => {
        if (params?.id && profile?.company) {
            if (contract) {
                handleContractData(contract)
            } else {
                getContractDetail(params?.id)
            }
            getContractPropertyList(params?.id)
        }
    }, [profile])

    const getContractDetail = (id) => {
        dispatch(getContractAction(profile?.company, id, dispatch, (res) => {
            handleContractData(res)
        }))
    }

    const getContractPropertyList = (id) => {
        dispatch(getContractPropertiesActions(id, (res) => {
            setPropertyList(res)
        }))
    }

    const handleContractData = (info) => {
        setData({
            ...info,
            amount: info?.rent_amount,
            amountFirstMonth: info?.rent_amount_first_month,
            rentRiseType: info?.rent_rise_type,
            riseInterval: info?.rent_rise_interval,
            intervalType: info?.rent_rise_interval_type,
            startDate: info?.date_of_first_rise,
            endDate: info?.date_of_last_rise,
            riseAmount: info?.rent_rise_amount,
            landlord_name: `${info?.landlord_name} (${info?.landlord_id})`
        })
        if (info?.extra_costs?.length) {
            let extraCost = info?.extra_costs?.map((cost) => {
                if (cost?.applicable_tenant_rent === true) {
                    return { ...cost, applicable_tenant_rent: "yes", status: "save" };
                } else if (cost?.applicable_tenant_rent === false) {
                    return { ...cost, applicable_tenant_rent: "no", status: "save" };
                }
            });
            setAllExtraCost(extraCost);
        } else {
            setAllExtraCost([])
        }

        if (info?.optional_maturities?.length) {
            setAllMaturities(info?.optional_maturities)
        }
    }

    const handleTabChange = (index) => {
        setActiveIndex(index)
    }

    return {
        data,
        allMaturities,
        setAllMaturities,
        allExtraCost,
        setAllExtraCost,
        propertyList,
        activeIndex,
        handleTabChange,
    }
}

export default ViewContractContainer