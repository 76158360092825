import api from "services/api";
import endPoints from "services/endPoints";

const checkExistingUserAction = (data, onRes) => async () => {
    const res = await api(
        "post",
        endPoints?.CHECK_EXISTING_USER,
        data
    );
    onRes(res);
};

const getCompanyRolesAction = (data, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.COMPANY_ROLES + `${data}/`
    );
    onRes(res);
};

const addOfficeAction = (company, payload, loading, onRes) => async () => {
    loading(true)
    const res = await api(
        "post",
        endPoints?.ADD_OFFICE + `${company}/`,
        payload
    )
    loading(false)
    onRes(res)
}

const searchOfficeIdAction = (payload, setOfficeIdOptions, onError) => async () => {
    const res = await api(
        "post",
        endPoints?.SEARCH_OFFICE_ID,
        payload
    )

    if(res?.success){
        setOfficeIdOptions([])
    }else{
        // let options = res?.data?.results?.map((option) => {
        //     return { name: option, value: option }
        // })
        setOfficeIdOptions(res?.data?.results || [])
        onError(`${res?.message}!.`)
    }
}

export {
    checkExistingUserAction,
    getCompanyRolesAction,
    addOfficeAction,
    searchOfficeIdAction
};
