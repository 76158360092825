import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    damageReport: { },
    isDamageUpdated: false
}

export const damageSlice = createSlice({
    name: "damage",
    initialState,
    reducers: {
        setDamageReportSlice: (state, action) => {
            state.damageReport = action.payload
        },
        setIsDamageUpdateSlice: (state, action) => {
            state.isDamageUpdated = action.payload
        },
    },

});

export const { setDamageReportSlice, setIsDamageUpdateSlice } = damageSlice.actions;
export default damageSlice.reducer;