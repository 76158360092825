import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import formValidation from "../utils/validations";
import { useDispatch } from "react-redux";
import { showFormErrors } from "../utils/commonFunctions";
import { isAuthenticated } from "../services/auth";
import { onUserSignUpAction } from "../store/actions/userActions";
import { Messages } from "primereact/messages";
import Layout from "./Layout";
import {
  CustomCheckbox,
  CustomForm,
  CustomInput,
  CustomPassword,
} from "../shared/AllInputs";
import CustomButton from "../shared/CustomButton";
import OTPVerification from "./OtpVerification";
import { Toast } from "primereact/toast";

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOtpSent, setIsOtpSent] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    businessEmail: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    terms: false,
  });

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (showFormErrors(data, setData)) {
      dispatch(
        onUserSignUpAction(data, setLoading, navigate, (res) => {
          if (res?.success) {
            setIsOtpSent(true);
            msgs.current.show({
              severity: "success",
              detail: res?.message,
            });
          } else {
            msgs.current.show({
              severity: "error",
              detail: res?.message,
            });
          }
        })
      );
    }
  };

  const msgs = useRef(null);
  return (
    <>
      {isOtpSent ? (
        <OTPVerification action="verify" email={data?.businessEmail} signup />
      ) : (
        <Layout title="Create New Account" signup>
          <CustomForm onSubmit={onSubmit} className="form">
            {/* <Messages className="w-full" ref={msgs} /> */}
            <Toast ref={msgs} />
            <CustomInput
              data={data}
              onChange={handleChange}
              name="businessEmail"
              required
              col="12"
            />
            <CustomInput
              data={data}
              onChange={handleChange}
              name="firstName"
              required
            />
            <CustomInput
              data={data}
              onChange={handleChange}
              name="lastName"
              required
            />
            <CustomPassword
              data={data}
              onChange={handleChange}
              name="password"
              required
              col="12"
            />{" "}
            <CustomPassword
              data={data}
              onChange={handleChange}
              name="confirmPassword"
              required
              col="12"
            />
            <CustomCheckbox
              data={data}
              onChange={handleChange}
              name="terms"
              label={
                <div className="terms inline-block">
                  I've read and accept the{" "}
                  <a className="link" href="/" target="_blank">
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a className="link" href="/" target="_blank">
                    Privacy Policy
                  </a>{" "}
                </div>
              }
              col="12"
            />
            <CustomButton
              onClick={onSubmit}
              loading={loading}
              extraClassNames="w-full my-4"
              label="Sign up"
              disabled={!data.terms}
            />
            <div className="w-full text-center">
              Already have an account?{" "}
              <Link className="link" to="/">
                Login
              </Link>
            </div>
          </CustomForm>
        </Layout>
      )}
    </>
  );
}
