import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const landlordSlice = createSlice({
    name: "landlord",
    initialState,
    reducers: {
        setLandlordSlice: (state, action) => {
            state.landlord = action.payload
        },
    },

});

export const { setLandlordSlice } = landlordSlice.actions;
export default landlordSlice.reducer;