import React from "react";
import { CustomForm } from "shared/AllInputs";
import { CustomViewInput } from "shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "assets/images/icons/editicon.png";
import { canAccess } from "services/auth";
import ViewAddressComponent from "shared/Components/ViewAddressComponent";

const ViewLandlordInfo = ({
  type,
  data,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            type === "property"? navigate(`/real-estate/property/edit/landlord-info/${params?.id}`) : navigate(`/real-estate/landlord/edit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  return (
    <>
      <CustomForm title="Landlord info" header={(canAccess("change_landlord") || canAccess("change_property"))&& header()}>
        <CustomViewInput
          name="existingLandlord"
          value={data?.existingLandlord || data?.landlordId}
          label={"Landlord ID"}
        />
        <CustomViewInput name="landlordName" data={data} />
        <CustomViewInput name="landlordType" data={data} label={"Type"} />
        <ViewAddressComponent data={data} />
        {/* <CustomViewInput name="address" data={data} />
        <CustomViewInput name="street" data={data} />
        <CustomViewInput name="zipCode" data={data} />
        <CustomViewInput name="city" data={data} />
        <CustomViewInput
          name="cityDivision"
          data={data}
        />
        <CustomViewInput name="country" data={data} /> */}
        <CustomViewInput name="email" data={data} />
        <CustomViewInput name="phoneNumber" data={data} label={"Contact"} />
      </CustomForm>
    </>
  );
};

export default ViewLandlordInfo;
