import React from "react";
import { ReactComponent as Logo } from "assets/images/svg/Logo.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logout } from "assets/images/svg/Logout.svg";
import { confirmDialog } from "primereact/confirmdialog";
import { canAccess, logout } from "services/auth";
import { sidebarItems } from "./SidebarRoutes";
import SidebarItem from "./SidebarItem";
import { useDispatch } from "react-redux";
import { getProfileAction } from "store/actions/userActions";

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    confirmDialog({
      message: "Are you sure you want to logout?",
      className: "invitation-modal",
      header: "Confirmation",
      rejectClassName: "primary-button-outlined bg-transparent",
      acceptClassName: "primary-button",
      icon: "pi pi-sign-out",
      defaultFocus: "accept",
      accept: () => {
        logout(() => navigate("/"));
      },
    });
  };

  const checkHaspermission = (codenames) => {
    //remove
    // if(!codenames){
    //   return true
    // }

    const hasPermission = codenames?.some((item) => canAccess(item));
    return hasPermission;
  };

  const getUserInfo = () => {
    dispatch(getProfileAction());
  };

  getUserInfo();

  return (
    <div className="layout-sidebar">
      <div className="logo">
        <Logo />
        <div>IVO</div>
      </div>
      <div className="menu-bar">
        <div className="sidebar-header">
          {sidebarItems.map(
            (item, i) =>
              checkHaspermission(item?.codenames) && (
                <SidebarItem
                  Svg={item?.icon}
                  title={item?.title}
                  items={item?.items}
                  link={item?.link}
                />
              )
          )}
        </div>
        <div className="sidebar-footer">
          <SidebarItem Svg={Logout} title="Logout" onClick={onLogout} />
        </div>
      </div>
    </div>
  );
}

export default React.memo(Sidebar);
