import React, { useEffect, useRef, useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import {
  HandleAddNew,
  handleChangeNew,
  handleDeleteNew,
  handlEdit,
  handleSaveNew,
} from "./commonFunctions";
import CustomOverlay from "../CustomOverlay";
import CustomModal from "../Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllHandymanServiceListAction } from "store/actions/handymanServiceAction";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import CrossIcon from "assets/images/svg/cross-icon.svg";
import { showToastAction } from "store/slices/commonSlice";

const AddService = ({ title, allValues, setAllValues }) => {
  const openAction = useRef(null);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const InputObj = {
    service: "",
    email: "",
    work_phone: "",
    home_phone: "",
  };
  const ignoreInputs = ["status", "id"];
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    if (profile?.company) {
      getServiceList(profile?.company);
    }
  }, [profile]);

  const header = (
    <div className="w-12 flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => HandleAddNew(InputObj, allValues, setAllValues)}
      />
    </div>
  );

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected service from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleServiceDelete = () => {
    handleDeleteNew(allValues, setAllValues, idToDelete?.index);
    setIdToDelete({ id: "", index: "" });
    setDeleteModal(false);
  };

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setServicesList(options);
      })
    );
  };

  return (
    <>
      <CustomForm title={title} header={header}>
        <div className="overflow-auto">
          <table className="financial-table w-full mt-3 table-error-align">
            <thead>
              <tr>
                <th>Service</th>
                <th>Email</th>
                <th>Work Phone</th>
                <th>Home Phone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody id="financial-table-form" className="cell-width">
              {allValues?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <CustomDropDown
                          data={item}
                          onChange={(e) =>
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            )
                          }
                          name="service"
                          col={12}
                          options={servicesList}
                          disabled={item?.status === "save" ? true : false}
                          ignoreLabel
                          errorMessage={item?.formErrors?.type}
                        />
                      </td>
                      <td>
                        <CustomInput
                          disabled={item?.status === "save"}
                          type="text"
                          name="email"
                          extraClassName="w-full"
                          value={item?.email}
                          onChange={(e) => {
                            handleChangeNew(
                              e?.target?.name,
                              e?.target?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            );
                          }}
                          ignoreLabel
                          errorMessage={item?.formErrors?.email}
                        />
                      </td>
                      <td>
                        <CustomPhoneInput
                          disabled={item?.status === "save"}
                          data={item}
                          extraClassName="w-full"
                          onChange={(e) => {
                            handleChangeNew(
                              e?.name,
                              e?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            );
                          }}
                          name="work_phone"
                          required
                          ignoreLabel
                          errorMessage={item?.formErrors?.office_phone}
                        />
                      </td>
                      <td>
                        <CustomPhoneInput
                          disabled={item?.status === "save"}
                          data={item}
                          extraClassName="w-full"
                          onChange={(e) => {
                            handleChangeNew(
                              e?.name,
                              e?.value,
                              index,
                              allValues,
                              setAllValues,
                              ignoreInputs
                            );
                          }}
                          name="home_phone"
                          required
                          ignoreLabel
                          errorMessage={item?.formErrors?.office_phone}
                        />
                      </td>
                      {item?.status === "save" ? (
                        <td>
                          <CustomOverlay ref={openAction}>
                            <ul className="no-style p-0">
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() =>
                                  handlEdit(allValues, setAllValues, index)
                                }
                              >
                                <i className="pi pi-file-edit text-dark"></i>
                                Edit
                              </li>
                              <li
                                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                                onClick={() => {
                                  setIdToDelete({ id: item?.id, index: index });
                                  setDeleteModal(true);
                                }}
                              >
                                <i className="pi pi-trash cursor-pointer text-dark"></i>
                                Delete
                              </li>
                            </ul>
                          </CustomOverlay>
                        </td>
                      ) : (
                        <td>
                          <div className="d-flex gap-3">
                            <a
                              className="cursor-pointer"
                              onClick={() =>
                                handleSaveNew(
                                  index,
                                  allValues,
                                  setAllValues,
                                  ignoreInputs
                                )
                              }
                            >
                              <img src={SaveIcon} />
                            </a>
                            <a
                              className="cursor-pointer"
                              onClick={() => {
                                if (allValues.length > 1) {
                                  handleDeleteNew(
                                    allValues,
                                    setAllValues,
                                    index
                                  );
                                } else {
                                  dispatch(
                                    showToastAction({
                                      type: "error",
                                      detail:
                                        "At least one service is required.",
                                    })
                                  );
                                }
                              }}
                            >
                              <img src={CrossIcon} />
                            </a>
                          </div>
                        </td>
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
      </CustomForm>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleServiceDelete}
        loading={loading}
      />
    </>
  );
};

export default AddService;
