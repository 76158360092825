import React from "react";
import {
  CustomAutoComplete,
  CustomForm,
  CustomInput,
  CustomNumberInput2,
} from "../../shared/AllInputs";
import GooglePlacesInput from "../../shared/GooglePlacesInput";
import AddressComponent from "shared/Components/AddressComponent";
const AddPropertyForm = ({
  type,
  data,
  handleChange,
  propertyIdOptions,
  // propertyId,
  activeIndex,
  setSelectedAddress,
  propertyIdChange,
}) => {
  return (
    <CustomForm title="General Information">
      <CustomAutoComplete
        data={data}
        onChange={handleChange}
        name="propertyId"
        suggestions={propertyIdOptions}
        required
        editable
        disabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
        label={"Property ID"}
        placeholder="Property ID"
        search={(e) => propertyIdChange.current(e.query)}
        max={9}
      />
      <AddressComponent 
        id="property"
        data={data}
        handleChange={handleChange}
        setAddress={setSelectedAddress}
        isDisabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
      />

      {/* <GooglePlacesInput
        id="property"
        data={data}
        onChange={handleChange}
        name="address"
        required
        setAddress={setSelectedAddress}
        disabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="houseNo"
        required
        disabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="street"
        required
        disabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
      />
      <CustomNumberInput2
        data={data}
        onChange={handleChange}
        name="zipCode"
        required
        disabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="city"
        required
        disabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="cityDivision"
        required
        disabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
      />
      <CustomInput
        data={data}
        onChange={handleChange}
        name="country"
        required
        disabled={type === "Edit" ? false : activeIndex !== 0 ? true : false}
      /> */}
    </CustomForm>
  );
};

export default React.memo(AddPropertyForm);
