import React, { useEffect, useState } from "react";
import {
  CustomDropDown,
  CustomForm,
  CustomInput,
  CustomPhoneInput,
  CustomRatings,
  CustomTextArea,
} from "shared/AllInputs";
import CustomButton from "shared/CustomButton";
import CustomModal from "shared/Modals/CustomModal";
import {
  changeServiceStatusActon,
  deleteHandymanServiceActon,
  handleAddServiceActon,
  handleUpdateServiceActon,
} from "store/actions/HandymanActions";
import { useDispatch, useSelector } from "react-redux";
import CustomOverlay from "shared/CustomOverlay";
import { getAllHandymanServiceListAction } from "store/actions/handymanServiceAction";
import formValidation from "utils/validations";
import { useParams } from "react-router-dom";
import CommonViewTable from "./CommonViewTable";
import { showToastAction } from "store/slices/commonSlice";
import { showFormErrors } from "utils/commonFunctions";
const CustomHandymanServiceTable = ({ services }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [isServiceOpen, setIsServiceOpen] = useState({
    isOpen: false,
    action: "",
  });
  const [data, setData] = useState({
    service: "",
    email: "",
    work_phone: "",
    home_phone: "",
    status: "",
  });
  const [serviceStatusModal, setServiceStatusModal] = useState({
    isOpen: false,
    action: "",
  });
  const [loading, setLoading] = useState(false);
  const [servicesList, setServicesList] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [reason, setReason] = useState("");
  const { profile } = useSelector((state) => state.user);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  useEffect(() => {
    if (profile?.company) {
      getServiceList(profile?.company);
    }
  }, [profile]);

  const header = (
    <div className="w-12  flex justify-content-end flex-wrap">
      <CustomButton
        type="button"
        label="Add"
        onClick={() => setIsServiceOpen({ isOpen: true, action: "Add" })}
      />
    </div>
  );

  const serviceModalContent = () => {
    return (
      <div>
        <CustomDropDown
          data={data}
          onChange={handleChange}
          name="service"
          col={12}
          options={servicesList}
          label={"Select Service"}
          required
        />
        <CustomInput
          data={data}
          type="text"
          name="email"
          extraClassName="w-full"
          onChange={handleChange}
          required
        />
        <CustomPhoneInput
          data={data}
          extraClassName="w-full"
          onChange={handleChange}
          name="work_phone"
          required
        />
        <CustomPhoneInput
          data={data}
          extraClassName="w-full"
          onChange={handleChange}
          name="home_phone"
          required
        />
      </div>
    );
  };

  const handleChange = ({ name, value }) => {
    const formErrors = formValidation(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleAddService = () => {
    if (showFormErrors(data, setData, ["status"])) {
      if (data?.id) {
        dispatch(
          handleUpdateServiceActon(
            params.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              setIsServiceOpen({ isOpen: false, action: "" });
              setData({
                service: "",
                email: "",
                work_phone: "",
                home_phone: "",
                status: "",
              });
            }
          )
        );
      } else {
        dispatch(
          handleAddServiceActon(
            params.id,
            data,
            setLoading,
            dispatch,
            (res) => {
              setIsServiceOpen({ isOpen: false, action: "" });
              setData({
                service: "",
                email: "",
                work_phone: "",
                home_phone: "",
                status: "",
              });
            }
          )
        );
      }
    }
  };

  const actions = (d) => {
    return (
      <>
        <CustomOverlay>
          <ul className="no-style p-0">
            <li className="flex gap-2 text-xs font-medium mb-3 cursor-pointer">
              <i
                className="pi pi-file-edit text-dark"
                onClick={() => {
                  setIsServiceOpen({ isOpen: true, action: "Edit" });
                  setData({
                    id: d?.id,
                    service: d?.service?.id,
                    email: d?.email,
                    work_phone: d?.work_phone,
                    home_phone: d?.home_phone,
                    status: d?.status,
                  });
                }}
              ></i>
              Edit
            </li>
            {d?.status === "Unblocked" ? (
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setServiceStatusModal({
                    isOpen: true,
                    action: d?.status === "Unblocked" ? "Block" : "Unblock",
                  });
                  setSelectedService(d?.id);
                }}
              >
                <i className="pi pi-ban text-dark"></i>
                Block
              </li>
            ) : (
              <li
                className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                onClick={() => {
                  setServiceStatusModal({
                    isOpen: true,
                    action: d?.status === "Blocked" ? "Unblock" : "Block",
                  });
                  setSelectedService(d?.id);
                }}
              >
                <i className="pi pi-ban text-dark"></i>
                Unblock
              </li>
            )}
            <li
              className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
              onClick={() => {
                if (services?.length === 1) {
                  dispatch(
                    showToastAction({
                      type: "error",
                      detail: "At least one service is required.",
                    })
                  );
                } else {
                  setIsDeleteModal(true);
                  setSelectedService(d?.id);
                }
              }}
            >
              <i className="pi pi-trash text-dark"></i> Delete
            </li>
          </ul>
        </CustomOverlay>
      </>
    );
  };

  const getServiceList = () => {
    dispatch(
      getAllHandymanServiceListAction(profile?.company, (res) => {
        const options = res?.options?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setServicesList(options);
      })
    );
  };

  const modalContent = () => {
    return (
      <>
        <span>{`Are you sure you want to ${serviceStatusModal?.action} user?`}</span>
        {serviceStatusModal?.action === "Block" && (
          <CustomTextArea
            extraClassName={"w-full"}
            value={reason}
            onChange={(e) => setReason(e?.target?.value)}
            name="reason"
            label="Write a reason for the block?"
          />
        )}
      </>
    );
  };

  const deleteModalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected service from the list? This
        action cannot be undone.
      </span>
    );
  };

  const handleChangeServiceStatus = () => {
    dispatch(
      changeServiceStatusActon(
        selectedService,
        serviceStatusModal?.action,
        reason,
        setLoading,
        dispatch,
        (res) => {
          setReason("");
          setServiceStatusModal({ isOpen: false, action: "" });
        }
      )
    );
  };

  const handleDeleteService = () => {
    dispatch(
      deleteHandymanServiceActon(
        selectedService,
        setLoading,
        setIsDeleteModal,
        setSelectedService,
        dispatch,
        (res) => {}
      )
    );
  };

  const onCancel = () => {
    setData({
      service: "",
      email: "",
      work_phone: "",
      home_phone: "",
      status: "",
    });
  };

  return (
    <>
      <CustomForm header={header} col={12}>
        {services?.length ? (
          services?.map((service, index) => {
            return (
              <div className="p-4 w-full bg-primary-light border-round-xl grid mt-3">
                <div className="col-1">{index + 1}</div>
                <div className="col-4">
                  <h4 className="mb-2 capitalize">{service?.service?.name}</h4>
                  <p className="mb-2">
                    <span className="font-bold">Email : </span> {service?.email}
                  </p>
                  <p className="mb-2">
                    <span className="font-bold">Home phone : </span>{" "}
                    {service?.home_phone}
                  </p>
                  {service?.reason && (
                    <p className="mb-2">
                      <span className="font-bold">Reason for Block : </span>{" "}
                      {service?.reason}
                    </p>
                  )}
                </div>
                <div className="col-6">
                  <div className="flex gap-3 mb-2">
                    <CustomRatings
                      value={service?.rating?.average_rating}
                      name="average_rating"
                      ignoreLabel
                      disabled
                      cancel={false}
                    />{" "}
                    ({service?.rating?.count})
                  </div>
                  <div className="flex gap-3 mb-2">
                    <p>
                      <span className="font-bold">Work phone : </span>{" "}
                      {service?.work_phone}
                    </p>
                  </div>
                  {service?.blocked_date && (
                    <div className="flex gap-3 mb-2">
                      <p>
                        <span className="font-bold">Block Date : </span>{" "}
                        {service?.blocked_date}
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-1">{actions(service)}</div>
              </div>
            );
          })
        ) : (
          <CommonViewTable title={""} list={[]} />
        )}
      </CustomForm>
      <CustomModal
        isOpen={isServiceOpen?.isOpen}
        setIsOpen={setIsServiceOpen}
        heading={`${isServiceOpen?.action} Service`}
        body={serviceModalContent}
        submitLabel={"Save"}
        onSubmit={handleAddService}
        loading={loading}
        onCancel={onCancel}
      />
      <CustomModal
        isOpen={serviceStatusModal?.isOpen}
        setIsOpen={setServiceStatusModal}
        heading={`${serviceStatusModal?.action}`}
        body={modalContent}
        submitLabel={serviceStatusModal?.action}
        onSubmit={handleChangeServiceStatus}
        loading={loading}
      />
      <CustomModal
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        heading={"Delete Service"}
        body={deleteModalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteService}
        loading={loading}
      />
    </>
  );
};

export default CustomHandymanServiceTable;
