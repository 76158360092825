import React from "react";
import { Dialog } from "primereact/dialog";
import CustomButton from "../CustomButton";
import HeatingIcon from "assets/images/icons/heating-icon.png";
import MaintenanceIcon from "assets/images/icons/maintenance-icon.png";

const AddDocumentModal = ({
  visible,
  setVisible,
  documentType,
  setDocumentType,
  addedDocument,
}) => {
  const documentTypes = [
    { name: "Maintenance", value: "Maintenance" },
    { name: "Heating", value: "Heating" },
    { name: "Combined", value: "Combined" },
  ];

  const handleAdd = () => {
    if (documentType.type) {
      setDocumentType((prev) => ({ ...prev, ["isAdd"]: true, action: "Add" }));
      setVisible(!visible);
    }
  };

  const footerContent = (
    <div className="modal-footer border-0 flex justify-content-center pt-4">
      <CustomButton
        disabled={!documentType}
        onClick={handleAdd}
        type="button"
        label="Add"
        className="btn primary-button fs-6 p-2 text-white px-5"
      />
    </div>
  );

  return (
    <>
      <Dialog
        className="invitation-modal"
        header="Add Document"
        footer={footerContent}
        visible={visible}
        onHide={() => {
          setDocumentType("");
          setVisible(!visible);
        }}
      >
        <p className="text-black fw-medium text-center flex gap-1 justify-content-center align-items-center">
          <span className="font-bold">Select type of Document</span>
        </p>
        <div className="flex mt-3 justify-content-center gap-3">
          {documentTypes?.map((type, index) => {
            if (type.name === "Maintenance") {
              return (
                <div
                  key={index}
                  onClick={() => {
                    addedDocument !== "Maintenance" &&
                      setDocumentType({ type: type.value });
                  }}
                >
                  <div
                    className={`profile-role ${
                      documentType?.type === type.value ? "active-role" : ""
                    }`}
                  >
                    <img
                      src={MaintenanceIcon}
                      alt=""
                      style={{ width: "70%" }}
                    />
                  </div>
                  <p
                    className={`text-center firstLetterCapital ${
                      documentType?.type === type.value ? "text-primary" : ""
                    }`}
                  >
                    {type.name}
                  </p>
                </div>
              );
            } else if (type.name === "Heating") {
              return (
                <div
                  key={index}
                  onClick={() => {
                    addedDocument !== "Heating" &&
                      setDocumentType({ type: type.value });
                  }}
                >
                  <div
                    className={`profile-role ${
                      documentType?.type === type.value ? "active-role" : ""
                    } `}
                  >
                    <img src={HeatingIcon} alt="" style={{ width: "40%" }} />
                  </div>
                  <p
                    className={`text-center firstLetterCapital ${
                      documentType?.type === type.value ? "text-primary" : ""
                    }`}
                  >
                    {type.name}
                  </p>
                </div>
              );
            } else if (type.name === "Combined") {
              return (
                <div
                  key={index}
                  onClick={() => {
                    !(
                      addedDocument === "Maintenance" ||
                      addedDocument === "Heating"
                    ) && setDocumentType({ type: type.value });
                  }}
                >
                  <div
                    className={`profile-role ${
                      documentType?.type === type.value ? "active-role" : ""
                    }`}
                  >
                    <img
                      src={MaintenanceIcon}
                      alt=""
                      style={{ width: "40%" }}
                    />
                    <i className="pi pi-plus"></i>
                    <img src={HeatingIcon} alt="" style={{ width: "20%" }} />
                  </div>
                  <p
                    className={`text-center firstLetterCapital ${
                      documentType?.type === type.value ? "text-primary" : ""
                    }`}
                  >
                    {type.name}
                  </p>
                </div>
              );
            }
          })}
        </div>
      </Dialog>
    </>
  );
};

export default AddDocumentModal;
