import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import CustomButton, {
  CustomButtonOutlined,
} from "shared/CustomButton";
import CustomCard from "shared/CustomCard";
import { CustomDropDown, CustomInput, CustomSearch } from "shared/AllInputs";
import { Tooltip } from "primereact/tooltip";
import { truncateText } from "utils/regex";
import { BuildingStatus } from "shared/AllDropDownOptions";
import { propertiesListActionModal } from "store/actions/propertyActions";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { getUserInfo } from "services/auth";
import { useParams } from "react-router-dom";
import CustomTableContainer from "views/Tables/CustomTableContainer";

const PropertyTableModal = ({
  isOpen,
  setIsOpen,
  heading,
  cancelLabel,
  submitLabel,
  loading,
  onSubmit,
  onCancel,
  selectedProperty,
  setSelectedProperty,
}) => {
  const params = useParams()
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const globalFilters = ["property_id", "contract_id", "address", "status"];
  // const [filters, setFilters] = useState({
  //   property_id: { value: "" },
  //   contractId: { value: "" },
  //   address: { value: "" },
  //   status: { value: "" },
  // });

  const [filters, setFilters] = useState({
    property_id: "",
    contract_id: "",
    address: "",
    status: "",
  });

  useEffect(() => {
    if(params?.propertyId && propertyList){
      const property = propertyList.find((item) => item.id == params?.propertyId)
      if(property){
        setSelectedProperty(property)
      }
    }

  },[params?.propertyId, propertyList])

  useEffect(() => {
    if (!profile.company) {
      let info = getUserInfo();
      // dispatch(setUserProfile(info));
    }
  }, [profile]);

  const footerContent = (
    <div className="modal-footer mt-2 border-0 gap-4 flex justify-content-center pb-4">
      {onSubmit && (
        <CustomButton
          type="button"
          loading={loading}
          label={submitLabel || "Send"}
          onClick={onSubmit}
          className="btn primary-button fs-6 p-2 text-white px-5"
        />
      )}
      <CustomButtonOutlined
        disabled={loading}
        type="button"
        label={cancelLabel || "Cancel"}
        className="btn m-0 fs-6 p-2 px-5 primary-button-outlined bg-transparent "
        onClick={() => {
          setIsOpen(!isOpen);
          onCancel && onCancel();
        }}
      />
    </div>
  );

  const header = () => {
    return (
      <div className="w-12 md:w-8 flex align-items-center justify-content-end flex-wrap align-items-center">
        <div className="w-8 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.property_id,
                filters?.contract_id,
                filters?.address,
                filters?.status
              );
            }}
            isSearch={isSearch}
            placeholder="Search Property"
          />
        </div>
      </div>
    );
  };

  const PropertyIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          data={filters}
          name="property_id"
          onChange={handleFilterChange}
          placeholder="Property ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const ContractIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          data={filters}
          name="contract_id"
          onChange={handleFilterChange}
          placeholder="Contract ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const AddressFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          data={filters}
          name="address"
          onChange={handleFilterChange}
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const addressBodyTemplate = (options) => {
    const tooltipId = `address-tooltip-${options?.id}`;
    return (
      <div>
        <Tooltip
          className="tooltip"
          target={`.${tooltipId}`}
          content={options?.address}
        />
        <span
          className={tooltipId}
          data-pr-classname="tooltip"
          style={{ cursor: "pointer" }}
        >
          <span
          // style={{
          //   color: options?.id === handyman?.id ? "white" : null,
          // }}
          >
            {truncateText(options?.address, 20)}
          </span>
        </span>
      </div>
    );
  };

  const StatusFilter = (options) => {
    return (
      <div>
        <CustomDropDown
          col={12}
          data={filters}
          options={BuildingStatus}
          onChange={handleFilterChange}
          name="status"
          optionLabel="name"
          placeholder="Status"
          className="p-column-filter"
        />
      </div>
    );
  };

  const statusBodyTemplate = (options) => {
    return (
      <span className={getStatusClass(options?.status)}>{options?.status}</span>
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Vacant":
      case "Fully":
        return "text-yellow-500";

      case "Expiring":
      case "Moving Out":
      case "Pending":
        return "text-red-600";

      case "Occupied":
      case "Partially":
        return "text-green-600";

      default:
        return "";
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Property ID",
        accessor: "property_id",
        value: true,
        filter: true,
        filterElement: (option) => PropertyIDFilter(option),
      },
      // {
      //   name: "Contract ID",
      //   accessor: "contract_id",
      //   value: true,
      //   filter: true,
      //   filterElement: (option) => ContractIDFilter(option),
      // },
      {
        name: "Address",
        accessor: "address",
        value: true,
        filter: true,
        body: (options) => addressBodyTemplate(options),
        filterElement: (option) => AddressFilter(option),
      },
      {
        name: "Status",
        accessor: "status",
        value: true,
        filter: true,
        body: (options) => statusBodyTemplate(options),
        filterElement: (options) => StatusFilter(options),
      },
    ],
    [filters]
  );

  const onPageChange = (e) => {
    setPage(e.page + 1);
    setRows(e.rows);
    setFirst(e.first);
  };

  const getPropertiesList = (
    company,
    rows,
    page,
    search,
    propertyId,
    contractId,
    address,
    status
  ) => {
    dispatch(
      propertiesListActionModal(
        company,
        rows,
        page,
        search,
        propertyId,
        contractId,
        address,
        status,
        (res) => {
         let options = res?.options?.map((option) =>{
            return {
              ...option,
              contract_id: option?.contract?.contract_id || ""
            }
          })
          setPropertyList(options);
          setCount(res?.count);
          setIsSearch(false);
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    propertyId,
    contractId,
    address,
    status
  ) => {
    setIsSearch(true);
    getPropertiesList(
      company,
      rows,
      page,
      search,
      propertyId,
      contractId,
      address,
      status
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getPropertiesList(
        profile?.company,
        rows,
        page,
        "",
        filters?.property_id,
        filters?.contract_id,
        filters?.address,
        filters?.status
      );
    }
  }, [
    rows,
    page,
    first,
    profile?.company,
  ]);

  const applyFilters = (options) => {
    getPropertiesList(
      profile?.company,
      rows,
      page,
      "",
      filters?.property_id,
      filters?.contract_id,
      filters?.address,
      filters?.status
    );
  };

  const clearFilter = (options) => {
    const field = options.field;
    setFilters((prev) => ({ ...prev, [field]: "" }));
    getPropertiesList(
      profile?.company,
      rows,
      page,
      "",
      field === "property_id" ? "" : filters?.property_id,
      field === "contract_id" ? "" : filters?.contract_id,
      field === "address" ? "" : filters?.address,
      field === "status" ? "" : filters?.status
    );
  };

  const handleFilterChange = ({name, value}) => {
    setFilters((prev) => ({...prev, [name]: value}))
  }

  return (
    <Dialog
      visible={isOpen}
      className="invitation-modal"
      header={heading}
      footer={footerContent}
      style={{ width: "70vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => {
        !loading && setIsOpen(false);
        onCancel && onCancel();
      }}
    >
      <CustomCard title=" " header={header()}>
        <CustomTableContainer
          list={propertyList}
          columns={columns}
          actions={""}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={""}
          activeRow={""}
          slice={""}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          selectionMode={"single"}
          selectedItems={selectedProperty}
          setSelectedItems={setSelectedProperty}
          onApplyFilter={applyFilters}
          onClearFilter={clearFilter}
        />
      </CustomCard>
    </Dialog>
  );
};

export default PropertyTableModal;
