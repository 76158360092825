import React, { useEffect, useState } from "react";
import formValidation from "utils/validations";
import { showFormErrors } from "utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getOfficeListAction } from "store/actions/officeActions";
import { getUserInfo } from "services/auth";
import { useNavigate, useParams } from "react-router-dom";
import { getEmployeesListAction } from "store/actions/EmployeeActions";
import { addOfficeUnitAction, getOfficeUnitInfoAction, updateOfficeUnitAction } from "store/actions/officeUnitActions";

const OfficeUnitContainer = () => {
    const params = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { profile } = useSelector((state) => state.user);
    const [data, setData] = useState({
        unitName: "",
        selectOffice: [],
        costCenter: "",
        email: "",
        phoneNumber: "",
        unitHeadOne: "",
        unitHeadTwo: "",
        unitCoHead: "",
    });
    const [officeList, setOfficeList] = useState([]);
    const [employeesList, setEmployeesList] = useState([]);
    const [userProfile, setUserProfile] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let info = "";
        const userInfo = getUserInfo();
        profile?.firstName ? (info = profile) : (info = userInfo);
        setUserProfile(info);
        getOfficeList(info?.company);
        getEmployeesList(info?.company);

        if (params?.id && info?.company) {
            getUnitInfo(info?.company, params?.id)
        }
    }, [profile]);

    const getOfficeList = (company) => {
        dispatch(
            getOfficeListAction(company, (res) => {
                if (res?.success) {
                    let options = res?.data.results.map((office) => {
                        return { name: office?.name, value: office?.id };
                    });
                    setOfficeList(options);
                }
            })
        );
    };

    const getEmployeesList = (company) => {
        dispatch(
            getEmployeesListAction(company, (res) => {
                if (res?.success) {
                    let options = res?.data?.results?.map((employee) => {
                        return {
                            name: `${employee.user?.first_name} ${employee.user?.last_name}`,
                            value: employee?.id,
                        };
                    });
                    setEmployeesList(options);
                }
            })
        );
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (showFormErrors(data, setData)) {
            let payload = {
                name: data?.unitName,
                email: data?.email,
                phone_number: data?.phoneNumber,
                cost_center: data?.costCenter,
                head_1: data?.unitHeadOne,
                head_2: data?.unitHeadTwo,
                head_3: data?.unitCoHead,
            };

            if (data?.selectOffice.length) {
                payload.offices = data?.selectOffice;
            }

            if (params?.id) {
                dispatch(
                    updateOfficeUnitAction(
                        userProfile?.company,
                        params?.id,
                        payload,
                        setLoading,
                        navigate,
                        dispatch
                    )
                );
            } else {
                dispatch(
                    addOfficeUnitAction(
                        userProfile?.company,
                        payload,
                        setLoading,
                        navigate,
                        dispatch
                    )
                );
            }
        }
    };

    const getUnitInfo = (company) => {
        dispatch(
            getOfficeUnitInfoAction(company, params?.id, dispatch, (res) => {
                    let offices = res?.offices?.map((office) => {
                        return office?.id
                    })
                    setData({
                        unitName: res?.name,
                        selectOffice: offices,
                        costCenter: res?.cost_center,
                        email: res?.email,
                        phoneNumber: res?.phone_number,
                        unitHeadOne: res?.head_1?.id,
                        unitHeadTwo: res?.head_2?.id,
                        unitCoHead: res?.head_3?.id,
                    })
            })
        );
    };

    return {
        data,
        handleChange,
        onSubmit,
        loading,
        officeList,
        employeesList,
        navigate
    }
}

export default OfficeUnitContainer