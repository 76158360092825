import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import { CustomViewInput } from "../../shared/AllViewInputs";
import CustomBankAccountTable from "../Tables/CustomBankAccountTable";

const ViewBankDetails = ({type, data, allBankAccounts, setAllBankAccounts }) => {
  return (
    <>
      <CustomForm title="Banking Details">
        <CustomViewInput name="directDebit" data={data} />
      </CustomForm>
      <CustomBankAccountTable
        action={type}
        id={data?.id}
        title="Bank Accounts"
        allBankAccounts={allBankAccounts}
        setAllBankAccounts={setAllBankAccounts}
      />
    </>
  );
};

export default ViewBankDetails;
