import React from "react";
import MaintenanceForms from "./MaintenanceForm";

const ViewMaintenance = () => {

    return(
        <MaintenanceForms type="View"/>
    )

}

export default ViewMaintenance