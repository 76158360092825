import React, { useEffect, useState } from "react";
import { CustomDropDown, CustomForm } from "shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "utils/validations";
import CustomOverlay from "shared/CustomOverlay";
import CustomModal from "shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { handleDelete } from "shared/Components/commonFunctions";
import { PositionOptions } from "shared/PositionsList";
import { getEmployeesListAction } from "store/actions/EmployeeActions";
import SaveIcon from "assets/images/svg/Save-icon.svg";
import CrossIcon from "assets/images/svg/cross-icon.svg";

export default function OfficeEmployeeTable({
  title,
  allValues,
  setAllValues,
  onUpdate,
  onDelete,
}) {
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const { profile } = useSelector((state) => state?.user);
  const [employeesList, setEmployeesList] = useState([]);

  useEffect(() => {
    if (profile?.company) {
      getEmployeesList(profile?.company);
    }
  }, [profile]);

  const PositionEditor = (options) => {
    const formErrors = formValidation("position", options.value, allValues);
    return (
      <CustomDropDown
        value={options?.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="position"
        col={12}
        options={PositionOptions}
        optionLabel={"name"}
        ignoreLabel
        errorMessage={formErrors["position"]}
      />
    );
  };

  const EmployeeEditor = (options) => {
    const formErrors = formValidation(
      "employee",
      options.value?.value || options.value,
      allValues
    );
    return (
      <CustomDropDown
        value={options.value?.value || options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="employee"
        col={12}
        options={employeesList}
        ignoreLabel
        errorMessage={formErrors["employee"]}
      />
    );
  };

  const getEmployeesList = (company) => {
    dispatch(
      getEmployeesListAction(company, (res) => {
        if (res?.success) {
          let options = res?.data.results?.map((employee) => {
            return {
              value: employee?.id,
              name: `${employee?.user?.first_name} ${employee?.user?.last_name}`,
            };
          });
          setEmployeesList(options);
        }
      })
    );
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              className="cursor-pointer"
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <img src={SaveIcon} />
            </a>
            <a
              className="cursor-pointer"
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <img src={CrossIcon} />
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li className="flex gap-2 text-xs font-medium mb-3">
                  <i
                    className="pi pi-trash cursor-pointer text-dark"
                    onClick={() => {
                      setIdToDelete({
                        id: rowData?.id,
                        index: options?.rowIndex,
                      });
                      setDeleteModal(true);
                    }}
                  ></i>{" "}
                  Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const rowEditValidator = async (rowData, options) => {
    const { id, employee, position } = rowData;
    const positionError = formValidation("position", position, allValues);
    const employeeError = formValidation("employee", employee, allValues);
    if (!positionError["position"] && !employeeError["employeeError"]) {
      let payload = {
        employee: employee?.value || employee,
        position: position,
      };
      onUpdate(id, payload, (res) => {
        if (res.success) {
            const info = res?.data?.results
            let data = {
                employee: {name: info?.employee?.name, value: info?.employee?.id},
                id: info?.id,
                position: info?.position,
                role: {name: info?.employee?.role, value: info?.employee?.role_id}
            }
          let values = [...allValues];
          values[options.rowIndex] = data;
          setAllValues(values);
          setEditingRows({});
        }
      });
    } else {
      return false;
    }
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const handleDeleteItem = () => {
    onDelete(idToDelete?.id, setLoading, (res) => {
      if (res.success) {
        handleDelete(allValues, setAllValues, "", "", idToDelete?.index);
      } 
      setIdToDelete({ id: "", index: "" });
      setDeleteModal(false);
    });
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected employee from the list?
        This action cannot be undone.
      </span>
    );
  };

  return (
    <CustomForm title={title}>
      <DataTable
        className="col-12 properties-table-list table-error-align"
        value={allValues}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="role"
          header="Select Role"
          body={(option) => editingRows[option?.id]? "---" : option?.role?.name}
          className="firstletter_capital"
        ></Column>
        <Column
          headerClassName="custom-header"
          field="position"
          header="Select Position"
          editor={(options) => PositionEditor(options)}
          className="firstletter_capital"
          body={(option) => PositionOptions.find(item => item.value === option?.position)?.name ?? null}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="employee"
          header="Select Employee"
          body={(option) => option?.employee?.name}
          editor={(options) => EmployeeEditor(options)}
          className="firstletter_capital"
        ></Column>
        <Column
          headerClassName="custom-header"
          header="Action"
          rowEditor
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteItem}
        loading={loading}
      />
    </CustomForm>
  );
}
