import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bankAccounts: []
}

export const bankAccountsSlice = createSlice({
    name: "bankAccounts",
    initialState,
    reducers: {
        setBankAccounts: (state, action) => {
            state.bankAccounts = action.payload
        },
    },

});

export const { setBankAccounts } = bankAccountsSlice.actions;
export default bankAccountsSlice.reducer;