import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { showToastAction } from "../slices/commonSlice";
import { setPropertySlice } from "../slices/propertySlice";

const addBuildingUnitAction = (data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        // name: buildingUnitData?.name,
        floor: data?.floor,
        location: data?.location,
        water_heating: data?.waterHeating,
        unit_type: data?.unitType,
        heating_technology: data?.heatingTechnology,
        unit_size: data?.size,
        property: data?.propertyId,
    }

    if (data?.rooms?.length) {
        payload.rooms = data?.rooms
    }

    if (data?.energy_meters?.length) {
        payload.energy_meters = data?.energy_meters
    }

    const res = await api(
        "post",
        endPoints?.BUILDING_UNIT,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results;
        onRes(info)
        dispatch(setPropertySlice(info))
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
};

const getBuildingUnitAction = (propertyId, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY_BUILDING_UNIT + `/${propertyId}/`,
    );
    onRes(res)
};

// const searchBuildingAction = (id, onRes) => async () => {
//     const res = await api(
//         "get",
//         endPoints?.GET_BUILDING_BYID + `?building_id=${id}`,
//     )
//     onRes(res)
// }

const updateBuildingUnitAction = (unitId, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        // name: buildingUnitData?.name,
        id: unitId,
        floor: data?.floor,
        location: data?.location,
        water_heating: data?.waterHeating,
        unit_type: data?.unitType,
        heating_technology: data?.heatingTechnology,
        unit_size: data?.size,
        property: data?.propertyId,
    }

    if (data?.rooms?.length) {
        payload.rooms = data?.rooms
    }

    if (data?.energy_meters?.length) {
        payload.energy_meters = data?.energy_meters
    }

    const res = await api(
        "patch",
        endPoints?.BUILDING_UNIT + `${unitId}/`,
        data
    );
    if (res?.success) {
        const info = res?.data?.results;
        onRes(info)
        dispatch(setPropertySlice(info))
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
};

const getEnergyClocksListAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.ENERGY_CLOCK + `?energy_meter_id=${id}`,
    );
    onRes(res)
}

const addEnergyClockAction = (data, onRes) => async () => {
    const res = await api(
        "post",
        endPoints?.ENERGY_CLOCK,
        data
    );
    onRes(res)
}

const updateEnergyClockAction = (id, data, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.ENERGY_CLOCK + `${id}/`,
        data
    );
    onRes(res)
}

const deleteEnergyClockAction = (id, onRes) => async () => {
    const res = await api(
        "delete",
        endPoints?.ENERGY_CLOCK + `${id}/`,
    );
    onRes(res)
}

const addEnergyMeterAction = (data, dispatch, onRes) => async () => {
    const res = await api(
        "post",
        endPoints?.ENERGY_METER,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res?.data?.results)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const getEnergyMeterAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.ENERGY_METER + `${id}/`,
    );
    onRes(res)
}

const updateEnergyMeterAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "put",
        endPoints?.ENERGY_METER + `${id}/`,
        data
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    onRes(res)

}

const deleteEnergyMeterAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.ENERGY_METER + `${id}/`,
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
}

const getRoomAction = (id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.UNIT_ROOM + `${id}/`,
    );
    onRes(res)
}

const updateRoomAction = (id, data, dispatch, onRes) => async () => {
    const res = await api(
        "patch",
        endPoints?.UNIT_ROOM + `${id}/`,
        data
    );

    if (res?.success) {
        onRes(res)
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
}

const deleteRoomAction = (id, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.UNIT_ROOM + `${id}/`,
    );
    if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
    }
    setLoading(false)

}

export {
    addBuildingUnitAction,
    getBuildingUnitAction,
    updateBuildingUnitAction,

    //room
    updateRoomAction,
    getRoomAction,
    deleteRoomAction,

    //energy meter
    addEnergyMeterAction,
    getEnergyMeterAction,
    updateEnergyMeterAction,
    deleteEnergyMeterAction,

    //energy clock
    addEnergyClockAction,
    updateEnergyClockAction,
    deleteEnergyClockAction,
    getEnergyClocksListAction,

    // searchBuildingAction
}