import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "primereact/avatar";
import { useSelector, useDispatch } from "react-redux";
import { getImageUrl } from "../utils/commonFunctions";
import { ReactComponent as Home } from "../assets/images/svg/HomeFilled.svg";
// import { ReactComponent as Logo } from "../assets/images/svg/logo1.svg";
import { getUserInfo, logout } from "../services/auth";
import { setUserProfile } from "store/slices/userSlice";
import { OverlayPanel } from "primereact/overlaypanel";
import { Link, useNavigate } from "react-router-dom";
import  Logoutblack  from "assets/images/svg/Logoutblack.svg";
import { confirmDialog } from "primereact/confirmdialog";

export default function Topbar({ toggleSidebar }) {
  const dispatch = useDispatch();
  // const [isUserOnBoarding, setIsUserOnBoarding] = useState(true);
  const { profile } = useSelector((state) => state.user);
  const navigate = useNavigate()
  useEffect(() => {
    if (!profile?.id) {
      let user = getUserInfo()
      dispatch(setUserProfile(user))
    }
  }, [profile])

  // useEffect(() => {
  //   setIsUserOnBoarding(profile?.is_onboarding);
  // }, [profile]);

  const getFirstCharacter = (name = "") => {
    let words = name.split(" ");
    let firstLetters = words.map((word) => word?.charAt(0).toUpperCase());
    firstLetters = firstLetters.slice(0, 2);
    return firstLetters;
  };

  const op = useRef(null);

  const onLogout = () => {
    confirmDialog({
      message: "Are you sure you want to logout?",
      className: "invitation-modal",
      header: "Confirmation",
      rejectClassName: "primary-button-outlined bg-transparent",
      acceptClassName: "primary-button",
      icon: "pi pi-sign-out",
      defaultFocus: "accept",
      accept: () => {
        logout(() => navigate("/"));
      },
    });
  };
  return (
    <div className="layout-topbar px-4">
      <div className="flex">
        {/* {isUserOnBoarding ? (
          <Logo />
        ) : ( */}
          <>
            <div className="my-auto cursor-pointer" onClick={toggleSidebar}>
              <i className="pi pi-bars text-2xl"></i>
            </div>
            <div className="flex my-auto ml-3">
              <Home className="my-auto" />
              <div className="page-title ml-1">Real Estate</div>
            </div>
          </>
        {/* )} */}
      </div>

      <div className="top-menu">
        {/* {!isUserOnBoarding && ( */}
          <div className="my-auto mr-4">
            <i className="pi pi-bell text-xl" />
          </div>
        {/* )} */}

        <div className="flex cursor-pointer" onClick={(e) => op.current.toggle(e)}>
          {profile?.image ? (
            <Avatar
              image={getImageUrl(profile?.image)}
              shape="circle"
              className="my-auto"
            />
          ) : (
            <Avatar
              label={getFirstCharacter(profile?.first_name)}
              size="small"
              shape="circle"
              className="my-auto"
            />
          )}
          <div className="flex ml-2 my-auto ">
            <span className="username firstletter_capital">{profile?.first_name} {profile?.last_name}</span>
          </div>
        </div>
        <OverlayPanel ref={op}>
          <div className="p-3 flex align-item-center gap-2">
            <img src={Logoutblack} />
            <Link className="text-black-alpha-90 no-underline" onClick={onLogout}>Logout</Link>
          </div>
        </OverlayPanel>
      </div>
    </div>
  );
}
