import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    property: {
        landlordData: "",
        contractData: ""
    },
};

export const propertySlice = createSlice({
    name: "property",
    initialState,
    reducers: {
        setPropertySlice: (state, action) => {
            state.property = action.payload
        },
        setPropertyLandlordSlice: (state, action) => {
            state.property.landlordData = action.payload
        },
        setPropertyContractSlice: (state, action) => {
            state.property.contractData = action.payload
        }
    }
    
})

export const { setPropertySlice, setPropertyLandlordSlice, setPropertyContractSlice } = propertySlice.actions;
export default propertySlice.reducer;