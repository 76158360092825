import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const contractSlice = createSlice({
    name: "contract",
    initialState,
    reducers: {
        setContractSlice: (state, action) => {
            state.contract = action.payload
        },
    },

});

export const { setContractSlice } = contractSlice.actions;
export default contractSlice.reducer;