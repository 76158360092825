import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import PaperPlane from "../../assets/images/paperplane.png";
import AdminPage from "../../assets/images/admin.png";
import Backend from "../../assets/images/backend.png";
import SocialWorker from "../../assets/images/social-worker.png";
import CustomButton from "../CustomButton";
import { capitalFirstLetter } from "../../utils/regex";
const SendInviteModal = ({
  visible,
  setVisible,
  email,
  roles,
  allInvites,
  setAllInvites,
  handleRemoveEmail,
}) => {
  const [selectedRole, setSelectedRole] = useState("");
  const handleSendInvite = () => {
    let emails = [...allInvites];
    const invite = { recipient_email: email, role: selectedRole };
    emails.push(invite);
    setAllInvites(emails);
    setVisible(!visible);
    setSelectedRole("");
  };

  const footerContent = (
    <div className="modal-footer border-0 flex justify-content-center pt-4">
      <CustomButton
        disabled={!selectedRole}
        onClick={handleSendInvite}
        type="button"
        label="Confirm"
        className="btn primary-button fs-6 p-2 text-white px-5"
      />
    </div>
  );

  return (
    <>
      <Dialog
        className="invitation-modal"
        header="Invite"
        footer={footerContent}
        visible={visible}
        onHide={() => {
          handleRemoveEmail(email);
        }}
      >
        <p className="text-black fw-medium text-center flex gap-1 justify-content-center align-items-center">
          <img src={PaperPlane} alt="" width={28} className="me-2" />
          Invite:
          <p className="text-primary m-0 firstAlphabetCap">
            {capitalFirstLetter(email)}
          </p>
        </p>
        <div className="flex mt-3 justify-content-center gap-3">
          {roles?.map((role, index) => {
            if (role.name === "admin") {
              return (
                <div key={index} onClick={() => setSelectedRole(role.id)}>
                  <div
                    className={`profile-role ${
                      selectedRole === role.id ? "active-role" : ""
                    }`}
                  >
                    <img src={AdminPage} alt="" style={{ width: "70%" }} />
                  </div>
                  <p
                    className={`text-center firstLetterCapital ${
                      selectedRole === role.id ? "text-primary" : ""
                    }`}
                  >
                    {role.name}
                  </p>
                </div>
              );
            } else if (role.name === "backend") {
              return (
                <div key={index} onClick={() => setSelectedRole(role.id)}>
                  <div
                    className={`profile-role ${
                      selectedRole === role.id ? "active-role" : ""
                    } `}
                  >
                    <img src={Backend} alt="" style={{ width: "80%" }} />
                  </div>
                  <p
                    className={`text-center firstLetterCapital ${
                      selectedRole === role.id ? "text-primary" : ""
                    }`}
                  >
                    {role.name}
                  </p>
                </div>
              );
            } else if (role.name === "social-worker") {
              return (
                <div key={index} onClick={() => setSelectedRole(role.id)}>
                  <div
                    className={`profile-role ${
                      selectedRole === role.id ? "active-role" : ""
                    }`}
                  >
                    <img src={SocialWorker} alt="" style={{ width: "80%" }} />
                  </div>
                  <p
                    className={`text-center firstLetterCapital ${
                      selectedRole === role.id ? "text-primary" : ""
                    }`}
                  >
                    {role.name}
                  </p>
                </div>
              );
            }
          })}
        </div>
      </Dialog>
    </>
  );
};

export default SendInviteModal;
