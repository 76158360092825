import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const officeSlice = createSlice({
    name: "office",
    initialState,
    reducers: {
        setOfficeSlice: (state, action) => {
            state.office = action.payload
        },
    },

});

export const { setOfficeSlice } = officeSlice.actions;
export default officeSlice.reducer;