import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    handyman: { },
    updateHandyman: false
}

export const handymanSlice = createSlice({
    name: "handyman",
    initialState,
    reducers: {
        setHandymanSlice: (state, action) => {
            state.handyman = action.payload
        },
        setHandymanUpdateSlice: (state, action) => {
            state.updateHandyman = action.payload
        }
    },

});

export const { setHandymanSlice, setHandymanUpdateSlice } = handymanSlice.actions;
export default handymanSlice.reducer;