import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import formValidation from "utils/validations";
import { handleSetAddress, showFormErrors } from "utils/commonFunctions";
import { addHandymanAction, searchHandymanByNameAction } from "store/actions/HandymanActions";
import { useDispatch, useSelector } from "react-redux";
import { checkHandymanServiceErrors } from "shared/Components/commonFunctions";
import { CustomSwitch } from "shared/AllInputs";
import { setHandymanSlice } from "store/slices/handymanSlice";
import { debounce } from "lodash";

const HandymanContainer = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        name: "", address: "", houseNo: "", street: "", zipCode: "", city: "", cityDivision: "", country: "", feedback: true
    })
    const [selectedAddress, setSelectedAddress] = useState()
    const [loading, setLoading] = useState(false)
    const [allServices, setAllServices] = useState([
        { id: "", service: "", email: "", work_phone: "", home_phone: "", status: "" }
    ])
    const navigate = useNavigate()
    const { profile } = useSelector((state) => state.user);
    const [nameOptions, setNameOptions] = useState([])
    const userRef = useRef()
    const [isSearch, setIsSearch] = useState()

    useEffect(() => {
        if (profile?.company) {
            userRef.current = profile
        }
    }, [profile])
    const header = () => {
        return (
            <div className="col-6 text-right">
                <CustomSwitch flexClass="justify-content-end" data={data} extraClassName="ml-auto" onChange={handleChange} name="feedback" label="feedback" col={10} />
            </div>
        );
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));

        if (name === "address") {
            setSelectedAddress("")
        }
    }

    useMemo(() => {
        if (selectedAddress) {
            handleSetAddress(selectedAddress, data, setData, ["longitude", "latitude"])
        }
    }, [selectedAddress])

    const onSubmit = () => {
        const services = checkHandymanServiceErrors(allServices, setAllServices)
        if (showFormErrors(data, setData) && !services?.hasErrors) {
            let paylod = {
                ...data,
                services: services.services
            }
            dispatch(
                addHandymanAction(
                    profile?.company,
                    paylod,
                    setLoading,
                    dispatch,
                    (res) => {
                        navigate("/real-estate/maintenance/handyman/list")
                        dispatch(setHandymanSlice(res))
                    }
                )
            )
        }
    }

    const handleSearch = (value) => {
        dispatch(
            searchHandymanByNameAction(userRef?.current?.company, value, setNameOptions, setIsSearch, (error) => {
                const note = ({ ["name"]: error })
                setData((prev) => ({ ...prev, note }));
            })
        );
    };

    const nameChange = useRef(debounce(handleSearch, 1000));

    return {
        header,
        data,
        handleChange,
        setSelectedAddress,
        onSubmit,
        selectedAddress,
        loading,
        navigate,
        allServices,
        setAllServices,
        nameChange,
        nameOptions,
        isSearch,
        setData
    }
}
export default HandymanContainer