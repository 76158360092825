import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// import { isAuthenticated } from "../services/auth";
import { PrivateRoutes } from "./allRoutes";
import Layout from "../layout/Layout";
import { canAccess, getUserInfo, isAuthenticated } from "../services/auth";
// import CreateProject from "../views/CreateProject/CreateProject";

const Auth = () => {
  return isAuthenticated() ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/403" />
  );
};

// const AuthCreateProject = () => {
//   return isAuthenticated() ? <Outlet /> : <Navigate to="/403" />;
// };

export const ProtectedRoutes = () => {
  const profile = getUserInfo()
  const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(false);

  useEffect(() => {
    if (profile?.id) {
      setIsPermissionsLoaded(true)
    }
  }, [])

  const checkUserPermission = (route) => {
    if (isPermissionsLoaded) {
      const hasPermission = canAccess(route?.codename)
      return hasPermission
    } else {
      return true
    }

  };

  return (
    <Routes>
      <Route element={<Auth />}>
        {/* {PrivateRoutes.map((route, key) => (
          <Route key={key} path={route.path} element={route.element} />
        ))} */}

        {PrivateRoutes.map((route) => checkUserPermission(route) ? <Route key={route.path} path={route.path} element={route.element} /> : <Route key={route.path} path={route.path} element={<Navigate to="/403" />} />)}
      </Route>

      {/* <Route element={<AuthCreateProject />}>
        <Route path="/create-project" element={<CreateProject />} />
      </Route> */}
    </Routes>
  );
};
