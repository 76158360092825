import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: "",
    row: "",
}

export const listSlice = createSlice({
    name: "listDetail",
    initialState,
    reducers: {
        setListDetailSlice: (state, action) => {
            const { page, row } = action.payload;
            state.page = page;
            state.row = row;
        },
    },

});

export const { setListDetailSlice } = listSlice.actions;
export default listSlice.reducer;