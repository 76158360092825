import React, { useState } from "react";
import { CustomForm, CustomInputMask } from "../../shared/AllInputs";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import formValidation from "../../utils/validations";
import CustomOverlay from "../../shared/CustomOverlay";
import CustomModal from "../../shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { handleDelete } from "../../shared/Components/commonFunctions";
import { deleteOptionMaturityAction, getContractAction, getPropertyContractAction, updateOptionMaturityAction } from "../../store/actions/contractActions";
import { useParams } from "react-router-dom";

export default function CustomMaturityTable({
  action,
  id,
  title,
  allMaturities,
  setAllMaturities,
}) {
  const params = useParams()
  const dispatch = useDispatch();
  const [editingRows, setEditingRows] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  const { profile } = useSelector((state) => state?.user)

  const rowEditValidator = async (rowData, options) => {
    const { extension, notice_period } = rowData;
    const extensionError = formValidation(
      "extension",
      extension,
      allMaturities
    );
    const noticePeriodError = formValidation(
      "notice_period",
      notice_period,
      allMaturities
    );

    if (!extensionError["extension"] && !noticePeriodError["notice_period"]) {
      let payload = {
        extension: extension,
        notice_period: notice_period,
        contract: id,
      };
      dispatch(
        updateOptionMaturityAction(rowData?.id, payload, dispatch, (res) => {
          let maturity = [...allMaturities];
          maturity[options.rowIndex] = rowData;
          setAllMaturities(maturity);
          setEditingRows({});
          handleGetDetails()
        })
      );
    } else {
      return false;
    }
  };

  const handleEditRow = (rowData) => {
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const NoticePeriosEditor = (options) => {
    const formErrors = formValidation(
      "notice_period",
      options.value,
      allMaturities
    );

    return (
      <CustomInputMask
        spanLabel={"Months"}
        type="text"
        col={12}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        name="notice_period"
        ignoreLabel
        errorMessage={formErrors["notice_period"]}
        max={9}
      />
    );
  };

  const ExtensionEditor = (options) => {
    const formErrors = formValidation(
      "extension",
      options.value,
      allMaturities
    );
    return (
      <CustomInputMask
        spanLabel={"Months"}
        value={options.value}
        col={12}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
        name="extension"
        ignoreLabel
        errorMessage={formErrors["extension"]}
        max={9}
      />
    );
  };

  const handleSave = async (rowData, options, e) => {
    options?.rowEditor?.onSaveClick(e);
    const val = { ...{ [`${rowData?.id}`]: true } };
    setEditingRows(val);
  };

  const actions = (rowData, options) => {
    return (
      <>
        {options.rowEditor?.editing ? (
          <>
            <a
              style={{ cursor: "pointer" }}
              onClick={(e) => handleSave(rowData, options, e)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295l5.294-5.294l1.414 1.414l-6.706 6.706z"
                  fill="#787878"
                ></path>
              </svg>
            </a>
            <a
              onClick={(e) =>
                options.rowEditor?.onCancelClick &&
                options.rowEditor?.onCancelClick(e)
              }
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#787878"
                  d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
                ></path>
              </svg>
            </a>
          </>
        ) : (
          <>
            <CustomOverlay>
              <ul className="no-style p-0">
                <li
                  className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
                  onClick={() => handleEditRow(rowData)}
                >
                  <i className="pi pi-file-edit text-dark"></i>
                  Edit
                </li>
                <li className="flex gap-2 text-xs font-medium mb-3">
                  <i
                    className="pi pi-trash cursor-pointer text-dark"
                    onClick={() => {
                      setIdToDelete({
                        id: rowData?.id,
                        index: options?.rowIndex,
                      });
                      setDeleteModal(true);
                    }}
                  ></i>{" "}
                  Delete
                </li>
              </ul>
            </CustomOverlay>
          </>
        )}
      </>
    );
  };

  const onRowEditChange = (e) => {
    setEditingRows(e.data);
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected optional maturity from the
        list? This action cannot be undone.
      </span>
    );
  };

  const handleDeleteMaturity = () => {
    dispatch(
      deleteOptionMaturityAction(idToDelete?.id, setLoading, dispatch, (res) => {
        handleDelete(allMaturities, setAllMaturities, "", "", idToDelete?.index);
        setIdToDelete({ id: "", index: "" });
        setDeleteModal(false);
        handleGetDetails()
      })
    );
  };

  const handleGetDetails = () => {
    if(action === "property"){
      dispatch(getPropertyContractAction(params?.id, dispatch))
    }else if(action === "contract"){
      dispatch(getContractAction(profile?.company, params?.id, dispatch))
    }
  }

  return (
    <CustomForm title={title}>
      <DataTable
        className="col-12"
        value={allMaturities}
        editMode="row"
        dataKey="id"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        // onRowEditComplete={onRowEditComplete}
        rowEditValidator={rowEditValidator}
        onRowEditSave={handleSave}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column
          headerClassName="custom-header"
          field="extension"
          header="Extension"
          editor={(options) => ExtensionEditor(options)}
          body={(rowData) => (
            <span>
              {rowData.extension ? `${rowData.extension} Months` : "-----"}
            </span>
          )}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          field="notice_period"
          header="Notice Period"
          body={(rowData) => (
            <span>
              {rowData.notice_period
                ? `${rowData.notice_period} Months`
                : "-----"}
            </span>
          )}
          editor={(options) => NoticePeriosEditor(options)}
          // style={{ width: "200px" }}
        ></Column>
        <Column
          headerClassName="custom-header"
          header="Action"
          rowEditor
          // headerStyle={{ width: "10%", minWidth: "8rem" }}
          // bodyStyle={{ textAlign: "center" }}
          body={(rowData, options) => actions(rowData, options)}
        ></Column>
      </DataTable>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleDeleteMaturity}
        loading={loading}
      />
    </CustomForm>
  );
}
