import React from "react";
import Page from "shared/Page";
import CustomCard from "shared/CustomCard";
import { CustomDropDown, CustomForm } from "shared/AllInputs";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "shared/CustomButton";
import DamageContainer from "./DamageContainer";
import { CustomViewInput } from "shared/AllViewInputs";
import { TabPanel, TabView } from "primereact/tabview";
import PropertyTableModal from "./PropertyTableModal";
import AddDamageFrom from "views/CommonComponents/AddDamageForm";
import CustomDamageTable from "views/Tables/CustomDamageTable";
import { damageReportType } from "shared/AllDropDownOptions";
import CommentsTable from "views/Tables/CommentsTable";

const DamageForms = ({ type }) => {
  const {
    propertyData,
    setPropertyData,
    onSubmit,
    loading,
    navigate,
    isPropertyModal,
    setIsPropertyModal,
    onConfirm,
    selectedProperty,
    setSelectedProperty,
    isAddDamage,
    setIsAddDamage,
    allDamages,
    setAllDamages,
    // handleDeleteDamage,
    handleEditDamage,
    // deleteDamageModal,
    // setDeleteDamageModal,
    damagesheader,
    reportId,
    handleProcessDamage,
    updateDamageReport,
    comments,
    addComment,
    updateComment,
    deleteComment,
  } = DamageContainer();

  const header = () => {
    return (
      <div className="cursor-pointer">
        {type === "Add" && (
          <CustomButton
            type="button"
            size="small"
            extraClassNames="ml-0"
            label="Select Property"
            onClick={() => setIsPropertyModal(true)}
          />
        )}
      </div>
    );
  };
  const FormHeader = () => {
    return (
      <div className="flex align-items-center col-12 justify-content-between">
        <h1 className="title">General</h1>
        {header()}
      </div>
    );
  };

  return (
    <>
      {isAddDamage ? (
        <>
          <AddDamageFrom
            reportId={reportId}
            allDamages={allDamages}
            setAllDamages={setAllDamages}
            setIsAddDamage={setIsAddDamage}
          />
        </>
      ) : (
        <>
          <Page
            title={`${type === "View" ? "View" : "Report"}` + "Damage"}
            description={`Let’s ${type} your Damage Details`}
            navigateLink={"/real-estate/maintenance/damage/list"}
          >
            <CustomCard>
              <CustomForm col={12} header={FormHeader()}>
                <CustomViewInput data={propertyData} name="propertyID" />
                <CustomViewInput data={propertyData} name="landlordName" />
                <CustomViewInput data={propertyData} name="tenantsName" />
                <CustomViewInput data={propertyData} name="address" />
                {type !== "Add" && (
                  <CustomDropDown
                    data={propertyData}
                    onChange={({ value }) => {
                      setPropertyData({ ...propertyData, status: value });
                      updateDamageReport(value);
                    }}
                    name="status"
                    options={damageReportType}
                    required
                    colors
                  />
                )}
              </CustomForm>
              <TabView className="tabs-design">
                <TabPanel header="Damages">
                  <CustomDamageTable
                    allDamages={allDamages}
                    header={damagesheader()}
                    onEdit={handleEditDamage}
                    onProcess={handleProcessDamage}
                  />
                </TabPanel>
                {reportId && (
                  <TabPanel header="Comments">
                    <CommentsTable
                      comments={comments}
                      onAdd={addComment}
                      onUpdate={updateComment}
                      onDelete={deleteComment}
                    />
                  </TabPanel>
                )}
              </TabView>
            </CustomCard>
            <Buttonlayout>
              {type === "Add" ? (
                <>
                  <CustomButton
                    onClick={onSubmit}
                    label="Save"
                    loading={loading}
                  />
                  <CustomButtonOutlined
                    label="Cancel"
                    onClick={() => {
                      navigate("/real-estate/maintenance/damage/list");
                    }}
                  />
                </>
              ) : (
                <CustomButtonOutlined
                  label="Damage History"
                  onClick={() => {
                    navigate(
                      `/real-estate/maintenance/damage/damage-history/${reportId}`
                    );
                  }}
                />
              )}
            </Buttonlayout>
          </Page>
        </>
      )}

      {type === "Add" && (
        <PropertyTableModal
          isOpen={isPropertyModal}
          setIsOpen={setIsPropertyModal}
          heading={"Select Property"}
          submitLabel={"Confirm"}
          onSubmit={onConfirm}
          loading={loading}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
        />
      )}
    </>
  );
};

export default DamageForms;
