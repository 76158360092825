import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import formValidation from "../utils/validations";
import { useDispatch } from "react-redux";
import { showFormErrors } from "../utils/commonFunctions";
import { isAuthenticated } from "../services/auth";
import { onUserLoginAction } from "../store/actions/userActions";
import Layout from "./Layout";
import {
  CustomForm,
  CustomInput,
  CustomPassword,
  CustomSwitch,
} from "../shared/AllInputs";
import CustomButton from "../shared/CustomButton";
import { Toast } from "primereact/toast";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    loginPassword: "",
    rememberMe: true,
  });

  const handleChange = ({ name, value }) => {
    let errorName = "";
    if (name === "loginPassword") {
      errorName = "password";
    }
    const formErrors = formValidation(name, value, data, [], errorName);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errorName = "";
    if (!data?.loginPassword) {
      errorName = "password";
    }
    if (showFormErrors(data, setData, [], errorName)) {
      dispatch(
        onUserLoginAction(data, setLoading, navigate, (error) => {
          msgs.current.show({
            severity: "error",
            detail: error,
          });
        })
      );
    }
  };

  const msgs = useRef(null);
  return (
    <Layout title="Login Here ">
      <CustomForm onSubmit={onSubmit} className="form">
        <Toast ref={msgs} />
        <CustomInput
          data={data}
          onChange={handleChange}
          name="email"
          label="Email Address"
          required
          col="12"
        />
        <CustomPassword
          data={data}
          onChange={handleChange}
          name="loginPassword"
          label="Password"
          placeholder="Enter Password"
          required
          col="12"
        />
        <CustomSwitch
          data={data}
          onChange={handleChange}
          name="rememberMe"
          label="Remember Me"
        />
        <div className="col-6 flex justify-content-end my-auto">
          <Link className="link" to="/forget-password">
            Forgot password?
          </Link>
        </div>
        <CustomButton
          onClick={onSubmit}
          loading={loading}
          extraClassNames="w-full my-4"
          label="Sign in"
        />

        <div className="w-full text-center">
          Don’t have an account?{" "}
          <Link className="link" to="/register">
            Sign up now
          </Link>
        </div>
      </CustomForm>
    </Layout>
  );
}
